import {INewDoc} from "../store/new/new";
import {ERoles, ETypes, IMUtil, ITableName} from "./types";
import { isDate } from 'date-fns';
import {INewPhotoDoc} from "../store/new/photo";
import NewPhotoModel from "./newPhotoModel";
import {IAttach} from "../store/types";


export default class NewModel implements INewDoc {

  public id: 					  number | null;
  public date:					Date;
  public title:				  string;
  public description: 	string;
  public text:					string;
  public siteId:				string;
  public createdAt:		  Date;
  public updatedAt:		  Date;
  public deletedAt:		  Date | null;

  // Relation
  public siteNewPhoto:  INewPhotoDoc | null;
  // 4 Attach ONLY 1
  public attach:        IAttach;

  constructor(doc?: INewDoc) {
    // Initial Value with input object (optional)
    this.id =           !!doc && doc.id ? doc.id : null;
    this.date =					!!doc && !!doc.date ? isDate(doc.date) ? doc.date : new Date(doc.date) : new Date();
    this.title =				!!doc && !!doc.title ? doc.title : '';
    this.description = 	!!doc && !!doc.description ? doc.description : '';
    this.text =					!!doc && !!doc.text ? doc.text : '';
    this.siteId =				!!doc && !!doc.siteId ? doc.siteId : '';
    this.createdAt =		!!doc && !!doc.createdAt ? isDate(doc.createdAt) ? doc.createdAt : new Date(doc.createdAt) : new Date();
    this.updatedAt =		!!doc && !!doc.updatedAt ? isDate(doc.updatedAt) ? doc.updatedAt : new Date(doc.updatedAt) : new Date();
    this.deletedAt =		!!doc && !!doc.deletedAt ? isDate(doc.deletedAt) ? doc.deletedAt : new Date(doc.deletedAt) : null;

    // Relations
    this.siteNewPhoto = !!doc && doc.siteNewPhoto ? new NewPhotoModel(doc.siteNewPhoto) : null;
    // 4 Attach
    // this.attach =       !!doc && doc.attach ? AttachModel.toDB2(doc.attach) : AttachModel.toDB2(new AttachModel());
    this.attach = {
      file:   !!doc && doc.attach && doc.attach.file ? doc.attach.file : null,
      attach: !!doc && doc.attach && doc.attach.attach ? doc.attach.attach : null,
    }
  }

  // Object to DB
  public toDB() {
    const docForDB = Object.assign({}, this);
    if (!this.id) {
      delete docForDB.id
    }
    if (!!this.siteNewPhoto) {
      docForDB.siteNewPhoto = NewPhotoModel.toDB2(this.siteNewPhoto);
    }
    // else {
    //   delete docForDB.siteNewPhoto
    // }
    delete docForDB.createdAt;
    delete docForDB.updatedAt;
    delete docForDB.deletedAt;

    delete docForDB.attach;

    return docForDB;
  }

  // Object to DB
  static toDB2(doc: INewDoc): INewDoc {
    const docForDB = Object.assign({}, doc);
    if (!doc.id) {
      delete docForDB.id
    }

    if (!!doc.siteNewPhoto) {
      docForDB.siteNewPhoto = NewPhotoModel.toDB2(doc.siteNewPhoto);
    }
    else {
      delete docForDB.siteNewPhoto
    }

    delete docForDB.createdAt;
    delete docForDB.updatedAt;
    delete docForDB.deletedAt;

    delete docForDB.attach;

    return docForDB;
  }


  // STATIC methods
  static util(): IMUtil {
    return {
      id: {
        name:     'id',
        forSearch: {
          forRoles:   [ERoles.SUPER_ADMIN],
          relation:   {},
        },
        type:     ETypes.INTEGER,
        forTable: {
          visibleFor: [],
          isSortable: false,
        },
      },

      date: {
        name:     'date',
        forSearch: {
          forRoles: [ERoles.SUPER_ADMIN, ERoles.ADMIN, ERoles.MANAGER, ERoles.EDITOR, ERoles.USER],
          relation: {},
        },
        type:     ETypes.DATE,
        forTable: {
          visibleFor: [ERoles.SUPER_ADMIN, ERoles.ADMIN, ERoles.MANAGER, ERoles.EDITOR, ERoles.USER],
          isSortable: true,
        },
      },

      title: {
        name:     'title',
        forSearch: {
          forRoles: [ERoles.SUPER_ADMIN, ERoles.ADMIN, ERoles.MANAGER, ERoles.EDITOR, ERoles.USER],
          relation: {},
        },
        type:     ETypes.STRING,
        forTable: {
          visibleFor: [ERoles.SUPER_ADMIN, ERoles.ADMIN, ERoles.MANAGER, ERoles.EDITOR, ERoles.USER],
          isSortable: true,
        },
      },

      description: {
        name:     'description',
        forSearch: {
          forRoles: [ERoles.SUPER_ADMIN, ERoles.ADMIN, ERoles.MANAGER, ERoles.EDITOR, ERoles.USER],
          relation: {},
        },
        type:     ETypes.STRING,
        forTable: {
          visibleFor: [ERoles.SUPER_ADMIN, ERoles.ADMIN, ERoles.MANAGER, ERoles.EDITOR, ERoles.USER],
          isSortable: true,
        },
      },

      text: {
        name:      'text',
        forSearch: {
          forRoles: [ERoles.SUPER_ADMIN, ERoles.ADMIN, ERoles.MANAGER, ERoles.EDITOR, ERoles.USER],
          relation: {},
        },
        type:     ETypes.STRING,
        forTable: {
          visibleFor: [ERoles.SUPER_ADMIN, ERoles.ADMIN, ERoles.MANAGER, ERoles.EDITOR, ERoles.USER],
          isSortable: true,
        },
      },

      siteId: {
        name:      'site',
        forSearch: {
          forRoles: [ERoles.SUPER_ADMIN, ERoles.ADMIN, ERoles.MANAGER, ERoles.EDITOR, ERoles.USER],
          relation: {},
        },
        type:     ETypes.STRING,
        forTable: {
          visibleFor: [ERoles.SUPER_ADMIN],
          isSortable: false,
        },
      },

      createdAt: {
        name:      'created at',
        forSearch: {
          forRoles: [ERoles.SUPER_ADMIN, ERoles.ADMIN, ERoles.MANAGER, ERoles.EDITOR, ERoles.USER],
          relation: {},
        },
        type:     ETypes.DATE,
        forTable: {
          visibleFor: [ERoles.SUPER_ADMIN, ERoles.ADMIN, ERoles.MANAGER, ERoles.EDITOR, ERoles.USER],
          isSortable: true,
        },
      },

      updatedAt: {
        name:      'updated at',
        forSearch: {
          forRoles: [ERoles.SUPER_ADMIN, ERoles.ADMIN],
          relation: {},
        },
        type:     ETypes.DATE,
        forTable: {
          visibleFor: [],
          isSortable: false,
        },
      },

      deletedAt: {
        name:      'deleted at',
        forSearch: {
          forRoles: [ERoles.SUPER_ADMIN, ERoles.ADMIN],
          relation: {},
        },
        type:     ETypes.DATE,
        forTable: {
          visibleFor: [],
          isSortable: false,
        },
      },

    };
  }

  static tableName(): ITableName {
    return {
      singular: 'siteNew',
      plural:   'siteNews',
    }
  }

}
