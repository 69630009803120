import {ESessionsActionTypes, ISessionsAction} from "./index";
import {Action} from "redux";
import {ThunkDispatch} from "redux-thunk";
import {IApplicationState} from "../../index";
import {EDirection, IPaginationParam, IParamsTypes, ISortParam} from "../../../helpers/interfaces/forQueryParams";
import {config} from "../../../appConfig"
import axios from 'axios';
import SessionModel from "../../../models/sessionModel";
import {ISessionDoc} from "../session";
import {IItems, IPages, IServerResponseMany} from "../../types";
import {decode} from "../../../helpers/params/param_helper";
import {IDecodeParams} from "../../../helpers/params/types";
import {push} from "connected-react-router";
import {actAuth} from "../auth";
import {initToken} from "../../../helpers/token/auth_helper";
import {InitTokenReturn} from "../../../helpers/token/types";

// Default Query
const defaultOrderParams: ISortParam[] = [
	{
		field: 'id',
		dir: EDirection.UP,
	},
];

const defaultPaginationParams: IPaginationParam = {
	page: 1,
	limit: 10,
};

interface ISessionsResponse extends IServerResponseMany {
	docs: ISessionDoc[],
}

export const actSessions = {
	get: ({
					queryParams = [],
					sortParams = defaultOrderParams,
					paginationParams = defaultPaginationParams
				}: IParamsTypes)  => {
		return async (dispatch: ThunkDispatch<IApplicationState, void, Action>) => { // ,	getState: Function

			dispatch(actionRequestOn(ESessionsActionTypes.GET_REQUEST));
			const params: IParamsTypes = {
				queryParams,
				sortParams,
				paginationParams,
			};

			const decodeParams: IDecodeParams = {
				util:				SessionModel.util(),
				tableName: 	SessionModel.tableName(),
				...params
			};
			const token: InitTokenReturn = await initToken();
			if (token.token) {
				try {
					axios.defaults.headers.common['Authorization'] = token.token;
					const query: string = decode(decodeParams);
					const queryAxios: string = !!query ? '?' + query : '';
					const response = await axios.get<ISessionsResponse>(`${config.site.apiURL}/auth/sessions` + queryAxios);

					const docs: ISessionDoc[] = response.data.docs.map((n: ISessionDoc) => {
						return new SessionModel(n);
					});
					const items: IItems = response.data.items;
					const pages: IPages = response.data.pages;

					return dispatch(actionRequestOff(ESessionsActionTypes.GET_SUCCESS, docs, params, items, pages));
				} catch (error) {
					console.log('AXIOS error: ', JSON.stringify(error));
					console.log('AXIOS error: ', error);
					if (error.response && error.response.status === 401) {
						//dispatch({type: SNACKBAR_ERROR, payload: {serverError: {code: null, message:'ACCESSO NON AUTORIZZATO'}}});
					} else if (error.response && error.response.status === 403) {
						//dispatch({type: SNACKBAR_ERROR, payload: {serverError: {code: null, message:'ACCESSO NON CONSENTITO'}}});
					} else if (error.response && error.response.status === 400) {
						//dispatch({type: SNACKBAR_ERROR, payload: {serverError: {path: error.response.data.details[0].path, message:error.response.data.details[0].path + ' ' + error.response.data.details[0].message, type:error.response.data.details[0].type}}});
					} else {
						//dispatch({type: SNACKBAR_ERROR, payload: {serverError: {path: error.response.data.details[0].path, message:error.response.data.details[0].path + ' ' + error.response.data.details[0].message, type:error.response.data.details[0].type}}});
					}
					// Close error
					return dispatch(actionRequestOff(ESessionsActionTypes.GET_FAILURE, [], params));
				}
			}
			else {
				dispatch(push('/'));
				dispatch(actionRequestOff(ESessionsActionTypes.GET_FAILURE, [], params));
				dispatch(actAuth.logout());
			}
		}
	},

};

// Service for Fetching
const actionRequestOn = (type: ESessionsActionTypes) => {
	return (dispatch: ThunkDispatch<IApplicationState, void, Action>) => {
		const action: ISessionsAction = {
			type: type,
			payload: {
				docs: [],
				isFetching: true,
			},
		};
		dispatch(action);
	}
};

const actionRequestOff = (type: ESessionsActionTypes, docs: ISessionDoc[], params: IParamsTypes, items?: IItems, pages?: IPages) => {
	return (dispatch: ThunkDispatch<IApplicationState, void, Action>) => {
		const action: ISessionsAction = {
			type: type,
			payload: {
				docs: !!docs ? docs : [],
				isFetching: false,
				items: !!items ? items : null,
				pages: !!pages ? pages : null,
				params: !!params ? params : {},
			},
		};
		dispatch(action);
	}
};
