import {IChurchDoc} from "../store/church/church";
import {ERoles, ETypes, IMUtil, ITableName} from "./types";
import { isDate } from 'date-fns';
import {IChurchPhotoDoc} from "../store/church/photo";
import ChurchPhotoModel from "./churchPhotoModel";
import {IAttach} from "../store/types";


export default class ChurchModel implements IChurchDoc {

  public id: 					  number | null;
  public date:					string;
  public map:           number;
  public number:        number;
  public name:				  string;
  public originalName: 	string;
  public place:					string;
  public x:             number;
  public y:             number;
  public siteId:				string;
  public createdAt:		  Date;
  public updatedAt:		  Date;
  public deletedAt:		  Date | null;

  // Relation
  public siteChurchPhoto:  IChurchPhotoDoc | null;
  // 4 Attach ONLY 1
  public attach:        IAttach;

  constructor(doc?: IChurchDoc) {
    // Initial Value with input object (optional)
    this.id =             !!doc && doc.id ? doc.id : null;
    this.date =					  !!doc && doc.date ? doc.date : '';
    this.map =            !!doc && !!doc.map ? doc.map : 0;
    this.number =            !!doc && !!doc.number ? doc.number : 0;
    this.name =				    !!doc && doc.name ? doc.name : '';
    this.originalName = 	!!doc && doc.originalName ? doc.originalName : '';
    this.place =					!!doc && doc.place ? doc.place : '';
    this.x =            !!doc && !!doc.x ? doc.x : 0;
    this.y =            !!doc && !!doc.y ? doc.y : 0;
    this.siteId =				  !!doc && doc.siteId ? doc.siteId : '';
    this.createdAt =		  !!doc && !!doc.createdAt ? isDate(doc.createdAt) ? doc.createdAt : new Date(doc.createdAt) : new Date();
    this.updatedAt =		  !!doc && !!doc.updatedAt ? isDate(doc.updatedAt) ? doc.updatedAt : new Date(doc.updatedAt) : new Date();
    this.deletedAt =	  	!!doc && !!doc.deletedAt ? isDate(doc.deletedAt) ? doc.deletedAt : new Date(doc.deletedAt) : null;

    // Relations
    this.siteChurchPhoto = !!doc && doc.siteChurchPhoto ? new ChurchPhotoModel(doc.siteChurchPhoto) : null;
    // 4 Attach
    // this.attach =       !!doc && doc.attach ? AttachModel.toDB2(doc.attach) : AttachModel.toDB2(new AttachModel());
    this.attach = {
      file:   !!doc && doc.attach && doc.attach.file ? doc.attach.file : null,
      attach: !!doc && doc.attach && doc.attach.attach ? doc.attach.attach : null,
    }
  }

  // Object to DB
  public toDB() {
    const docForDB = Object.assign({}, this);
    if (!this.id) {
      delete docForDB.id
    }
    if (!!this.siteChurchPhoto) {
      docForDB.siteChurchPhoto = ChurchPhotoModel.toDB2(this.siteChurchPhoto);
    }
    // else {
    //   delete docForDB.siteChurchPhoto
    // }
    delete docForDB.createdAt;
    delete docForDB.updatedAt;
    delete docForDB.deletedAt;

    delete docForDB.attach;

    return docForDB;
  }

  // Object to DB
  static toDB2(doc: IChurchDoc): IChurchDoc {
    const docForDB = Object.assign({}, doc);
    if (!doc.id) {
      delete docForDB.id
    }

    if (!!doc.siteChurchPhoto) {
      docForDB.siteChurchPhoto = ChurchPhotoModel.toDB2(doc.siteChurchPhoto);
    }
    else {
      delete docForDB.siteChurchPhoto
    }

    delete docForDB.createdAt;
    delete docForDB.updatedAt;
    delete docForDB.deletedAt;

    delete docForDB.attach;

    return docForDB;
  }


  // STATIC methods
  static util(): IMUtil {
    return {
      id: {
        name:     'id',
        forSearch: {
          forRoles:   [ERoles.SUPER_ADMIN],
          relation:   {},
        },
        type:     ETypes.INTEGER,
        forTable: {
          visibleFor: [],
          isSortable: false,
        },
      },

      date: {
        name:     'date',
        forSearch: {
          forRoles: [ERoles.SUPER_ADMIN, ERoles.ADMIN, ERoles.MANAGER, ERoles.EDITOR, ERoles.USER],
          relation: {},
        },
        type:     ETypes.STRING,
        forTable: {
          visibleFor: [ERoles.SUPER_ADMIN, ERoles.ADMIN, ERoles.MANAGER, ERoles.EDITOR, ERoles.USER],
          isSortable: true,
        },
      },

      map: {
        name:     'map',
        forSearch: {
          forRoles: [ERoles.SUPER_ADMIN, ERoles.ADMIN, ERoles.MANAGER, ERoles.EDITOR, ERoles.USER],
          relation: {},
        },
        type:     ETypes.INTEGER,
        forTable: {
          visibleFor: [ERoles.SUPER_ADMIN, ERoles.ADMIN, ERoles.MANAGER, ERoles.EDITOR, ERoles.USER],
          isSortable: true,
        },
      },

      number: {
        name:     'number',
        forSearch: {
          forRoles: [ERoles.SUPER_ADMIN, ERoles.ADMIN, ERoles.MANAGER, ERoles.EDITOR, ERoles.USER],
          relation: {},
        },
        type:     ETypes.INTEGER,
        forTable: {
          visibleFor: [ERoles.SUPER_ADMIN, ERoles.ADMIN, ERoles.MANAGER, ERoles.EDITOR, ERoles.USER],
          isSortable: true,
        },
      },

      name: {
        name:      'name',
        forSearch: {
          forRoles: [ERoles.SUPER_ADMIN, ERoles.ADMIN, ERoles.MANAGER, ERoles.EDITOR, ERoles.USER],
          relation: {},
        },
        type:     ETypes.STRING,
        forTable: {
          visibleFor: [ERoles.SUPER_ADMIN, ERoles.ADMIN, ERoles.MANAGER, ERoles.EDITOR, ERoles.USER],
          isSortable: true,
        },
      },

      originalName: {
        name:      'originalName',
        forSearch: {
          forRoles: [ERoles.SUPER_ADMIN, ERoles.ADMIN, ERoles.MANAGER, ERoles.EDITOR, ERoles.USER],
          relation: {},
        },
        type:     ETypes.STRING,
        forTable: {
          visibleFor: [ERoles.SUPER_ADMIN, ERoles.ADMIN, ERoles.MANAGER, ERoles.EDITOR, ERoles.USER],
          isSortable: true,
        },
      },

      place: {
        name:      'place',
        forSearch: {
          forRoles: [ERoles.SUPER_ADMIN, ERoles.ADMIN, ERoles.MANAGER, ERoles.EDITOR, ERoles.USER],
          relation: {},
        },
        type:     ETypes.STRING,
        forTable: {
          visibleFor: [ERoles.SUPER_ADMIN, ERoles.ADMIN, ERoles.MANAGER, ERoles.EDITOR, ERoles.USER],
          isSortable: true,
        },
      },

      x: {
        name:      'x',
        forSearch: {
          forRoles: [ERoles.SUPER_ADMIN, ERoles.ADMIN, ERoles.MANAGER, ERoles.EDITOR, ERoles.USER],
          relation: {},
        },
        type:     ETypes.DECIMAL,
        forTable: {
          visibleFor: [ERoles.SUPER_ADMIN, ERoles.ADMIN, ERoles.MANAGER, ERoles.EDITOR, ERoles.USER],
          isSortable: true,
        },
      },

      y: {
        name:      'y',
        forSearch: {
          forRoles: [ERoles.SUPER_ADMIN, ERoles.ADMIN, ERoles.MANAGER, ERoles.EDITOR, ERoles.USER],
          relation: {},
        },
        type:     ETypes.DECIMAL,
        forTable: {
          visibleFor: [ERoles.SUPER_ADMIN, ERoles.ADMIN, ERoles.MANAGER, ERoles.EDITOR, ERoles.USER],
          isSortable: true,
        },
      },

      siteId: {
        name:      'site',
        forSearch: {
          forRoles: [ERoles.SUPER_ADMIN, ERoles.ADMIN, ERoles.MANAGER, ERoles.EDITOR, ERoles.USER],
          relation: {},
        },
        type:     ETypes.STRING,
        forTable: {
          visibleFor: [ERoles.SUPER_ADMIN],
          isSortable: false,
        },
      },

      createdAt: {
        name:      'created at',
        forSearch: {
          forRoles: [ERoles.SUPER_ADMIN, ERoles.ADMIN, ERoles.MANAGER, ERoles.EDITOR, ERoles.USER],
          relation: {},
        },
        type:     ETypes.DATE,
        forTable: {
          visibleFor: [ERoles.SUPER_ADMIN, ERoles.ADMIN, ERoles.MANAGER, ERoles.EDITOR, ERoles.USER],
          isSortable: true,
        },
      },

      updatedAt: {
        name:      'updated at',
        forSearch: {
          forRoles: [ERoles.SUPER_ADMIN, ERoles.ADMIN],
          relation: {},
        },
        type:     ETypes.DATE,
        forTable: {
          visibleFor: [],
          isSortable: false,
        },
      },

      deletedAt: {
        name:      'deleted at',
        forSearch: {
          forRoles: [ERoles.SUPER_ADMIN, ERoles.ADMIN],
          relation: {},
        },
        type:     ETypes.DATE,
        forTable: {
          visibleFor: [],
          isSortable: false,
        },
      },

    };
  }

  static tableName(): ITableName {
    return {
      singular: 'siteChurch',
      plural:   'siteChurches',
    }
  }

}
