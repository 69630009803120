import {ESessionsActionTypes, ISessionsAction, ISessionsState} from './index';
import {Reducer} from 'redux';



// Reducer initial State with ALL objects
export const initialState: ISessionsState = {
	docs: [],
	isFetching: false,
	items: null,
	pages: null,
	params: {},
};

const reducer: Reducer<ISessionsState> = (state: ISessionsState = initialState, action: ISessionsAction) => {
	switch (action.type) {
		case ESessionsActionTypes.GET_REQUEST:
			return {
				...state,
				...action.payload,
			};
		case ESessionsActionTypes.GET_SUCCESS:
			return {
				...state,
					...action.payload,
			};
		case ESessionsActionTypes.GET_FAILURE:
			return {
				...state,
				...action.payload,
			};

		default:
			return state;
	}
};

export { reducer as SessionsReducer }
