export const DASHBOARD = '/dashboard';
export const HOME = '/dashboard';
export const LANDING = '/';
export const SIGN_IN = '/signin';
export const USERS = '/users';
export const ROLES = '/roles';
export const SITES = '/sites';
export const SESSIONS = '/sessions';
export const CHURCHES = '/churches';
export const NEWS = '/news';
