import * as Yup from "yup";
import {ILoginParams} from "../store/auth/auth";

const syncValidationSchema = Yup.object().shape({
	email:				Yup.string().required().email(),
	password: 		Yup.string().required().min(8),
});

const submitValidationSchema = Yup.object().shape({
	email:					Yup.string().required().email(),
	password: 			Yup.string().required().min(8),
});

const options = {
	strict: false,
	abortEarly: false,
	stripUnknown: false,
	recursive: true
};

export const validate = (values: ILoginParams, isSubmitting: boolean) => {

	let errors = {};


	if (!isSubmitting) {

		try {
			syncValidationSchema.validateSync(values, options);
		}
		catch (err) {
			console.log('Error: ', err);
			Object.keys(values).map(key => {

				err.inner.forEach(error => {
					if (error.path === key && !errors[key]) {

						errors[key] = error.message;
					}
				});
			});
		}
	} else {

		try {
			submitValidationSchema.validateSync(values, options);
		} catch (err) {

			Object.keys(values).map(key => {
				err.inner.forEach(error => {
					if (error.path === key && !errors[key]) {
						errors[key] = error.message;
					}
				});
			});
		}
	}

	return errors;
};

