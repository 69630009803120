import { ERoleActionTypes, IRoleAction, IRoleDoc } from "./index";
import { Action } from "redux";
import { batch } from 'react-redux';
import { ThunkDispatch } from "redux-thunk";
import { IApplicationState } from "../../index";
import RoleModel from "../../../models/roleModel";
import {initToken} from "../../../helpers/token/auth_helper";
import {actAuth} from "../auth";
import axios from "axios";
import {config} from "../../../appConfig";
import {IServerResponseOne} from "../../types";
import {InitTokenReturn} from "../../../helpers/token/types";
import {actRoles} from "../roles";
import {push} from "connected-react-router";

interface IRoleResponse extends IServerResponseOne {
	doc: IRoleDoc,
}

export const actRole = {
	create: (doc: IRoleDoc) => {
		return async (dispatch: ThunkDispatch<IApplicationState, void, Action>, getState: () => IApplicationState) => {
			dispatch(actionRequestOn(ERoleActionTypes.POST_REQUEST, doc));

			const sitesState = getState().sitesState;
			const params = !!sitesState && !!sitesState.params ? !!sitesState.params : {};
			const token: InitTokenReturn = await initToken();
			if (token.token) {
				try {
					// doc.siteId = config.site.id;
					axios.defaults.headers.common['Authorization'] = token.token;

					await axios.post<IRoleResponse>(`${config.site.apiURL}/auth/roles`, RoleModel.toDB2(doc));
					// const newDoc: IRoleDoc | null = response.data.doc ? response.data.doc : null;
				}
				catch (error) {
					console.log('error: ', error.response);
					if (error.response && error.response.status === 401) {
						//dispatch({type: SNACKBAR_ERROR, payload: {serverError: {code: null, message:'ACCESSO NON AUTORIZZATO'}}});
					}
					else if (error.response && error.response.status === 403) {
						//dispatch({type: SNACKBAR_ERROR, payload: {serverError: {code: null, message:'ACCESSO NON CONSENTITO'}}});
					}
					else if (error.response && error.response.status === 400) {
						error.response.data.details.map(error => {
							// const message: IMessage = {
							// 	code: error.context.label==='image' ? 'attach.file' : error.context.label,
							// 	message: error.message,
							// 	type: EMessageTypes.ERROR,
							// };
							console.log('Msg: ', error);
							// dispatch(messageActions.add(message));
						});
					}
					else{
						//dispatch({type: SNACKBAR_ERROR, payload: {serverError: {path: error.response.data.details[0].path, message:error.response.data.details[0].path + ' ' + error.response.data.details[0].message, type:error.response.data.details[0].type}}});
					}
					return dispatch(actionRequestOff(ERoleActionTypes.POST_FAILURE, doc));
				}
			}
			else {
				batch(() => {
					dispatch(push('/'));
					dispatch(actionRequestOff(ERoleActionTypes.POST_FAILURE));
					dispatch(actAuth.logout());
				});
			}
			batch(() => {
				dispatch(actRoles.get(params));
				dispatch(actionRequestOff(ERoleActionTypes.POST_SUCCESS, doc));
			});
		}
	},

	update: (doc: IRoleDoc) => {
		return async (dispatch: ThunkDispatch<IApplicationState, void, Action>, getState: () => IApplicationState) => {
			dispatch(actionRequestOn(ERoleActionTypes.PUT_REQUEST, doc));

			const sitesState = getState().sitesState;
			const params = !!sitesState && !!sitesState.params ? !!sitesState.params : {};
			const token: InitTokenReturn = await initToken();
			if (token.token) {
				try {
					// doc.siteId = config.site.id;

					axios.defaults.headers.common['Authorization'] = token.token;
					await axios.put<IRoleResponse>(`${config.site.apiURL}/auth/roles/${doc.id}`, RoleModel.toDB2(doc));
					// const newDoc: IRoleDoc | null = response.data.doc ? response.data.doc : null;
				}
				catch (error) {
					console.log('error: ', error);
					if (error.response && error.response.status === 401) {
						//dispatch({type: SNACKBAR_ERROR, payload: {serverError: {code: null, message:'ACCESSO NON AUTORIZZATO'}}});
					}
					else if (error.response && error.response.status === 403) {
						//dispatch({type: SNACKBAR_ERROR, payload: {serverError: {code: null, message:'ACCESSO NON CONSENTITO'}}});
					}
					else if (error.response && error.response.status === 400) {
						//dispatch({type: SNACKBAR_ERROR, payload: {serverError: {path: error.response.data.details[0].path, message:error.response.data.details[0].path + ' ' + error.response.data.details[0].message, type:error.response.data.details[0].type}}});
					}
					else{
						//dispatch({type: SNACKBAR_ERROR, payload: {serverError: {path: error.response.data.details[0].path, message:error.response.data.details[0].path + ' ' + error.response.data.details[0].message, type:error.response.data.details[0].type}}});
					}
					return dispatch(actionRequestOff(ERoleActionTypes.PUT_FAILURE, doc));
				}
			}
			else {
				batch(() => {
					dispatch(push('/'));
					dispatch(actionRequestOff(ERoleActionTypes.PUT_FAILURE));
					dispatch(actAuth.logout());
				});
			}
			batch(() => {
				dispatch(actRoles.get(params));
				dispatch(actionRequestOff(ERoleActionTypes.PUT_SUCCESS, doc));
			});
		}
	},

	get: (doc?: IRoleDoc, id?: number) => {
		return async (dispatch: ThunkDispatch<IApplicationState, void, Action> ) => {   //, getState: Function
			dispatch(actionRequestOn(ERoleActionTypes.GET_REQUEST));

			const getDoc: IRoleDoc = !!doc ? doc : new RoleModel();

			if (!!doc) {
				return dispatch(actionRequestOff(ERoleActionTypes.GET_SUCCESS, getDoc));
			}
			if (!!id) {
				try {
					const params = '?$withRelated=["siteRolePhoto"]';
					const response = await axios.get<IRoleResponse>(`${config.site.apiURL}/auth/roles/${id}${params}` );

					return dispatch(actionRequestOff(ERoleActionTypes.GET_SUCCESS, response.data.doc));
				}
				catch (error) {
					console.log('error: ', error);
					if (error.response && error.response.status === 401) {
						//dispatch({type: SNACKBAR_ERROR, payload: {serverError: {code: null, message:'ACCESSO NON AUTORIZZATO'}}});
					}
					else if (error.response && error.response.status === 403) {
						//dispatch({type: SNACKBAR_ERROR, payload: {serverError: {code: null, message:'ACCESSO NON CONSENTITO'}}});
					}
					else if (error.response && error.response.status === 400) {
						//dispatch({type: SNACKBAR_ERROR, payload: {serverError: {path: error.response.data.details[0].path, message:error.response.data.details[0].path + ' ' + error.response.data.details[0].message, type:error.response.data.details[0].type}}});
					}
					else{
						//dispatch({type: SNACKBAR_ERROR, payload: {serverError: {path: error.response.data.details[0].path, message:error.response.data.details[0].path + ' ' + error.response.data.details[0].message, type:error.response.data.details[0].type}}});
					}
					return dispatch(actionRequestOff(ERoleActionTypes.GET_FAILURE));
				}
			}

			return dispatch(actionRequestOff(ERoleActionTypes.GET_FAILURE));
		}
	},

	delete: (doc: IRoleDoc) => {
		return async (dispatch: ThunkDispatch<IApplicationState, void, Action>, getState: () => IApplicationState) => {
			dispatch(actionRequestOn(ERoleActionTypes.DELETE_REQUEST, doc));

			const sitesState = getState().sitesState;
			const params = !!sitesState && !!sitesState.params ? !!sitesState.params : {};
			const token: InitTokenReturn = await initToken();
			if (token.token) {
				try {
					// doc.siteId = config.site.id;

					axios.defaults.headers.common['Authorization'] = token.token;
					await axios.delete(`${config.site.apiURL}/auth/roles/${doc.id}`,{ data: { "$hardDelete": false }});
				}
				catch (error) {
					console.log('error: ', error);
					if (error.response && error.response.status === 401) {
						//dispatch({type: SNACKBAR_ERROR, payload: {serverError: {code: null, message:'ACCESSO NON AUTORIZZATO'}}});
					}
					else if (error.response && error.response.status === 403) {
						//dispatch({type: SNACKBAR_ERROR, payload: {serverError: {code: null, message:'ACCESSO NON CONSENTITO'}}});
					}
					else if (error.response && error.response.status === 400) {
						//dispatch({type: SNACKBAR_ERROR, payload: {serverError: {path: error.response.data.details[0].path, message:error.response.data.details[0].path + ' ' + error.response.data.details[0].message, type:error.response.data.details[0].type}}});
					}
					else{
						//dispatch({type: SNACKBAR_ERROR, payload: {serverError: {path: error.response.data.details[0].path, message:error.response.data.details[0].path + ' ' + error.response.data.details[0].message, type:error.response.data.details[0].type}}});
					}
					return dispatch(actionRequestOff(ERoleActionTypes.DELETE_FAILURE, doc));
				}
			}
			else {
				batch(() => {
					dispatch(push('/'));
					dispatch(actionRequestOff(ERoleActionTypes.DELETE_FAILURE));
					dispatch(actAuth.logout());
				});

			}
			batch(() => {
				dispatch(actRoles.get(params));
				dispatch(actionRequestOff(ERoleActionTypes.DELETE_SUCCESS, doc));
			});
		}
	},

};

// Service for Fetching
const actionRequestOn = (type: ERoleActionTypes, doc?: IRoleDoc) => {
	return (dispatch: ThunkDispatch<IApplicationState, void, Action>) => {
		const action: IRoleAction = {
			type: type,
			payload: {
				doc: !!doc ? doc : new RoleModel(),
				isFetching: true,
			},
		};
		dispatch(action);
	}
};

const actionRequestOff = (type: ERoleActionTypes, doc?: IRoleDoc) => {
	return (dispatch: ThunkDispatch<IApplicationState, void, Action>) => {
		const action: IRoleAction = {
			type: type,
			payload: {
				doc: !!doc ? doc : new RoleModel(),
				isFetching: false,
			},
		};
		dispatch(action);
	}
};
