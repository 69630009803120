import * as React from "react";
import {InjectedFormikProps, withFormik} from "formik";
import {IChurchDoc} from "../store/church/church";
import ChurchModel from "../models/churchModel";
import {validate} from "../validations/churchValidation";
import {EActionType} from "../helpers/interfaces/forQueryParams";
import {Box, Button, Form, FormField, Heading, Layer, Paragraph} from "grommet";
import {config} from "../appConfig";
import {ISiteDoc} from "../store/auth/site";
import {Trash} from "grommet-icons";


interface Props {
  selectRow: IChurchDoc | null,
  actionType: EActionType | null,
  submitModal: (actionType: EActionType, row: IChurchDoc) => void,
  closeModal: () => void,
  currentSite: ISiteDoc | null
}

const ChurchForm: React.FunctionComponent<InjectedFormikProps<Props, IChurchDoc>> = props => {
  const {handleSubmit, closeModal, values, actionType, setFieldValue, handleBlur, errors, touched, isSubmitting, currentSite} = props;

  const handleImageChange = (e) => {
    e.preventDefault();

    let reader = new FileReader();
    let uploadFile: File = e.target.files[0];
    reader.readAsDataURL(uploadFile);

    if (reader && uploadFile) {
      reader.onloadend = () => {
        const imagePath = reader.result as string;
        setFieldValue('attach.file', uploadFile);
        setFieldValue('attach.attach', imagePath);
      }
    }
  };

  const handleImageDelete = (e) => {
    e.preventDefault();

    if (values.attach.file && values.attach.attach) {
      setFieldValue('attach.file', null);
      setFieldValue('attach.attach', null);
    }

  };

  return (

      <Layer
          onEsc={closeModal}
          onClickOutside={closeModal}
      >
        <Box width={'large'} pad={"large"} overflow={"auto"} >
          <Heading color={'neutral-1'} margin="none" level={3}>{`${actionType} Church`}</Heading>
          <br/>
          {(actionType === EActionType.CREATE || actionType === EActionType.UPDATE) &&
          <Form>
            <FormField
                name="name"
                label="Name"
                value={values.name}
                error={touched.name && !!errors.name ? errors.name : ''}
                onBlur={handleBlur}
                onChange={event => setFieldValue('name', event.target.value)}/>
            <FormField
              name="date"
              label="Date"
              value={!!values.date ? values.date : ''}
              error={touched.date && !!errors.date ? errors.date : ''}
              onBlur={handleBlur}
              onChange={event => setFieldValue('date', event.target.value)}/>
            <FormField
                name="originalName"
                label="OriginalName"
                value={!!values.originalName ? values.originalName : '' }
                error={touched.originalName && !!errors.originalName ? errors.originalName : ''}
                onBlur={handleBlur}
                onChange={event => setFieldValue('originalName', event.target.value)}/>
            <FormField
                name="map"
                label="Map"
                value={!!values.map ? values.map : '' }
                error={touched.map && !!errors.map ? errors.map : ''}
                onBlur={handleBlur}
                onChange={event => setFieldValue('map', event.target.value)}/>
            <FormField
                name="number"
                label="Number"
                type="number"
                value={values.number}
                error={touched.number && !!errors.number ? errors.number : ''}
                onBlur={handleBlur}
                onChange={event => setFieldValue('number', event.target.value)}/>
            <FormField
                name="place"
                label="Place"
                value={!!values.place ? values.place : '' }
                error={touched.place && !!errors.place ? errors.place : ''}
                onBlur={handleBlur}
                onChange={event => setFieldValue('place', event.target.value)}/>
            <FormField
                name="x"
                label="X"
                value={!!values.x ? values.x : '' }
                error={touched.x && !!errors.x ? errors.x : ''}
                onBlur={handleBlur}
                onChange={event => setFieldValue('x', event.target.value)}/>
            <FormField
                name="y"
                label="Y"
                value={!!values.y ? values.y : '' }
                error={touched.y && !!errors.y ? errors.y : ''}
                onBlur={handleBlur}
                onChange={event => setFieldValue('y', event.target.value)}/>
            <div style={{display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              padding: '18px'}}>

              <Button type={"button"} primary label={<label htmlFor={"uploadSiteNewPhoto"}>Upload Image</label>}/>

              <input
                  accept="image/*"
                  id="uploadSiteNewPhoto"
                  style={{display:'none'}}
                  type="file"
                  onChange={ (e) => {handleImageChange(e)} }
              />

              {!!currentSite && !!currentSite.id &&
              <div style={{display: 'flex', justifyContent: "center", paddingTop: '5px'}}>
                <img style={{maxWidth:'180px',maxHeight:'100px'}}
                     src={
                       values.attach.attach ||
                       (!!values.siteChurchPhoto && `${config.site.apiURL}/site/${currentSite.id}/churchPhotos/photo/${values.siteChurchPhoto.fileName}`) ||
                       'https://via.placeholder.com/180x100'}
                     alt={'Image'}
                />
                {((values.attach && values.attach.file) || values.siteChurchPhoto) &&
                <Trash size={'small'} style={{cursor: 'pointer',paddingLeft: '10px'}} onClick={(e) => {handleImageDelete(e)}}/>
                }

              </div>
              }
            </div>

            <Box justify='end' direction={"row"} margin={{top: 'large'}} >
              <Button color='light-5' primary alignSelf={"end"} label="Cancel" onClick={closeModal}/>
              <Button disabled={isSubmitting} type="submit" primary alignSelf={"end"} margin={{left: 'small'}}
                      label="Save" onClick={()=>handleSubmit()}/>
            </Box>
          </Form>
          }
          {actionType === EActionType.DELETE &&
          <Form>
            <Paragraph margin="medium">
              Are you sure to delete church {`${values.name}`} ?
            </Paragraph>
            <Box justify='end' direction={"row"} margin={{top: 'large'}} >
              <Button color='light-5' primary alignSelf={"end"} label="Cancel" onClick={closeModal}/>
              <Button disabled={isSubmitting} type="submit" color={"red"} alignSelf={"end"} margin={{left: 'small'}}
                      label="Delete" onClick={()=>handleSubmit()}/>
            </Box>
          </Form>
          }

        </Box>
      </Layer>
  );
};


const churchForm = withFormik<Props, IChurchDoc>({
  enableReinitialize: true,
  validateOnChange: false,

  mapPropsToValues: (props): IChurchDoc => {
    console.log('FKProps: ', props.selectRow);
    if (!!props.selectRow){
      return {
        ...props.selectRow,
      };
    }
    else{
      return new ChurchModel();
    }

  },

  validate: (values: IChurchDoc) => {
    return validate(values, false);
  },

  handleSubmit: (values: IChurchDoc, { props, setSubmitting, setErrors }) => {
    console.log('Submit: ', values);
    setSubmitting(true);
    let errors = validate(values, true);

    if (Object.keys(errors).length > 0) {
      setErrors(errors);
      setSubmitting(false);
    }
    else {
      const doc = new ChurchModel(values);

      if (!!props.actionType)
        props.submitModal(props.actionType, doc);

      setSubmitting(false);
    }
  }
})(ChurchForm);

export default churchForm;
