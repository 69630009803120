import { INewState, ENewActionTypes } from '.';
import { Reducer } from 'redux';
import NewModel from "../../../models/newModel";

// Reducer initial State with ALL objects
export const initialState: INewState = {
	doc: new NewModel(),
	isFetching: false,
};

const reducer: Reducer<INewState> = (state = initialState, action) => {
	switch (action.type) {
		case ENewActionTypes.GET_REQUEST:
			return {
				...state,
				...action.payload,
			};
		case ENewActionTypes.GET_SUCCESS:
			return {
				...state,
					...action.payload,
			};
		case ENewActionTypes.GET_FAILURE:
			return {
				...state,
				...action.payload,
			};
		default:
			return state;
	}
};

export { reducer as NewReducer }
