import * as qs from "qs";
import {IDecodeParams} from "./types";


export const decode = (params: IDecodeParams): string => {
  const modelName: string = params.tableName.singular;
  const query: object = {};

  console.log('Params: ', params);
  if (!!params.extraQuery && params.extraQuery.length) {
    params.extraQuery.forEach(el => {
      if(!!query[el.kind])
        query[el.kind].push(el.value);
      else
        query[el.kind] = [el.value];
    });
  }

  if (!!params.paginationParams) {
    query['$page'] = params.paginationParams.page;
    query['$pageSize'] = params.paginationParams.limit;
  }

  if (!!params.sortParams && params.sortParams.length) {
    query['$sort'] = params.sortParams.map(el => {
      return `{${modelName}}${el.dir}${el.field}`
    });
  }

  console.log('Query: ', query);

  return qs.stringify(query, { indices: false });
};
