import {ERolesActionTypes, IRolesAction, IRolesState} from './index';
import {Reducer} from 'redux';



// Reducer initial State with ALL objects
export const initialState: IRolesState = {
	docs: [],
	isFetching: false,
	items: null,
	pages: null,
	params: {},
};

const reducer: Reducer<IRolesState> = (state: IRolesState = initialState, action: IRolesAction) => {
	switch (action.type) {
		case ERolesActionTypes.GET_REQUEST:
			return {
				...state,
				...action.payload,
			};
		case ERolesActionTypes.GET_SUCCESS:
			return {
				...state,
					...action.payload,
			};
		case ERolesActionTypes.GET_FAILURE:
			return {
				...state,
				...action.payload,
			};

		default:
			return state;
	}
};

export { reducer as RolesReducer }
