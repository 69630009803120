import * as React from 'react';
import { Route, Switch } from 'react-router-dom';

import SignIn from '../pages/signIn';
import UsersTable from '../pages/usersTable';
import RolesTable from '../pages/rolesTable';
import SitesTable from '../pages/sitesTable';
import SessionsTable from '../pages/sessionsTable';
import Dashboard from '../pages/dashboard';
import Churches from '../pages/churchesTable';
import News from '../pages/newsTable';

import PublicRoute from './PublicRoute';
import PrivateRoute from './PrivateRoute';
import * as routes from './RoutesList';


const NoMatch = () => (
	<div>
		{/*<Page404/>*/}
		{'NULLA di NULLA'}
	</div>
);


const AppRouter = () => {
	return (

			<Switch>
				<PublicRoute exact path={routes.SIGN_IN} component={SignIn} />
				<PublicRoute exact path={routes.LANDING} component={SignIn} />
				<PrivateRoute exact path={routes.USERS} component={UsersTable} />
				<PrivateRoute exact path={routes.ROLES} component={RolesTable} />
				<PrivateRoute exact path={routes.SITES} component={SitesTable} />
				<PrivateRoute exact path={routes.SESSIONS} component={SessionsTable} />
				<PrivateRoute exact path={routes.DASHBOARD} component={Dashboard} />
				<PrivateRoute exact path={routes.CHURCHES} component={Churches} />
				<PrivateRoute exact path={routes.NEWS} component={News} />
				<Route component={NoMatch}/>
			</Switch>
	);
};

export default AppRouter;
