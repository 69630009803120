import * as React from "react";
import {InjectedFormikProps, withFormik} from "formik";
import {ISessionDoc} from "../store/auth/session";
import SessionModel from "../models/sessionModel";
import {validate} from "../validations/sessionValidation";
import {EActionType} from "../helpers/interfaces/forQueryParams";
import {Box, Button, Form, FormField, Heading, Layer, Paragraph} from "grommet";

interface Props {
  selectRow: ISessionDoc | null,
  actionType: EActionType | null,
  submitModal: (actionType: EActionType, row: ISessionDoc) => void,
  closeModal: () => void,
}

const SessionForm: React.FunctionComponent<InjectedFormikProps<Props, ISessionDoc>> = props => {
  const {handleSubmit, closeModal, values, actionType, setFieldValue, handleBlur, errors, touched, isSubmitting} = props;

  return (
      <Layer
          onEsc={closeModal}
          onClickOutside={closeModal}
      >
        <Box width={'large'} pad={"large"} >
          <Heading color={'neutral-1'} margin="none" level={3}>{`${actionType} Session`}</Heading>
          <br/>
          {(actionType === EActionType.CREATE || actionType === EActionType.UPDATE) &&
          <Form>
            <FormField
                name="key"
                label="Key"
                value={values.key}
                error={touched.key && !!errors.key ? errors.key : ''}
                onBlur={handleBlur}
                onChange={event => setFieldValue('key', event.target.value)}/>
            <FormField
                name="userAgent"
                label="UserAgent"
                value={!!values.userAgent ? values.userAgent : '' }
                error={touched.userAgent && !!errors.userAgent ? errors.userAgent : ''}
                onBlur={handleBlur}
                onChange={event => setFieldValue('userAgent', event.target.value)}/>
            <FormField
                name="userId"
                label="UserId"
                value={!!values.userId ? values.userId : '' }
                error={touched.userId && !!errors.userId ? errors.userId : ''}
                onBlur={handleBlur}
                onChange={event => setFieldValue('userId', event.target.value)}/>

            <Box justify='end' direction={"row"} margin={{top: 'large'}} >
              <Button color='light-5' primary alignSelf={"end"} label="Cancel" onClick={closeModal}/>
              <Button disabled={isSubmitting} type="submit" primary alignSelf={"end"} margin={{left: 'small'}}
                      label="Save" onClick={()=>handleSubmit()}/>
            </Box>
          </Form>
          }
          {actionType === EActionType.DELETE &&
          <Form>
            <Paragraph margin="medium">
              Are you sure to delete session {`${values.key}`} ?
            </Paragraph>
            <Box justify='end' direction={"row"} margin={{top: 'large'}} >
              <Button color='light-5' primary alignSelf={"end"} label="Cancel" onClick={closeModal}/>
              <Button disabled={isSubmitting} type="submit" color={"red"} alignSelf={"end"} margin={{left: 'small'}}
                      label="Delete" onClick={()=>handleSubmit()}/>
            </Box>
          </Form>
          }

        </Box>
      </Layer>
  );
};


const sessionForm = withFormik<Props, ISessionDoc>({
  enableReinitialize: true,
  validateOnChange: false,

  mapPropsToValues: (props): ISessionDoc => {
    console.log('FKProps: ', props.selectRow);
    if (!!props.selectRow){
      return {
        ...props.selectRow,
      };
    }
    else{
      return new SessionModel();
    }

  },

  validate: (values: ISessionDoc) => {
    return validate(values, false);
  },

  handleSubmit: (values: ISessionDoc, { props, setSubmitting, setErrors }) => {
    console.log('Submit: ', values);
    setSubmitting(true);
    let errors = validate(values, true);

    if (Object.keys(errors).length > 0) {
      setErrors(errors);
      setSubmitting(false);
    }
    else {
      const doc = new SessionModel(values);

      if (!!props.actionType)
        props.submitModal(props.actionType, doc);

      setSubmitting(false);
    }
  }
})(SessionForm);

export default sessionForm;
