import {ISiteDoc} from "../store/auth/site";
import {ERoles, ETypes, IMUtil, ITableName} from "./types";
import { isDate } from 'date-fns';


export default class SiteModel implements ISiteDoc {

  public id?: 						string | null;
  public name:    			  string;
  public url:     			  string;
  public description:		  string | null;
  public email:					  string | null;
  public isMailing:				boolean;

  // In relations
  public _delete?:			boolean;
  public createdAt:		  Date;
  public updatedAt:		  Date;
  public deletedAt:		  Date | null;

  // Relation
  // public siteSitePhoto:  ISitePhotoDoc | null;
  // 4 Attach ONLY 1
  // public attach:        IAttach;

  constructor(doc?: ISiteDoc) {
    // Initial Value with input object (optional)
    this.id =             !!doc && doc.id ? doc.id : null;
    this.name =           !!doc && doc.name ? doc.name : '';
    this.url =            !!doc && doc.url ? doc.url : '';
    this.description =    !!doc && doc.description ? doc.description : null;
    this.email =					!!doc && doc.email ? doc.email : null;
    this.isMailing =	  	!!doc && doc.isMailing ? doc.isMailing : false;

    this.createdAt =		  !!doc && !!doc.createdAt ? isDate(doc.createdAt) ? doc.createdAt : new Date(doc.createdAt) : new Date();
    this.updatedAt =		  !!doc && !!doc.updatedAt ? isDate(doc.updatedAt) ? doc.updatedAt : new Date(doc.updatedAt) : new Date();
    this.deletedAt =	  	!!doc && !!doc.deletedAt ? isDate(doc.deletedAt) ? doc.deletedAt : new Date(doc.deletedAt) : null;

    // Relations
    // this.siteSitePhoto = !!doc && doc.siteSitePhoto ? new SitePhotoModel(doc.siteSitePhoto) : null;
    // 4 Attach
    // this.attach =       !!doc && doc.attach ? AttachModel.toDB2(doc.attach) : AttachModel.toDB2(new AttachModel());
    // this.attach = {
    //   file:   !!doc && doc.attach && doc.attach.file ? doc.attach.file : null,
    //   attach: !!doc && doc.attach && doc.attach.attach ? doc.attach.attach : null,
    // }
  }

  // Object to DB
  public toDB() {
    const docForDB = Object.assign({}, this);
    if (!this.id) {
      delete docForDB.id
    }
    // if (!!this.siteSitePhoto) {
    //   docForDB.siteSitePhoto = SitePhotoModel.toDB2(this.siteSitePhoto);
    // }
    // else {
    //   delete docForDB.siteSitePhoto
    // }
    delete docForDB.createdAt;
    delete docForDB.updatedAt;
    delete docForDB.deletedAt;

    // delete docForDB.attach;

    return docForDB;
  }

  // Object to DB
  static toDB2(doc: ISiteDoc): ISiteDoc {
    const docForDB = Object.assign({}, doc);
    if (!doc.id) {
      delete docForDB.id
    }

    // if (!!doc.siteSitePhoto) {
    //   docForDB.siteSitePhoto = SitePhotoModel.toDB2(doc.siteSitePhoto);
    // }
    // else {
    //   delete docForDB.siteSitePhoto
    // }

    delete docForDB.createdAt;
    delete docForDB.updatedAt;
    delete docForDB.deletedAt;

    // delete docForDB.attach;

    return docForDB;
  }


  // STATIC methods
  static util(): IMUtil {
    return {
      id: {
        name:     'id',
        forSearch: {
          forRoles:   [ERoles.SUPER_ADMIN],
          relation:   {},
        },
        type:     ETypes.STRING,
        forTable: {
          visibleFor: [],
          isSortable: false,
        },
      },

      name: {
        name:     'name',
        forSearch: {
          forRoles: [ERoles.SUPER_ADMIN, ERoles.ADMIN, ERoles.MANAGER, ERoles.EDITOR, ERoles.USER],
          relation: {},
        },
        type:     ETypes.STRING,
        forTable: {
          visibleFor: [ERoles.SUPER_ADMIN, ERoles.ADMIN, ERoles.MANAGER, ERoles.EDITOR, ERoles.USER],
          isSortable: true,
        },
      },

      url: {
        name:     'url',
        forSearch: {
          forRoles: [ERoles.SUPER_ADMIN, ERoles.ADMIN, ERoles.MANAGER, ERoles.EDITOR, ERoles.USER],
          relation: {},
        },
        type:     ETypes.STRING,
        forTable: {
          visibleFor: [ERoles.SUPER_ADMIN, ERoles.ADMIN, ERoles.MANAGER, ERoles.EDITOR, ERoles.USER],
          isSortable: true,
        },
      },

      description: {
        name:     'description',
        forSearch: {
          forRoles: [ERoles.SUPER_ADMIN, ERoles.ADMIN, ERoles.MANAGER, ERoles.EDITOR, ERoles.USER],
          relation: {},
        },
        type:     ETypes.STRING,
        forTable: {
          visibleFor: [ERoles.SUPER_ADMIN, ERoles.ADMIN, ERoles.MANAGER, ERoles.EDITOR, ERoles.USER],
          isSortable: true,
        },
      },

      email: {
        name:     'email',
        forSearch: {
          forRoles: [ERoles.SUPER_ADMIN, ERoles.ADMIN, ERoles.MANAGER, ERoles.EDITOR, ERoles.USER],
          relation: {},
        },
        type:     ETypes.STRING,
        forTable: {
          visibleFor: [ERoles.SUPER_ADMIN, ERoles.ADMIN, ERoles.MANAGER, ERoles.EDITOR, ERoles.USER],
          isSortable: true,
        },
      },

      isMailing: {
        name:      'mailing',
        forSearch: {
          forRoles: [ERoles.SUPER_ADMIN, ERoles.ADMIN, ERoles.MANAGER, ERoles.EDITOR, ERoles.USER],
          relation: {},
        },
        type:     ETypes.BOOLEAN,
        forTable: {
          visibleFor: [ERoles.SUPER_ADMIN, ERoles.ADMIN, ERoles.MANAGER, ERoles.EDITOR, ERoles.USER],
          isSortable: true,
        },
      },

      createdAt: {
        name:      'created at',
        forSearch: {
          forRoles: [ERoles.SUPER_ADMIN, ERoles.ADMIN, ERoles.MANAGER, ERoles.EDITOR, ERoles.USER],
          relation: {},
        },
        type:     ETypes.DATE,
        forTable: {
          visibleFor: [ERoles.SUPER_ADMIN, ERoles.ADMIN, ERoles.MANAGER, ERoles.EDITOR, ERoles.USER],
          isSortable: true,
        },
      },

      updatedAt: {
        name:      'updated at',
        forSearch: {
          forRoles: [ERoles.SUPER_ADMIN, ERoles.ADMIN],
          relation: {},
        },
        type:     ETypes.DATE,
        forTable: {
          visibleFor: [],
          isSortable: false,
        },
      },

      deletedAt: {
        name:      'deleted at',
        forSearch: {
          forRoles: [ERoles.SUPER_ADMIN, ERoles.ADMIN],
          relation: {},
        },
        type:     ETypes.DATE,
        forTable: {
          visibleFor: [],
          isSortable: false,
        },
      },

    };
  }

  static tableName(): ITableName {
    return {
      singular: 'authSite',
      plural:   'authSites',
    }
  }

}
