import {Box, Button, Collapsible, Layer} from "grommet";
import {FormClose} from "grommet-icons";
import * as React from "react";
import {connect} from 'react-redux';
import {IApplicationState} from "../../store";
import {actAuth, IAuthState} from "../../store/auth/auth";

import { Sidebar } from './sidebar';
import {actSites, ISitesState} from "../../store/auth/sites";
import {ISiteDoc} from "../../store/auth/site";
import {ThunkDispatch} from "redux-thunk";
import {Action} from "redux";
import {IParamsTypes} from "../../helpers/interfaces/forQueryParams";
import {actUsers} from "../../store/auth/users";
import {actRoles} from "../../store/auth/roles";
import {actSessions} from "../../store/auth/sessions";
import {actChurches} from "../../store/church/churches";
import {actNews} from "../../store/new/news";

interface PropsFromState {
  authState:  IAuthState,
  sitesState: ISitesState
}

interface PropsFromDispatch {
  authChangeSite:   (value: ISiteDoc | null) => void,

  actUsersGetAll: (param: IParamsTypes) => void,
  actRolesGetAll: (param: IParamsTypes) => void,
  actSitesGetAll: (param: IParamsTypes) => void,
  actSessionsGetAll: (param: IParamsTypes) => void,
  actChurchesGetAll: (param: IParamsTypes) => void,
  actNewsGetAll: (param: IParamsTypes) => void,
}

interface IProps {
  showSidebar: boolean,
  size: string,
}

type Props = PropsFromState & IProps & PropsFromDispatch


const Body: React.FunctionComponent<Props> = (props) => {

  const {showSidebar, size, authState, sitesState, authChangeSite, actRolesGetAll, actSessionsGetAll,
         actSitesGetAll, actUsersGetAll, actChurchesGetAll, actNewsGetAll} = props;

  return(
    <Box direction='row' flex overflow={{ horizontal: 'hidden' }}>
      {(!showSidebar || size !== 'small') ? (
        <Collapsible direction="horizontal" open={(showSidebar || size === 'large') && (authState.isAuthenticated)}>
          <Box
            flex
            width='medium'
            background='dark-4'
            elevation='small'
            align='stretch'
            justify='start'
          >
            <Sidebar authState={authState} sitesState={sitesState} authChangeSite={authChangeSite}
                     actRolesGetAll={actRolesGetAll} actSessionsGetAll={actSessionsGetAll}
                     actSitesGetAll={actSitesGetAll} actUsersGetAll={actUsersGetAll}
                     actChurchesGetAll={actChurchesGetAll}
                     actNewsGetAll={actNewsGetAll}
            />
          </Box>
        </Collapsible>
      ) : (
        <Layer>
          <Box
            background='dark-4'
            tag='header'
            justify='end'
            align='center'
            direction='row'
          >
            <Button
              icon={<FormClose />}
              // onClick={() => this.setState({ showSidebar: false })}
            />
          </Box>
          <Box
            fill
            background='dark-4'
            align='start'
            justify='start'
          >
            <Sidebar authState={authState} sitesState={sitesState}/>
          </Box>
        </Layer>
      )}

      {authState.isAuthenticated ? (
        <Box flex align='stretch' justify='start' background={'dark-1'} >
          {props.children}
        </Box>
      ) : (
        <Box flex align='center' justify='start' background={'dark-1'} pad={{"top": "8%"}}>
          {props.children}
        </Box>
      )
      }

    </Box>
  )

};

function mapStateToProps(state: IApplicationState) {
  return {
    authState:  state.authState,
    sitesState: state.sitesState,
  };
}

// Mapping our action dispatcher to props is especially useful when creating container components.
const mapDispatchToProps = (dispatch: ThunkDispatch<IApplicationState, void, Action>): PropsFromDispatch => ({
  // Routines Actions
  authChangeSite: (value: ISiteDoc | null) => dispatch(actAuth.changeSite(value)),

  actUsersGetAll: (param: IParamsTypes) => dispatch(actUsers.get(param)),
  actRolesGetAll: (param: IParamsTypes) => dispatch(actRoles.get(param)),
  actSitesGetAll: (param: IParamsTypes) => dispatch(actSites.get(param)),
  actSessionsGetAll: (param: IParamsTypes) => dispatch(actSessions.get(param)),
  actChurchesGetAll: (param: IParamsTypes) => dispatch(actChurches.get(param)),
  actNewsGetAll: (param: IParamsTypes) => dispatch(actNews.get(param)),
});



export default connect<IApplicationState, PropsFromDispatch>(mapStateToProps, mapDispatchToProps)(Body);
