import { IChurchState, EChurchActionTypes } from '.';
import { Reducer } from 'redux';
import ChurchModel from "../../../models/churchModel";

// Reducer initial State with ALL objects
export const initialState: IChurchState = {
	doc: new ChurchModel(),
	isFetching: false,
};

const reducer: Reducer<IChurchState> = (state = initialState, action) => {
	switch (action.type) {
		case EChurchActionTypes.GET_REQUEST:
			return {
				...state,
				...action.payload,
			};
		case EChurchActionTypes.GET_SUCCESS:
			return {
				...state,
					...action.payload,
			};
		case EChurchActionTypes.GET_FAILURE:
			return {
				...state,
				...action.payload,
			};
		default:
			return state;
	}
};

export { reducer as ChurchReducer }
