import {Box, Heading} from "grommet";
import * as React from "react";



const MainFooter = (props) => (
  <Box
    tag='footer'
    direction='row'
    align='center'
    justify='center'
    background={'accent-4'}
    pad={{ left: 'medium', right: 'small', vertical: 'small' }}
    {...props}
  />
);


export const Footer: React.FC = () => {

  return(
    <MainFooter>
      <Heading level='5' margin='none'>Powered by Yoo-No Lab Srl</Heading>
    </MainFooter>

  )
};
