import { ENewActionTypes, INewAction, INewDoc } from "./index";
import { Action } from "redux";
import { ThunkDispatch } from "redux-thunk";
import { IApplicationState } from "../../index";
import NewModel from "../../../models/newModel";
import {initToken} from "../../../helpers/token/auth_helper";
import {actAuth, IAuthState} from "../../auth/auth";
import axios from "axios";
import {config} from "../../../appConfig";
import {IAttach, IServerResponseOne} from "../../types";
import {InitTokenReturn} from "../../../helpers/token/types";
import {actNews} from "../news";
//import {EMessageTypes, IMessage, messageActions} from "../../message";
import NewPhotoModel from "../../../models/newPhotoModel";
import {ISiteDoc} from "../../auth/site";
import {push} from "connected-react-router";

interface INewResponse extends IServerResponseOne {
	doc: INewDoc,
}

export const actNew = {
	create: (doc: INewDoc, attach: IAttach) => {
		return async (dispatch: ThunkDispatch<IApplicationState, void, Action>, getState: () => IApplicationState) => {
			dispatch(actionRequestOn(ENewActionTypes.POST_REQUEST, doc));

			if (!!attach.file && !!attach.attach){
				doc.siteNewPhoto = new NewPhotoModel();
				doc.siteNewPhoto.originalName = attach.file.name;
				doc.siteNewPhoto.mimeType = attach.file.type;
				doc.siteNewPhoto.size = attach.file.size;
				doc.siteNewPhoto.image = attach.attach;
			}

			const newsState = getState().newsState;
			const params = !!newsState && !!newsState.params ? !!newsState.params : {};
			console.log('Init Token');
			const token: InitTokenReturn = await initToken();
			if (token.token) {
				try {
					const currentUser:IAuthState = getState().authState;
					const currentSite: ISiteDoc | null =  !!currentUser && !!currentUser.selectedSite ? currentUser.selectedSite : null ;

					if(!!currentSite && !!currentSite.id ){
						doc.siteId = currentSite.id;
						axios.defaults.headers.common['Authorization'] = token.token;
						await axios.post<INewResponse>(`${config.site.apiURL}/site/${currentSite.id}/news`, NewModel.toDB2(doc));
					}
				}
				catch (error) {
					console.log('error: ', error.response);
					if (error.response && error.response.status === 401) {
						//dispatch({type: SNACKBAR_ERROR, payload: {serverError: {code: null, message:'ACCESSO NON AUTORIZZATO'}}});
					}
					else if (error.response && error.response.status === 403) {
						//dispatch({type: SNACKBAR_ERROR, payload: {serverError: {code: null, message:'ACCESSO NON CONSENTITO'}}});
					}
					else if (error.response && error.response.status === 400) {
						/*error.response.data.details.map(error => {
							const message: IMessage = {
								code: error.context.label==='image' ? 'attach.file' : error.context.label,
								message: error.message,
								type: EMessageTypes.ERROR,
							};
							console.log('Msg: ', message);
							dispatch(messageActions.add(message));
						});*/
					}
					else{
						//dispatch({type: SNACKBAR_ERROR, payload: {serverError: {path: error.response.data.details[0].path, message:error.response.data.details[0].path + ' ' + error.response.data.details[0].message, type:error.response.data.details[0].type}}});
					}
					return dispatch(actionRequestOff(ENewActionTypes.POST_FAILURE, doc));
				}
			}
			else {
				dispatch(push('/'));
				dispatch(actionRequestOff(ENewActionTypes.POST_FAILURE));
				return dispatch(actAuth.logout());
			}
			dispatch(actNews.get(params));
			return dispatch(actionRequestOff(ENewActionTypes.POST_SUCCESS, doc));
		}
	},

	update: (doc: INewDoc, attach: IAttach) => {
		return async (dispatch: ThunkDispatch<IApplicationState, void, Action>, getState: () => IApplicationState) => {
			dispatch(actionRequestOn(ENewActionTypes.PUT_REQUEST, doc));

			if (!!attach.file && !!attach.attach){
				doc.siteNewPhoto = !!doc.siteNewPhoto ? doc.siteNewPhoto : new NewPhotoModel();
				doc.siteNewPhoto.originalName = attach.file.name;
				doc.siteNewPhoto.mimeType = attach.file.type;
				doc.siteNewPhoto.size = attach.file.size;
				doc.siteNewPhoto.image = attach.attach;
				doc.siteNewPhoto.newId = !!doc.id ? doc.id : 0;
			}

			const newsState = getState().newsState;
			const params = !!newsState && !!newsState.params ? !!newsState.params : {};
			const token: InitTokenReturn = await initToken();
			if (token.token) {
				try {
					const currentUser:IAuthState = getState().authState;
					const currentSite: ISiteDoc | null =  !!currentUser && !!currentUser.selectedSite ? currentUser.selectedSite : null ;

					if(!!currentSite && !!currentSite.id ){
						doc.siteId = currentSite.id;
						axios.defaults.headers.common['Authorization'] = token.token;
						await axios.put<INewResponse>(`${config.site.apiURL}/site/${currentSite.id}/news/${doc.id}`, NewModel.toDB2(doc));
					}
				}
				catch (error) {
					console.log('error: ', error);
					if (error.response && error.response.status === 401) {
						//dispatch({type: SNACKBAR_ERROR, payload: {serverError: {code: null, message:'ACCESSO NON AUTORIZZATO'}}});
					}
					else if (error.response && error.response.status === 403) {
						//dispatch({type: SNACKBAR_ERROR, payload: {serverError: {code: null, message:'ACCESSO NON CONSENTITO'}}});
					}
					else if (error.response && error.response.status === 400) {
						//dispatch({type: SNACKBAR_ERROR, payload: {serverError: {path: error.response.data.details[0].path, message:error.response.data.details[0].path + ' ' + error.response.data.details[0].message, type:error.response.data.details[0].type}}});
					}
					else{
						//dispatch({type: SNACKBAR_ERROR, payload: {serverError: {path: error.response.data.details[0].path, message:error.response.data.details[0].path + ' ' + error.response.data.details[0].message, type:error.response.data.details[0].type}}});
					}
					return dispatch(actionRequestOff(ENewActionTypes.PUT_FAILURE, doc));
				}
			}
			else {
				dispatch(push('/'));
				dispatch(actionRequestOff(ENewActionTypes.PUT_FAILURE));
				return dispatch(actAuth.logout());
			}
			dispatch(actNews.get(params));
			return dispatch(actionRequestOff(ENewActionTypes.PUT_SUCCESS, doc));
		}
	},

	get: (doc: INewDoc) => {
		return (dispatch: ThunkDispatch<IApplicationState, void, Action> ) => {   //, getState: Function
			dispatch(actionRequestOn(ENewActionTypes.GET_REQUEST));

			dispatch(actionRequestOff(ENewActionTypes.GET_SUCCESS, doc));
		}
	},

	delete: (doc: INewDoc, attach: IAttach) => {
		return async (dispatch: ThunkDispatch<IApplicationState, void, Action>, getState: () => IApplicationState) => {
			dispatch(actionRequestOn(ENewActionTypes.DELETE_REQUEST, doc));

			console.log(attach.file);
			// CAPIRE SE DEVE ESSERE CANCELLATA ANCHE LA FOTO
			/*if (!!file && !!image){
				doc.siteNewPhoto = !!doc.siteNewPhoto ? doc.siteNewPhoto : new NewPhotoModel();
				doc.siteNewPhoto.originalName = file.name;
				doc.siteNewPhoto.mimeType = file.type;
				doc.siteNewPhoto.size = file.size;
				doc.siteNewPhoto.image = image;
			}*/

			const newsState = getState().newsState;
			const params = !!newsState && !!newsState.params ? !!newsState.params : {};
			const token: InitTokenReturn = await initToken();
			if (token.token) {
				try {
					const currentUser:IAuthState = getState().authState;
					const currentSite: ISiteDoc | null =  !!currentUser && !!currentUser.selectedSite ? currentUser.selectedSite : null ;

					if(!!currentSite && !!currentSite.id ){
						doc.siteId = currentSite.id;
						axios.defaults.headers.common['Authorization'] = token.token;
						await axios.delete(`${config.site.apiURL}/site/${currentSite.id}/news/${doc.id}`,{ data: { "$hardDelete": false }});

					}
				}
				catch (error) {
					console.log('error: ', error);
					if (error.response && error.response.status === 401) {
						//dispatch({type: SNACKBAR_ERROR, payload: {serverError: {code: null, message:'ACCESSO NON AUTORIZZATO'}}});
					}
					else if (error.response && error.response.status === 403) {
						//dispatch({type: SNACKBAR_ERROR, payload: {serverError: {code: null, message:'ACCESSO NON CONSENTITO'}}});
					}
					else if (error.response && error.response.status === 400) {
						//dispatch({type: SNACKBAR_ERROR, payload: {serverError: {path: error.response.data.details[0].path, message:error.response.data.details[0].path + ' ' + error.response.data.details[0].message, type:error.response.data.details[0].type}}});
					}
					else{
						//dispatch({type: SNACKBAR_ERROR, payload: {serverError: {path: error.response.data.details[0].path, message:error.response.data.details[0].path + ' ' + error.response.data.details[0].message, type:error.response.data.details[0].type}}});
					}
					return dispatch(actionRequestOff(ENewActionTypes.DELETE_FAILURE, doc));
				}
			}
			else {
				dispatch(push('/'));
				dispatch(actionRequestOff(ENewActionTypes.DELETE_FAILURE));
				return dispatch(actAuth.logout());
			}
			dispatch(actNews.get(params));
			return dispatch(actionRequestOff(ENewActionTypes.DELETE_SUCCESS, doc));
		}
	},

};

// Service for Fetching
const actionRequestOn = (type: ENewActionTypes, doc?: INewDoc) => {
	return (dispatch: ThunkDispatch<IApplicationState, void, Action>) => {
		const action: INewAction = {
			type: type,
			payload: {
				doc: !!doc ? doc : new NewModel(),
				isFetching: true,
			},
		};
		dispatch(action);
	}
};

const actionRequestOff = (type: ENewActionTypes, doc?: INewDoc) => {
	return (dispatch: ThunkDispatch<IApplicationState, void, Action>) => {
		const action: INewAction = {
			type: type,
			payload: {
				doc: !!doc ? doc : new NewModel(),
				isFetching: false,
			},
		};
		dispatch(action);
	}
};
