import { ILanguageState, ELanguageActionTypes } from '.';
import { Reducer } from 'redux';

const isServer = typeof window === 'undefined';

const language = (!isServer && !!navigator) ? (navigator.languages && navigator.languages[0]) || navigator.language : 'it-IT';

// Reducer initial State with ALL objects
export const initialState: ILanguageState = {
	language,
};

const reducer: Reducer<ILanguageState> = (state = initialState, action) => {
	switch (action.type) {
		case ELanguageActionTypes.LANGUAGE_CHANGE:
			return {
				...state,
				...action.payload,
			};

		default:
			return state;
	}
};

export { reducer as LanguageReducer }
