import * as React from "react";
import {useEffect, useState} from "react";
import {InjectedFormikProps, withFormik} from "formik";
import {IUserDoc} from "../store/auth/user";
import UserModel from "../models/userModel";
import {validate} from "../validations/userValidation";
import {EActionType} from "../helpers/interfaces/forQueryParams";
import {Box, Button, CheckBox, Form, FormField, Heading, Layer, Paragraph, Select} from "grommet";
import {IRolesState} from "../store/auth/roles";
import {ISitesState} from "../store/auth/sites";
import {IRoleSiteUserDoc} from "../store/auth/roleSiteUser";
import RoleSiteUserModel from "../models/roleSiteUserModel";
import {Trash} from "grommet-icons";

interface Props {
  selectRow: IUserDoc | null,
  actionType: EActionType | null,
  roles: IRolesState,
  sites: ISitesState,
  submitModal: (actionType: EActionType, row: IUserDoc) => void,
  closeModal: () => void,
}

interface IRoleSelected {
  value?:   number | null;
  label:    string;
}

interface ISiteSelected {
  value?:   string | null;
  label:    string;
}

const UserForm: React.FunctionComponent<InjectedFormikProps<Props, IUserDoc>> = props => {
  const {handleSubmit, closeModal, values, actionType, setFieldValue, handleBlur, errors, touched,
    isSubmitting, roles, sites} = props;

  console.log(values);

  const rolesOptions = roles.docs.map(role => {return {value: role.id,label:role.name}});
  rolesOptions.push({value:null, label:'No Role'});
  const sitesOptions = sites.docs.map(site => {return {value: site.id,label:site.name}});

  const [siteSelected, setSiteSelected] = useState<ISiteSelected>(!!values.userRRU && values.userRRU.length ?
      sitesOptions.filter(x => x.value === values.userRRU[0].siteId)[0] :
      {value: null, label: ''});

  const [roleSelected, setRoleSelected] = useState<IRoleSelected>(!!values.userRRU && values.userRRU.length ?
      rolesOptions.filter(x => x.value === values.userRRU[0].roleId)[0] :
      {value: null, label: ''});

  useEffect(() => {

    if (!!siteSelected){
      const userRRU= values.userRRU.filter(x => x.siteId === siteSelected.value)[0];

      if (!!userRRU){
        setSiteSelected(!!values.userRRU && values.userRRU.length ?
            sitesOptions.filter(x => x.value === userRRU.siteId)[0] :
            {value: null, label: ''});


        setRoleSelected(!!values.userRRU && values.userRRU.length  ?
            rolesOptions.filter(x => x.value === userRRU.roleId)[0] :
            rolesOptions.filter(x => x.value === null)[0]);
      }else {
        setRoleSelected(rolesOptions.filter(x => x.value === null)[0]);
      }
    }

  }, [values.userRRU]);


  const handleSiteChange = (option) =>{
    const userRRU = [...values.userRRU];
    const userRRUSite = !!userRRU && userRRU.length ? userRRU.filter(x => x.siteId === option.value)[0] : null;
    if (!!userRRUSite){
      setRoleSelected(rolesOptions.filter(x => x.value === userRRUSite.roleId)[0])
    }else{
      setRoleSelected(rolesOptions.filter(x => x.value === null)[0]);
    }
    setSiteSelected(option);
  };

  const handleRoleChange = (option) =>{
    const userRRU = [...values.userRRU];
    const userRRUSite = !!userRRU && userRRU.length ? userRRU.filter(x => x.siteId === siteSelected.value)[0] : null;
    if (!!userRRUSite){
      if (option.value === null){
        if (!!userRRUSite.id){
          userRRUSite.roleId = option.value;
          userRRUSite._delete = true;
        }
        else{
          userRRU.splice(userRRU.indexOf(userRRUSite, 1));
        }
      }
      else{
        userRRUSite.roleId = option.value;
        userRRUSite._delete = false;
      }
    }
    else{
      let userRRUSite: IRoleSiteUserDoc = {
        id: null,
        userId: !!values.id ? values.id : null,
        roleId: option.value,
        siteId: !!siteSelected.value ? siteSelected.value : null,
      };
      userRRU.push(new RoleSiteUserModel(userRRUSite));
    }
    setFieldValue('userRRU', userRRU)
  };

  return (
      <Layer
          onEsc={closeModal}
          onClickOutside={closeModal}
      >
        <Box width={'large'} pad={"large"} >
          <Heading color={'neutral-1'} margin="none" level={3}>{`${actionType} User`}</Heading>
          <br/>
          {(actionType === EActionType.CREATE || actionType === EActionType.UPDATE) &&
          <Form>
            <FormField
                name="firstName"
                label="Firstname"
                value={values.firstName}
                error={touched.firstName && !!errors.firstName ? errors.firstName : ''}
                onBlur={handleBlur}
                onChange={event => setFieldValue('firstName', event.target.value)}/>
            <FormField
                name="lastName"
                label="Lastname"
                value={values.lastName}
                error={touched.lastName && !!errors.lastName ? errors.lastName : ''}
                onBlur={handleBlur}
                onChange={event => setFieldValue('lastName', event.target.value)}/>
            <FormField
                name="email"
                label="Email"
                value={values.email}
                error={touched.email && !!errors.email ? errors.email : ''}
                onBlur={handleBlur}
                onChange={event => setFieldValue('email', event.target.value)}/>
            <FormField
                name="password"
                type={'password'}
                label="Password"
                value={values.password}
                error={touched.password && !!errors.password ? errors.password : ''}
                onBlur={handleBlur}
                onChange={event => setFieldValue('password', event.target.value)}/>
            <Box justify='between' direction={"row"} >
              <FormField label="Site">
                <Select
                    size={"medium"}
                    labelKey="label"
                    valueKey="value"
                    options={sitesOptions}
                    value={siteSelected}
                    onChange={({ option }) => handleSiteChange(option)}
                />
              </FormField>
              <FormField label="Role">
                <Select
                    size={"medium"}
                    labelKey="label"
                    valueKey="value"
                    options={rolesOptions}
                    value={roleSelected}
                    onChange={({ option }) => handleRoleChange(option)}
                />
              </FormField>
              <Button
                  alignSelf={"end"}
                  icon={<Trash />}
                  onClick={() => handleRoleChange({value:null, label:'No Role'})}
              />
            </Box>
            <CheckBox
                checked={values.isActive}
                label="Is Active"
                onChange={event => setFieldValue('isActive', event.target.checked)}/>

            <Box justify='end' direction={"row"} margin={{top: 'large'}} >
              <Button color='light-5' primary alignSelf={"end"} label="Cancel" onClick={closeModal}/>
              <Button disabled={isSubmitting} type="submit" primary alignSelf={"end"} margin={{left: 'small'}}
                      label="Save" onClick={()=>handleSubmit()}/>
            </Box>
          </Form>
          }
          {actionType === EActionType.DELETE &&
          <Form>
            <Paragraph margin="medium">
              Are you sure to delete user {`${values.firstName} ${values.lastName}`} ?
            </Paragraph>
            <Box justify='end' direction={"row"} margin={{top: 'large'}} >
              <Button color='light-5' primary alignSelf={"end"} label="Cancel" onClick={closeModal}/>
              <Button disabled={isSubmitting} type="submit" color={"red"} alignSelf={"end"} margin={{left: 'small'}}
                      label="Delete" onClick={()=>handleSubmit()}/>
            </Box>
          </Form>
          }

        </Box>
      </Layer>
  );
};


const userForm = withFormik<Props, IUserDoc>({
  enableReinitialize: true,
  validateOnChange: false,

  mapPropsToValues: (props): IUserDoc => {
    console.log('FKProps: ', props.selectRow);
    if (!!props.selectRow){
      return {
        ...props.selectRow,
      };
    }
    else{
      return new UserModel();
    }

  },

  validate: (values: IUserDoc) => {
    return validate(values, false);
  },

  handleSubmit: (values: IUserDoc, { props, setSubmitting, setErrors }) => {
    console.log('Submit: ', values);
    setSubmitting(true);
    let errors = validate(values, true);

    if (Object.keys(errors).length > 0) {
      setErrors(errors);
      setSubmitting(false);
    }
    else {
      const doc = new UserModel(values);

      if (!!props.actionType)
        props.submitModal(props.actionType, doc);

      setSubmitting(false);
    }
  }
})(UserForm);

export default userForm;
