import { EUserActionTypes, IUserAction, IUserDoc } from "./index";
import { Action } from "redux";
import { batch } from 'react-redux';
import { ThunkDispatch } from "redux-thunk";
import { IApplicationState } from "../../index";
import UserModel from "../../../models/userModel";
import {initToken} from "../../../helpers/token/auth_helper";
import {actAuth, IAuthState} from "../auth";
import axios from "axios";
import {config} from "../../../appConfig";
import {IAttach, IServerResponseOne} from "../../types";
import {InitTokenReturn} from "../../../helpers/token/types";
import {actUsers} from "../users";
import {push} from "connected-react-router";
import {ISiteDoc} from "../site";
import {isSuperAdmin} from "../../../helpers/roles/role_helper";
// import {EMessageTypes, IMessage, messageActions} from "../../message";
// import UserPhotoModel from "../../../models/userPhotoModel";

interface IUserResponse extends IServerResponseOne {
	doc: IUserDoc,
}

export const actUser = {
	create: (doc: IUserDoc, attach?: IAttach) => {
		return async (dispatch: ThunkDispatch<IApplicationState, void, Action>, getState: () => IApplicationState) => {
			dispatch(actionRequestOn(EUserActionTypes.POST_REQUEST, doc));

			const usersState = getState().usersState;
			const params = !!usersState && !!usersState.params ? usersState.params : {};
			const token: InitTokenReturn = await initToken();

			console.log('User State: ', usersState.params);
			console.log('User Params: ', params);

			if (token.token) {
				try {
					const currentUser: IAuthState = getState().authState;
					const currentSite: ISiteDoc | null =  !!currentUser && !!currentUser.selectedSite ? currentUser.selectedSite : null ;
					let url = `${config.site.apiURL}/auth/users`;
					if (!!currentUser && !!currentUser.user && isSuperAdmin(currentUser.user)){
						if(!!currentSite){
							url = `${config.site.apiURL}/site/${currentSite.id}/users`;
						}
					}
					else{
						if(!!currentSite){
							url = `${config.site.apiURL}/site/${currentSite.id}/users`;
						}
					}
					axios.defaults.headers.common['Authorization'] = token.token;

					await axios.post<IUserResponse>(url, UserModel.toDB2(doc));
					// const newDoc: IUserDoc | null = response.data.doc ? response.data.doc : null;
				}
				catch (error) {
					console.log('error: ', error.response);
					if (error.response && error.response.status === 401) {
						//dispatch({type: SNACKBAR_ERROR, payload: {serverError: {code: null, message:'ACCESSO NON AUTORIZZATO'}}});
					}
					else if (error.response && error.response.status === 403) {
						//dispatch({type: SNACKBAR_ERROR, payload: {serverError: {code: null, message:'ACCESSO NON CONSENTITO'}}});
					}
					else if (error.response && error.response.status === 400) {
						error.response.data.details.map(error => {
							// const message: IMessage = {
							// 	code: error.context.label==='image' ? 'attach.file' : error.context.label,
							// 	message: error.message,
							// 	type: EMessageTypes.ERROR,
							// };
							console.log('Msg: ', error);
							// dispatch(messageActions.add(message));
						});
					}
					else{
						//dispatch({type: SNACKBAR_ERROR, payload: {serverError: {path: error.response.data.details[0].path, message:error.response.data.details[0].path + ' ' + error.response.data.details[0].message, type:error.response.data.details[0].type}}});
					}
					return dispatch(actionRequestOff(EUserActionTypes.POST_FAILURE, doc));
				}
			}
			else {
				batch(() => {
					dispatch(push('/'));
					dispatch(actionRequestOff(EUserActionTypes.POST_FAILURE));
					dispatch(actAuth.logout());
				});
			}
			batch(() => {
				dispatch(actUsers.get(params));
				dispatch(actionRequestOff(EUserActionTypes.POST_SUCCESS, doc));
			});
		}
	},

	update: (doc: IUserDoc, attach?: IAttach) => {
		return async (dispatch: ThunkDispatch<IApplicationState, void, Action>, getState: () => IApplicationState) => {
			dispatch(actionRequestOn(EUserActionTypes.PUT_REQUEST, doc));

			const usersState = getState().usersState;
			const params = !!usersState && !!usersState.params ? usersState.params : {};
			const token: InitTokenReturn = await initToken();
			if (token.token) {
				try {
					// doc.siteId = config.site.id;

					axios.defaults.headers.common['Authorization'] = token.token;
					await axios.put<IUserResponse>(`${config.site.apiURL}/auth/users/${doc.id}`, UserModel.toDB2(doc));
					// const newDoc: IUserDoc | null = response.data.doc ? response.data.doc : null;
				}
				catch (error) {
					console.log('error: ', error);
					if (error.response && error.response.status === 401) {
						//dispatch({type: SNACKBAR_ERROR, payload: {serverError: {code: null, message:'ACCESSO NON AUTORIZZATO'}}});
					}
					else if (error.response && error.response.status === 403) {
						//dispatch({type: SNACKBAR_ERROR, payload: {serverError: {code: null, message:'ACCESSO NON CONSENTITO'}}});
					}
					else if (error.response && error.response.status === 400) {
						//dispatch({type: SNACKBAR_ERROR, payload: {serverError: {path: error.response.data.details[0].path, message:error.response.data.details[0].path + ' ' + error.response.data.details[0].message, type:error.response.data.details[0].type}}});
					}
					else{
						//dispatch({type: SNACKBAR_ERROR, payload: {serverError: {path: error.response.data.details[0].path, message:error.response.data.details[0].path + ' ' + error.response.data.details[0].message, type:error.response.data.details[0].type}}});
					}
					return dispatch(actionRequestOff(EUserActionTypes.PUT_FAILURE, doc));
				}
			}
			else {
				batch(() => {
					dispatch(push('/'));
					dispatch(actionRequestOff(EUserActionTypes.PUT_FAILURE));
					dispatch(actAuth.logout());
				});
			}
			batch(() => {
				dispatch(actUsers.get(params));
				dispatch(actionRequestOff(EUserActionTypes.PUT_SUCCESS, doc));
			});
		}
	},

	get: (doc?: IUserDoc, id?: number) => {
		return async (dispatch: ThunkDispatch<IApplicationState, void, Action> ) => {   //, getState: Function
			dispatch(actionRequestOn(EUserActionTypes.GET_REQUEST));

			const getDoc: IUserDoc = !!doc ? doc : new UserModel();

			if (!!doc) {
				return dispatch(actionRequestOff(EUserActionTypes.GET_SUCCESS, getDoc));
			}
			if (!!id) {
				try {
					const params = '?$withRelated=["siteUserPhoto"]';
					const response = await axios.get<IUserResponse>(`${config.site.apiURL}/users/${id}${params}` );

					return dispatch(actionRequestOff(EUserActionTypes.GET_SUCCESS, response.data.doc));
				}
				catch (error) {
					console.log('error: ', error);
					if (error.response && error.response.status === 401) {
						//dispatch({type: SNACKBAR_ERROR, payload: {serverError: {code: null, message:'ACCESSO NON AUTORIZZATO'}}});
					}
					else if (error.response && error.response.status === 403) {
						//dispatch({type: SNACKBAR_ERROR, payload: {serverError: {code: null, message:'ACCESSO NON CONSENTITO'}}});
					}
					else if (error.response && error.response.status === 400) {
						//dispatch({type: SNACKBAR_ERROR, payload: {serverError: {path: error.response.data.details[0].path, message:error.response.data.details[0].path + ' ' + error.response.data.details[0].message, type:error.response.data.details[0].type}}});
					}
					else{
						//dispatch({type: SNACKBAR_ERROR, payload: {serverError: {path: error.response.data.details[0].path, message:error.response.data.details[0].path + ' ' + error.response.data.details[0].message, type:error.response.data.details[0].type}}});
					}
					return dispatch(actionRequestOff(EUserActionTypes.GET_FAILURE));
				}
			}

			return dispatch(actionRequestOff(EUserActionTypes.GET_FAILURE));
		}
	},

	delete: (doc: IUserDoc, attach?: IAttach) => {
		return async (dispatch: ThunkDispatch<IApplicationState, void, Action>, getState: () => IApplicationState) => {
			dispatch(actionRequestOn(EUserActionTypes.DELETE_REQUEST, doc));

			//console.log(attach.file);
			// CAPIRE SE DEVE ESSERE CANCELLATA ANCHE LA FOTO
			/*if (!!file && !!image){
				doc.siteUserPhoto = !!doc.siteUserPhoto ? doc.siteUserPhoto : new UserPhotoModel();
				doc.siteUserPhoto.originalName = file.name;
				doc.siteUserPhoto.mimeType = file.type;
				doc.siteUserPhoto.size = file.size;
				doc.siteUserPhoto.image = image;
			}*/

			const usersState = getState().usersState;
			const params = !!usersState && !!usersState.params ? usersState.params : {};
			const token: InitTokenReturn = await initToken();
			if (token.token) {
				try {
					// doc.siteId = config.site.id;

					axios.defaults.headers.common['Authorization'] = token.token;
					await axios.delete(`${config.site.apiURL}/users/${doc.id}`,{ data: { "$hardDelete": false }});
				}
				catch (error) {
					console.log('error: ', error);
					if (error.response && error.response.status === 401) {
						//dispatch({type: SNACKBAR_ERROR, payload: {serverError: {code: null, message:'ACCESSO NON AUTORIZZATO'}}});
					}
					else if (error.response && error.response.status === 403) {
						//dispatch({type: SNACKBAR_ERROR, payload: {serverError: {code: null, message:'ACCESSO NON CONSENTITO'}}});
					}
					else if (error.response && error.response.status === 400) {
						//dispatch({type: SNACKBAR_ERROR, payload: {serverError: {path: error.response.data.details[0].path, message:error.response.data.details[0].path + ' ' + error.response.data.details[0].message, type:error.response.data.details[0].type}}});
					}
					else{
						//dispatch({type: SNACKBAR_ERROR, payload: {serverError: {path: error.response.data.details[0].path, message:error.response.data.details[0].path + ' ' + error.response.data.details[0].message, type:error.response.data.details[0].type}}});
					}
					return dispatch(actionRequestOff(EUserActionTypes.DELETE_FAILURE, doc));
				}
			}
			else {
				batch(() => {
					dispatch(push('/'));
					dispatch(actionRequestOff(EUserActionTypes.DELETE_FAILURE));
					dispatch(actAuth.logout());
				});

			}
			batch(() => {
				dispatch(actUsers.get(params));
				dispatch(actionRequestOff(EUserActionTypes.DELETE_SUCCESS, doc));
			});
		}
	},

};

// Service for Fetching
const actionRequestOn = (type: EUserActionTypes, doc?: IUserDoc) => {
	return (dispatch: ThunkDispatch<IApplicationState, void, Action>) => {
		const action: IUserAction = {
			type: type,
			payload: {
				doc: !!doc ? doc : new UserModel(),
				isFetching: true,
			},
		};
		dispatch(action);
	}
};

const actionRequestOff = (type: EUserActionTypes, doc?: IUserDoc) => {
	return (dispatch: ThunkDispatch<IApplicationState, void, Action>) => {
		const action: IUserAction = {
			type: type,
			payload: {
				doc: !!doc ? doc : new UserModel(),
				isFetching: false,
			},
		};
		dispatch(action);
	}
};
