import {ERoles, ETypes, IMUtil, ITableName} from "./types";
import { isDate } from 'date-fns';
import {IChurchPhotoDoc} from "../store/church/photo";


export default class ChurchPhotoModel implements IChurchPhotoDoc {

  public id: 					  number | null;
  public fieldName:     string;
  public originalName:	string;
  public fileName:			string;
  public mimeType:			string;
  public destination:		string;
  public path:				  string;
  public size: 	        number;
  public churchId:				  number;
  public image:				  string;
  public createdAt:		  Date;
  public updatedAt:		  Date;
  public deletedAt:		  Date | null;

  constructor(doc?: IChurchPhotoDoc) {
    // Initial Value with input object (optional)
    this.id =           !!doc && doc.id ? doc.id : null;
    this.fieldName =		!!doc && doc.fieldName ? doc.fieldName : '';
    this.originalName =	!!doc && doc.originalName ? doc.originalName : '';
    this.fileName =			!!doc && doc.fileName ? doc.fileName : '';
    this.mimeType =			!!doc && doc.mimeType ? doc.mimeType : '';
    this.destination =	!!doc && doc.destination ? doc.destination : '';
    this.path =				  !!doc && doc.path ? doc.path : '';
    this.size =         !!doc && doc.size ? +doc.size : 0;
    this.churchId =				!!doc && doc.churchId ? +doc.churchId : 0;
    this.image =				!!doc && doc.image ? doc.image : '';
    this.createdAt =		!!doc && !!doc.createdAt ? isDate(doc.createdAt) ? doc.createdAt : new Date(doc.createdAt) : new Date();
    this.updatedAt =		!!doc && !!doc.updatedAt ? isDate(doc.updatedAt) ? doc.updatedAt : new Date(doc.updatedAt) : new Date();
    this.deletedAt =		!!doc && !!doc.deletedAt ? isDate(doc.deletedAt) ? doc.deletedAt : new Date(doc.deletedAt) : null;

  }

  // Object to DB
  public toDB() {
    const docForDB = Object.assign({}, this);
    if (!this.id)
      delete docForDB.id;

    if (!this.churchId)
      delete docForDB.churchId;

    delete docForDB.createdAt;
    delete docForDB.updatedAt;
    delete docForDB.deletedAt;
    return docForDB;
  }

  // Object to DB
  static toDB2(doc: IChurchPhotoDoc): IChurchPhotoDoc {
    const docForDB = Object.assign({}, doc);
    if (!doc.id)
      delete docForDB.id;

    if (!doc.churchId)
      delete docForDB.churchId;

    delete docForDB.createdAt;
    delete docForDB.updatedAt;
    delete docForDB.deletedAt;
    return docForDB;
  }


  // STATIC methods
  static util(): IMUtil {
    return {
      id: {
        name:     'id',
        forSearch: {
          forRoles:   [ERoles.SUPER_ADMIN],
          relation:   {},
        },
        type:     ETypes.INTEGER,
        forTable: {
          visibleFor: [],
          isSortable: false,
        },
      },

      name: {
        name:     'name',
        forSearch: {
          forRoles: [ERoles.SUPER_ADMIN, ERoles.ADMIN, ERoles.MANAGER, ERoles.EDITOR, ERoles.USER],
          relation: {},
        },
        type:     ETypes.STRING,
        forTable: {
          visibleFor: [ERoles.SUPER_ADMIN, ERoles.ADMIN, ERoles.MANAGER, ERoles.EDITOR, ERoles.USER],
          isSortable: true,
        },
      },

      title: {
        name:     'title',
        forSearch: {
          forRoles: [ERoles.SUPER_ADMIN, ERoles.ADMIN, ERoles.MANAGER, ERoles.EDITOR, ERoles.USER],
          relation: {},
        },
        type:     ETypes.STRING,
        forTable: {
          visibleFor: [ERoles.SUPER_ADMIN, ERoles.ADMIN, ERoles.MANAGER, ERoles.EDITOR, ERoles.USER],
          isSortable: true,
        },
      },

      place: {
        name:     'place',
        forSearch: {
          forRoles: [ERoles.SUPER_ADMIN, ERoles.ADMIN, ERoles.MANAGER, ERoles.EDITOR, ERoles.USER],
          relation: {},
        },
        type:     ETypes.STRING,
        forTable: {
          visibleFor: [ERoles.SUPER_ADMIN, ERoles.ADMIN, ERoles.MANAGER, ERoles.EDITOR, ERoles.USER],
          isSortable: true,
        },
      },

      email: {
        name:     'email',
        forSearch: {
          forRoles: [ERoles.SUPER_ADMIN, ERoles.ADMIN, ERoles.MANAGER, ERoles.EDITOR, ERoles.USER],
          relation: {},
        },
        type:     ETypes.STRING,
        forTable: {
          visibleFor: [ERoles.SUPER_ADMIN, ERoles.ADMIN, ERoles.MANAGER, ERoles.EDITOR, ERoles.USER],
          isSortable: true,
        },
      },

      telephone: {
        name:     'telephone',
        forSearch: {
          forRoles: [ERoles.SUPER_ADMIN, ERoles.ADMIN, ERoles.MANAGER, ERoles.EDITOR, ERoles.USER],
          relation: {},
        },
        type:     ETypes.STRING,
        forTable: {
          visibleFor: [ERoles.SUPER_ADMIN, ERoles.ADMIN, ERoles.MANAGER, ERoles.EDITOR, ERoles.USER],
          isSortable: true,
        },
      },

      mobile: {
        name:     'mobile',
        forSearch: {
          forRoles: [ERoles.SUPER_ADMIN],
          relation: {},
        },
        type:     ETypes.STRING,
        forTable: {
          visibleFor: [],
          isSortable: false,
        },
      },

      description: {
        name:     'description',
        forSearch: {
          forRoles: [ERoles.SUPER_ADMIN, ERoles.ADMIN, ERoles.MANAGER, ERoles.EDITOR, ERoles.USER],
          relation: {},
        },
        type:     ETypes.STRING,
        forTable: {
          visibleFor: [],
          isSortable: false,
        },
      },

      text: {
        name:      'text',
        forSearch: {
          forRoles: [ERoles.SUPER_ADMIN, ERoles.ADMIN, ERoles.MANAGER, ERoles.EDITOR, ERoles.USER],
          relation: {},
        },
        type:     ETypes.STRING,
        forTable: {
          visibleFor: [],
          isSortable: false,
        },
      },

      section: {
        name:     'section',
        forSearch: {
          forRoles: [ERoles.SUPER_ADMIN],
          relation: {},
        },
        type:     ETypes.INTEGER,
        forTable: {
          visibleFor: [ERoles.SUPER_ADMIN, ERoles.ADMIN, ERoles.MANAGER, ERoles.EDITOR, ERoles.USER],
          isSortable: true,
        },
      },

      row: {
        name:     'row',
        forSearch: {
          forRoles: [ERoles.SUPER_ADMIN],
          relation: {},
        },
        type:     ETypes.INTEGER,
        forTable: {
          visibleFor: [ERoles.SUPER_ADMIN, ERoles.ADMIN, ERoles.MANAGER, ERoles.EDITOR, ERoles.USER],
          isSortable: true,
        },
      },

      column: {
        name:     'column',
        forSearch: {
          forRoles: [ERoles.SUPER_ADMIN],
          relation: {},
        },
        type:     ETypes.INTEGER,
        forTable: {
          visibleFor: [ERoles.SUPER_ADMIN, ERoles.ADMIN, ERoles.MANAGER, ERoles.EDITOR, ERoles.USER],
          isSortable: true,
        },
      },

      siteId: {
        name:      'site',
        forSearch: {
          forRoles: [ERoles.SUPER_ADMIN, ERoles.ADMIN, ERoles.MANAGER, ERoles.EDITOR, ERoles.USER],
          relation: {},
        },
        type:     ETypes.STRING,
        forTable: {
          visibleFor: [ERoles.SUPER_ADMIN],
          isSortable: false,
        },
      },

      createdAt: {
        name:      'created at',
        forSearch: {
          forRoles: [ERoles.SUPER_ADMIN, ERoles.ADMIN, ERoles.MANAGER, ERoles.EDITOR, ERoles.USER],
          relation: {},
        },
        type:     ETypes.DATE,
        forTable: {
          visibleFor: [],
          isSortable: true,
        },
      },

      updatedAt: {
        name:      'updated at',
        forSearch: {
          forRoles: [ERoles.SUPER_ADMIN, ERoles.ADMIN],
          relation: {},
        },
        type:     ETypes.DATE,
        forTable: {
          visibleFor: [],
          isSortable: false,
        },
      },

      deletedAt: {
        name:      'deleted at',
        forSearch: {
          forRoles: [ERoles.SUPER_ADMIN, ERoles.ADMIN],
          relation: {},
        },
        type:     ETypes.DATE,
        forTable: {
          visibleFor: [],
          isSortable: false,
        },
      },

    };
  }

  static tableName(): ITableName {
    return {
      singular: 'siteChurchPhoto',
      plural:   'sitePeople',
    }
  }

}
