import {EChurchesActionTypes, IChurchesAction, IChurchesState} from '.';
import {Reducer} from 'redux';



// Reducer initial State with ALL objects
export const initialState: IChurchesState = {
	docs: [],
	isFetching: false,
	items: null,
	pages: null,
	params: {},
};

const reducer: Reducer<IChurchesState> = (state: IChurchesState = initialState, action: IChurchesAction) => {
	switch (action.type) {
		case EChurchesActionTypes.GET_REQUEST:
			return {
				...state,
				...action.payload,
			};
		case EChurchesActionTypes.GET_SUCCESS:
			return {
				...state,
					...action.payload,
			};
		case EChurchesActionTypes.GET_FAILURE:
			return {
				...state,
				...action.payload,
			};

		default:
			return state;
	}
};

export { reducer as ChurchesReducer }
