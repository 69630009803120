import * as React from 'react';
import {connect} from 'react-redux';
import {Action} from 'redux';
import {ThunkDispatch} from "redux-thunk";
import {Grommet, ResponsiveContext} from 'grommet';
import { BrowserRouter } from 'react-router-dom';
import { ConnectedRouter } from 'connected-react-router'
import Layout from './containers/layout'
import {IApplicationState} from "./store";
import {actAuth, IAuthState} from "./store/auth/auth";
import AppRouter from "./routes/AppRouter";
import { Spinning } from 'grommet-controls';

const theme = {
  global: {
    // colors: {
    //   brand: '#228BE6',
    // },
    font: {
      family: 'Roboto',
      size: '14px',
      height: '16px',
    },
  },
};

interface IProps {
  history: History,
}

interface PropsFromState {
  authState: IAuthState,
}

interface PropsFromDispatch {
  authInit: () => void,
}


interface IState {
  showSidebar: boolean,
}

type Props = IProps & PropsFromState & PropsFromDispatch


class App extends React.Component<Props, IState> {
  constructor(props: Props)  {
    super(props);

    this.state = {
      showSidebar: false,
    };

    props.authInit();
  }


  render() {
    const { authState, history } = this.props;

    return (

      <Grommet theme={theme} full>
        {authState.isInitializing ? (
          <BrowserRouter>
            <ResponsiveContext.Consumer>
              {size => (
                <Layout size={size}>
                  <Spinning kind='three-bounce' color='brand' size='xlarge'/>
                </Layout>
              )}
            </ResponsiveContext.Consumer>
          </BrowserRouter>
        ) : (
          <ConnectedRouter history={history}>
            <ResponsiveContext.Consumer>
              {size => (
                <Layout size={size}>
                  <AppRouter/>
                </Layout>
              )}
            </ResponsiveContext.Consumer>
          </ConnectedRouter>
        )
        }

      </Grommet>

    );
  }
}

function mapStateToProps(state: IApplicationState) {
  return {
    authState: state.authState,
  };
}


// Mapping our action dispatcher to props is especially useful when creating container components.
const mapDispatchToProps = (dispatch: ThunkDispatch<IApplicationState, void, Action>): PropsFromDispatch => ({
  // Routines Actions
  authInit: () => dispatch(actAuth.init()),
});



export default connect(mapStateToProps, mapDispatchToProps)(App);
