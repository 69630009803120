import * as React from "react";
import {InjectedFormikProps, withFormik} from "formik";
import {IRoleDoc} from "../store/auth/role";
import RoleModel from "../models/roleModel";
import {validate} from "../validations/roleValidation";
import {EActionType} from "../helpers/interfaces/forQueryParams";
import {Box, Button, Form, FormField, Heading, Layer, Paragraph} from "grommet";

interface Props {
  selectRow: IRoleDoc | null,
  actionType: EActionType | null,
  submitModal: (actionType: EActionType, row: IRoleDoc) => void,
  closeModal: () => void,
}

const RoleForm: React.FunctionComponent<InjectedFormikProps<Props, IRoleDoc>> = props => {
  const {handleSubmit, closeModal, values, actionType, setFieldValue, handleBlur, errors, touched, isSubmitting} = props;

  return (
      <Layer
          onEsc={closeModal}
          onClickOutside={closeModal}
      >
        <Box width={'large'} pad={"large"} >
          <Heading color={'neutral-1'} margin="none" level={3}>{`${actionType} Role`}</Heading>
          <br/>
          {(actionType === EActionType.CREATE || actionType === EActionType.UPDATE) &&
          <Form>
            <FormField
                name="name"
                label="Name"
                value={values.name}
                error={touched.name && !!errors.name ? errors.name : ''}
                onBlur={handleBlur}
                onChange={event => setFieldValue('name', event.target.value)}/>
            <FormField
                name="description"
                label="Description"
                value={!!values.description ? values.description : '' }
                error={touched.description && !!errors.description ? errors.description : ''}
                onBlur={handleBlur}
                onChange={event => setFieldValue('description', event.target.value)}/>
            <FormField
                name="rate"
                label="Rate"
                type="number"
                value={values.rate}
                error={touched.rate && !!errors.rate ? errors.rate : ''}
                onBlur={handleBlur}
                onChange={event => setFieldValue('rate', event.target.value)}/>
            <Box justify='end' direction={"row"} margin={{top: 'large'}} >
              <Button color='light-5' primary alignSelf={"end"} label="Cancel" onClick={closeModal}/>
              <Button disabled={isSubmitting} type="submit" primary alignSelf={"end"} margin={{left: 'small'}}
                      label="Save" onClick={()=>handleSubmit()}/>
            </Box>
          </Form>
          }
          {actionType === EActionType.DELETE &&
          <Form>
            <Paragraph margin="medium">
              Are you sure to delete role {`${values.name}`} ?
            </Paragraph>
            <Box justify='end' direction={"row"} margin={{top: 'large'}} >
              <Button color='light-5' primary alignSelf={"end"} label="Cancel" onClick={closeModal}/>
              <Button disabled={isSubmitting} type="submit" color={"red"} alignSelf={"end"} margin={{left: 'small'}}
                      label="Delete" onClick={()=>handleSubmit()}/>
            </Box>
          </Form>
          }

        </Box>
      </Layer>
  );
};


const roleForm = withFormik<Props, IRoleDoc>({
  enableReinitialize: true,
  validateOnChange: false,

  mapPropsToValues: (props): IRoleDoc => {
    console.log('FKProps: ', props.selectRow);
    if (!!props.selectRow){
      return {
        ...props.selectRow,
      };
    }
    else{
      return new RoleModel();
    }

  },

  validate: (values: IRoleDoc) => {
    return validate(values, false);
  },

  handleSubmit: (values: IRoleDoc, { props, setSubmitting, setErrors }) => {
    console.log('Submit: ', values);
    setSubmitting(true);
    let errors = validate(values, true);

    if (Object.keys(errors).length > 0) {
      setErrors(errors);
      setSubmitting(false);
    }
    else {
      const doc = new RoleModel(values);

      if (!!props.actionType)
        props.submitModal(props.actionType, doc);

      setSubmitting(false);
    }
  }
})(RoleForm);

export default roleForm;
