import {ISessionDoc} from "../store/auth/session";
import {ERoles, ETypes, IMUtil, ITableName} from "./types";
import { isDate } from 'date-fns';


export default class SessionModel implements ISessionDoc {

  public id?: 						string | null;
  public key:    			    string;
  public userAgent:     	string;
  public userId:		      string | null;

  // In relations
  public _delete?:			boolean;
  public createdAt:		  Date;
  public updatedAt:		  Date;
  public deletedAt:		  Date | null;

  constructor(doc?: ISessionDoc) {
    // Initial Value with input object (optional)
    this.id =             !!doc && doc.id ? doc.id : null;
    this.key =            !!doc && doc.key ? doc.key : '';
    this.userAgent =      !!doc && doc.userAgent ? doc.userAgent : '';
    this.userId =         !!doc && doc.userId ? doc.userId : null;

    this.createdAt =		  !!doc && !!doc.createdAt ? isDate(doc.createdAt) ? doc.createdAt : new Date(doc.createdAt) : new Date();
    this.updatedAt =		  !!doc && !!doc.updatedAt ? isDate(doc.updatedAt) ? doc.updatedAt : new Date(doc.updatedAt) : new Date();
    this.deletedAt =	  	!!doc && !!doc.deletedAt ? isDate(doc.deletedAt) ? doc.deletedAt : new Date(doc.deletedAt) : null;
  }

  // Object to DB
  public toDB() {
    const docForDB = Object.assign({}, this);
    if (!this.id) {
      delete docForDB.id
    }

    delete docForDB.createdAt;
    delete docForDB.updatedAt;
    delete docForDB.deletedAt;

    // delete docForDB.attach;

    return docForDB;
  }

  // Object to DB
  static toDB2(doc: ISessionDoc): ISessionDoc {
    const docForDB = Object.assign({}, doc);

    if (!doc.id) {
      delete docForDB.id
    }

    delete docForDB.createdAt;
    delete docForDB.updatedAt;
    delete docForDB.deletedAt;

    return docForDB;
  }


  // STATIC methods
  static util(): IMUtil {
    return {
      id: {
        name:     'id',
        forSearch: {
          forRoles:   [ERoles.SUPER_ADMIN],
          relation:   {},
        },
        type:     ETypes.STRING,
        forTable: {
          visibleFor: [],
          isSortable: false,
        },
      },

      name: {
        name:     'key',
        forSearch: {
          forRoles: [ERoles.SUPER_ADMIN, ERoles.ADMIN, ERoles.MANAGER, ERoles.EDITOR, ERoles.USER],
          relation: {},
        },
        type:     ETypes.STRING,
        forTable: {
          visibleFor: [ERoles.SUPER_ADMIN, ERoles.ADMIN, ERoles.MANAGER, ERoles.EDITOR, ERoles.USER],
          isSortable: true,
        },
      },

      url: {
        name:     'userAgent',
        forSearch: {
          forRoles: [ERoles.SUPER_ADMIN, ERoles.ADMIN, ERoles.MANAGER, ERoles.EDITOR, ERoles.USER],
          relation: {},
        },
        type:     ETypes.STRING,
        forTable: {
          visibleFor: [ERoles.SUPER_ADMIN, ERoles.ADMIN, ERoles.MANAGER, ERoles.EDITOR, ERoles.USER],
          isSortable: true,
        },
      },

      description: {
        name:     'userId',
        forSearch: {
          forRoles: [ERoles.SUPER_ADMIN, ERoles.ADMIN, ERoles.MANAGER, ERoles.EDITOR, ERoles.USER],
          relation: {},
        },
        type:     ETypes.STRING,
        forTable: {
          visibleFor: [ERoles.SUPER_ADMIN, ERoles.ADMIN, ERoles.MANAGER, ERoles.EDITOR, ERoles.USER],
          isSortable: true,
        },
      },
    };
  }

  static tableName(): ITableName {
    return {
      singular: 'authSession',
      plural:   'authSessions',
    }
  }

}
