import {IUserDoc} from "../store/auth/user";
import {ERoles, ETypes, IMUtil, ITableName} from "./types";
import { isDate } from 'date-fns';
import {IRoleSiteUserDoc} from "../store/auth/roleSiteUser";
import RoleSiteUserModel from "./roleSiteUserModel";


export default class UserModel implements IUserDoc {

  public id?: 						number | null;
  public email:					  string;
  public firstName:			  string;
  public lastName:				string;
  public password?:				string;
  public isActive:				boolean;
  public currentLoginAt:	Date | null;
  public lastLoginAt:		  Date | null;
  public currentLoginIP:	string | null;
  public lastLoginIP:		  string | null;
  public fullName:				string;
  // In relations
  public _delete?:			boolean;
  public createdAt:		  Date;
  public updatedAt:		  Date;
  public deletedAt:		  Date | null;

  // Relation
  public userRRU: IRoleSiteUserDoc[];
  // 4 Attach ONLY 1
  // public attach:        IAttach;

  constructor(doc?: IUserDoc) {
    // Initial Value with input object (optional)
    this.id =             !!doc && doc.id ? doc.id : null;
    this.email =					!!doc && doc.email ? doc.email : '';
    this.firstName =      !!doc && !!doc.firstName ? doc.firstName : '';
    this.lastName =       !!doc && !!doc.lastName ? doc.lastName : '';
    this.password =       !!doc && !!doc.password ? doc.password : '';
    this.isActive =				!!doc && doc.isActive ? doc.isActive : false;
    this.currentLoginAt =	!!doc && !!doc.currentLoginAt ? isDate(doc.currentLoginAt) ? doc.currentLoginAt : new Date(doc.currentLoginAt) : null;
    this.lastLoginAt =	  !!doc && !!doc.lastLoginAt ? isDate(doc.lastLoginAt) ? doc.lastLoginAt : new Date(doc.lastLoginAt) : null;
    this.currentLoginIP = !!doc && doc.currentLoginIP ? doc.currentLoginIP : null;
    this.lastLoginIP =    !!doc && doc.lastLoginIP ? doc.lastLoginIP : null;
    this.fullName =       !!doc && !!doc.fullName ? doc.fullName : '';
    this.createdAt =		  !!doc && !!doc.createdAt ? isDate(doc.createdAt) ? doc.createdAt : new Date(doc.createdAt) : new Date();
    this.updatedAt =		  !!doc && !!doc.updatedAt ? isDate(doc.updatedAt) ? doc.updatedAt : new Date(doc.updatedAt) : new Date();
    this.deletedAt =	  	!!doc && !!doc.deletedAt ? isDate(doc.deletedAt) ? doc.deletedAt : new Date(doc.deletedAt) : null;

    // Relations
    this.userRRU =        !!doc && !!doc.userRRU && !!doc.userRRU.length ?
      doc.userRRU.map((rsu: IRoleSiteUserDoc) => {return new RoleSiteUserModel(rsu)}) :
      [];
    // 4 Attach
    // this.attach =       !!doc && doc.attach ? AttachModel.toDB2(doc.attach) : AttachModel.toDB2(new AttachModel());
    // this.attach = {
    //   file:   !!doc && doc.attach && doc.attach.file ? doc.attach.file : null,
    //   attach: !!doc && doc.attach && doc.attach.attach ? doc.attach.attach : null,
    // }
  }

  // Object to DB
  public toDB() {
    const docForDB = Object.assign({}, this);
    if (!this.id) {
      delete docForDB.id
    }
    // if (!!this.siteUserPhoto) {
    //   docForDB.siteUserPhoto = UserPhotoModel.toDB2(this.siteUserPhoto);
    // }
    // else {
    //   delete docForDB.siteUserPhoto
    // }
    delete docForDB.createdAt;
    delete docForDB.updatedAt;
    delete docForDB.deletedAt;

    // delete docForDB.attach;

    return docForDB;
  }

  // Object to DB
  static toDB2(doc: IUserDoc): IUserDoc {
    const docForDB = Object.assign({}, doc);
    if (!doc.id) {
      delete docForDB.id
    }
    if (!doc.password) {
      delete docForDB.password
    }
    if (!!doc.userRRU.length) {
      docForDB.userRRU = doc.userRRU.map((rru: IRoleSiteUserDoc): IRoleSiteUserDoc => {
        return RoleSiteUserModel.toDB2(rru);
      })
    }
    // if (!!doc.siteUserPhoto) {
    //   docForDB.siteUserPhoto = UserPhotoModel.toDB2(doc.siteUserPhoto);
    // }
    // else {
    //   delete docForDB.siteUserPhoto
    // }

    // Not writable fields
    delete docForDB.currentLoginAt;
    delete docForDB.lastLoginAt;
    delete docForDB.currentLoginIP;
    delete docForDB.lastLoginIP;
    delete docForDB.fullName;

    delete docForDB.createdAt;
    delete docForDB.updatedAt;
    delete docForDB.deletedAt;

    // delete docForDB.attach;

    return docForDB;
  }


  // STATIC methods
  static util(): IMUtil {
    return {
      id: {
        name:     'id',
        forSearch: {
          forRoles:   [ERoles.SUPER_ADMIN],
          relation:   {},
        },
        type:     ETypes.INTEGER,
        forTable: {
          visibleFor: [],
          isSortable: false,
        },
      },

      email: {
        name:     'email',
        forSearch: {
          forRoles: [ERoles.SUPER_ADMIN, ERoles.ADMIN, ERoles.MANAGER, ERoles.EDITOR, ERoles.USER],
          relation: {},
        },
        type:     ETypes.STRING,
        forTable: {
          visibleFor: [ERoles.SUPER_ADMIN, ERoles.ADMIN, ERoles.MANAGER, ERoles.EDITOR, ERoles.USER],
          isSortable: true,
        },
      },

      firstName: {
        name:     'first name',
        forSearch: {
          forRoles: [ERoles.SUPER_ADMIN, ERoles.ADMIN, ERoles.MANAGER, ERoles.EDITOR, ERoles.USER],
          relation: {},
        },
        type:     ETypes.STRING,
        forTable: {
          visibleFor: [ERoles.SUPER_ADMIN, ERoles.ADMIN, ERoles.MANAGER, ERoles.EDITOR, ERoles.USER],
          isSortable: true,
        },
      },

      lastName: {
        name:     'last name',
        forSearch: {
          forRoles: [ERoles.SUPER_ADMIN, ERoles.ADMIN, ERoles.MANAGER, ERoles.EDITOR, ERoles.USER],
          relation: {},
        },
        type:     ETypes.STRING,
        forTable: {
          visibleFor: [ERoles.SUPER_ADMIN, ERoles.ADMIN, ERoles.MANAGER, ERoles.EDITOR, ERoles.USER],
          isSortable: true,
        },
      },

      isActive: {
        name:      'is active',
        forSearch: {
          forRoles: [ERoles.SUPER_ADMIN, ERoles.ADMIN, ERoles.MANAGER, ERoles.EDITOR, ERoles.USER],
          relation: {},
        },
        type:     ETypes.BOOLEAN,
        forTable: {
          visibleFor: [ERoles.SUPER_ADMIN, ERoles.ADMIN, ERoles.MANAGER, ERoles.EDITOR, ERoles.USER],
          isSortable: true,
        },
      },

      currentLoginAt: {
        name:      'current login at',
        forSearch: {
          forRoles: [ERoles.SUPER_ADMIN, ERoles.ADMIN, ERoles.MANAGER, ERoles.EDITOR, ERoles.USER],
          relation: {},
        },
        type:     ETypes.DATE,
        forTable: {
          visibleFor: [ERoles.SUPER_ADMIN, ERoles.ADMIN, ERoles.MANAGER, ERoles.EDITOR, ERoles.USER],
          isSortable: true,
        },
      },

      lastLoginAt: {
        name:      'last login at',
        forSearch: {
          forRoles: [ERoles.SUPER_ADMIN, ERoles.ADMIN, ERoles.MANAGER, ERoles.EDITOR, ERoles.USER],
          relation: {},
        },
        type:     ETypes.DATE,
        forTable: {
          visibleFor: [ERoles.SUPER_ADMIN, ERoles.ADMIN, ERoles.MANAGER, ERoles.EDITOR, ERoles.USER],
          isSortable: true,
        },
      },

      currentLoginIP: {
        name:      'current login IP',
        forSearch: {
          forRoles: [ERoles.SUPER_ADMIN, ERoles.ADMIN, ERoles.MANAGER, ERoles.EDITOR, ERoles.USER],
          relation: {},
        },
        type:     ETypes.STRING,
        forTable: {
          visibleFor: [ERoles.SUPER_ADMIN, ERoles.ADMIN, ERoles.MANAGER, ERoles.EDITOR, ERoles.USER],
          isSortable: true,
        },
      },

      lastLoginIP: {
        name:      'last login IP',
        forSearch: {
          forRoles: [ERoles.SUPER_ADMIN, ERoles.ADMIN, ERoles.MANAGER, ERoles.EDITOR, ERoles.USER],
          relation: {},
        },
        type:     ETypes.STRING,
        forTable: {
          visibleFor: [ERoles.SUPER_ADMIN, ERoles.ADMIN, ERoles.MANAGER, ERoles.EDITOR, ERoles.USER],
          isSortable: true,
        },
      },

      fullName: {
        name:      'full name',
        forSearch: {
          forRoles: [ERoles.SUPER_ADMIN, ERoles.ADMIN, ERoles.MANAGER, ERoles.EDITOR, ERoles.USER],
          relation: {},
        },
        type:     ETypes.STRING,
        forTable: {
          visibleFor: [ERoles.SUPER_ADMIN, ERoles.ADMIN, ERoles.MANAGER, ERoles.EDITOR, ERoles.USER],
          isSortable: true,
        },
      },

      createdAt: {
        name:      'created at',
        forSearch: {
          forRoles: [ERoles.SUPER_ADMIN, ERoles.ADMIN, ERoles.MANAGER, ERoles.EDITOR, ERoles.USER],
          relation: {},
        },
        type:     ETypes.DATE,
        forTable: {
          visibleFor: [ERoles.SUPER_ADMIN, ERoles.ADMIN, ERoles.MANAGER, ERoles.EDITOR, ERoles.USER],
          isSortable: true,
        },
      },

      updatedAt: {
        name:      'updated at',
        forSearch: {
          forRoles: [ERoles.SUPER_ADMIN, ERoles.ADMIN],
          relation: {},
        },
        type:     ETypes.DATE,
        forTable: {
          visibleFor: [],
          isSortable: false,
        },
      },

      deletedAt: {
        name:      'deleted at',
        forSearch: {
          forRoles: [ERoles.SUPER_ADMIN, ERoles.ADMIN],
          relation: {},
        },
        type:     ETypes.DATE,
        forTable: {
          visibleFor: [],
          isSortable: false,
        },
      },

    };
  }

  static tableName(): ITableName {
    return {
      singular: 'authUser',
      plural:   'authUsers',
    }
  }

}
