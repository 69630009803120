import {Box} from "grommet";
import * as React from "react";
import {actAuth, IAuthState, ILoginParams} from "../store/auth/auth";
import {connect} from 'react-redux'
import {ThunkDispatch} from "redux-thunk";
import {IApplicationState} from "../store";
import {Action} from "redux";
import ChurchForm from "./churchForm"
import {Add, Edit, Trash} from "grommet-icons";
import {actChurches, IChurchesState} from "../store/church/churches";
import {
  EActionType,
  IParamsTypes
} from "../helpers/interfaces/forQueryParams";
import {Grid, PagingPanel, Table, TableFilterRow, TableHeaderRow, Toolbar,} from 'dx-react-grid-grommet';
import {
  FilteringState,
  IntegratedFiltering,
  IntegratedPaging,
  IntegratedSorting,
  PagingState,
  SortingState,
} from '@devexpress/dx-react-grid';
import {actChurch, IChurchDoc} from "../store/church/church";
import {IAttach} from "../store/types";
import {ISiteDoc} from "../store/auth/site";


interface IState {
  openModal:boolean
  selectRow: IChurchDoc | null
  actionType: EActionType | null
}

// Props passed from mapStateToProps TS
interface PropsFromState {
  authState:  IAuthState,
  churchesState: IChurchesState,
}

interface PropsFromDispatch {
  actAuthLogin: (param: ILoginParams) => void,
  actChurchesGetAll: (param: IParamsTypes) => void,
  actChurchCreate: (doc: IChurchDoc, attach: IAttach) => void,
  actChurchUpdate: (doc: IChurchDoc, attach: IAttach) => void,
  actChurchDelete: (doc: IChurchDoc, attach: IAttach) => void,
}

type Props = PropsFromState & PropsFromDispatch & IState;


class ChurchesTable extends React.Component<Props, IState> {


  constructor(props){
    super(props);
    this.state={
      openModal: false,
      selectRow: null,
      actionType: null
    }
  }

  handleAction = (row:IChurchDoc | null, action:EActionType ) =>{
    this.setState({ selectRow:row , openModal: true, actionType: action});
  };
  
  handleSubmit =async (actionType:EActionType, doc:IChurchDoc)=>{
    if (!!actionType){
      if (actionType === EActionType.CREATE){
        await this.props.actChurchCreate(doc,doc.attach);
        this.setState({openModal:false, selectRow:null});
      }
      else if (actionType === EActionType.UPDATE){
        await this.props.actChurchUpdate(doc,doc.attach);
        this.setState({openModal:false, selectRow:null});
      }
      else if (actionType === EActionType.DELETE){
        await this.props.actChurchDelete(doc,doc.attach);
        this.setState({openModal:false, selectRow:null});
      }
    }
  };

  render () {

    const {openModal, selectRow, actionType, } = this.state;
    const {churchesState, authState} = this.props;
    const currentSite: ISiteDoc | null =  !!authState && !!authState.selectedSite ? authState.selectedSite : null ;

    const TableHeaderContent = ({column, children, ...rest}) => (
        <TableHeaderRow.Content
            column={column}
            {...rest}
        >
          {column.name === 'command' ? '' : children}
        </TableHeaderRow.Content>
    );

    const CellCreate = (props) => {
      if (props.column.name === 'command') {
        return <TableFilterRow.Cell {...props}>
          <Box flex direction='row' justify={'center'} pad={{"left":"20px"}}>
            <Add size={'medium'} style={{cursor: 'pointer'}} onClick={() => this.handleAction(null,EActionType.CREATE)}/>
          </Box>
        </TableFilterRow.Cell>
      }
      return (<TableFilterRow.Cell {...props} />);
    };

    const Cell = (props) => {
      return <Table.Cell
          {...props}
          style={{
            paddingTop:'8px',
            paddingBottom:'8px',
            paddingLeft: '19px',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }}
      />;
    };

    const columns=[
      { name: 'id', title: 'Id',},
      { name: 'map', title: 'Map' },
      { name: 'number', title: 'Number' },
      { name: 'name', title: 'Name' },
      { name: 'originalName', title: 'Original Name' },
      { name: 'place', title: 'Place' },
      { name: 'command',
        getCellValue: row => {
          return(
            <Box flex direction='row' justify={'center'} gap={'small'}>
              <Edit size={'medium'} style={{cursor: 'pointer'}} onClick={() => this.handleAction(row,EActionType.UPDATE)}/>
              <Trash size={'medium'} style={{cursor: 'pointer'}} onClick={() => this.handleAction(row,EActionType.DELETE)}/>
            </Box>
          )
        },
      },

    ];

    return (

      <Box>

        {openModal &&
          <ChurchForm
            closeModal={()=>this.setState({openModal:false})}
            selectRow={selectRow}
            actionType={actionType}
            submitModal={(actionType: EActionType,doc: IChurchDoc)=>this.handleSubmit(actionType, doc)}
            currentSite={currentSite}
          />
        }

        <Grid
          rows={churchesState.docs}
          columns={columns}
        >

          <FilteringState/>
          <SortingState/>

          <PagingState
            defaultCurrentPage={1}
            defaultPageSize={10}
          />

          <IntegratedFiltering />
          <IntegratedSorting />
          <IntegratedPaging />

          <Table cellComponent={Cell}/>

          <TableHeaderRow
            showSortingControls
            contentComponent={TableHeaderContent}
          />

          <TableFilterRow
            showFilterSelector={true}
            cellComponent={CellCreate}/>

          <Toolbar />

          <PagingPanel
            pageSizes={[3, 5, 10, 20, 0]}
          />

        </Grid>
      </Box>
    )
  }


}


const mapStateToProps = (state: IApplicationState): PropsFromState => ({
  authState:    state.authState,
  churchesState:  state.churchesState,
});

// Mapping our action dispatcher to props is especially useful when creating container components.
const mapDispatchToProps = (dispatch: ThunkDispatch<IApplicationState, void, Action>): PropsFromDispatch => ({
  //AUTH Actions
  actAuthLogin: (param: ILoginParams) => dispatch(actAuth.login(param)),
  actChurchesGetAll: (param: IParamsTypes) => dispatch(actChurches.get(param)),
  actChurchCreate: (doc: IChurchDoc, attach: IAttach) => dispatch(actChurch.create(doc,attach)),
  actChurchUpdate: (doc: IChurchDoc, attach: IAttach) => dispatch(actChurch.update(doc,attach)),
  actChurchDelete: (doc: IChurchDoc, attach: IAttach) => dispatch(actChurch.delete(doc,attach)),
});

export default connect(mapStateToProps,mapDispatchToProps)(ChurchesTable);
