import {Box} from "grommet";
import * as React from "react";
import {actAuth, IAuthState, ILoginParams} from "../store/auth/auth";
import {connect} from 'react-redux'
import {ThunkDispatch} from "redux-thunk";
import {IApplicationState} from "../store";
import {Action} from "redux";
import {Add, Edit, Trash} from "grommet-icons";
import UserForm from "./userForm"
import {actUser, IUserDoc} from "../store/auth/user";
import {actUsers, IUsersState} from "../store/auth/users";
import {EActionType, EExtraQueryType, IExtraQueryParam, IParamsTypes} from "../helpers/interfaces/forQueryParams";
import {Grid, PagingPanel, Table, TableFilterRow, TableHeaderRow, Toolbar,} from 'dx-react-grid-grommet';
import {
  FilteringState,
  IntegratedFiltering,
  IntegratedPaging,
  IntegratedSorting,
  PagingState,
  SortingState,
} from '@devexpress/dx-react-grid';
import {actRoles, IRolesState} from "../store/auth/roles";
import {actSites, ISitesState} from "../store/auth/sites";
import {initToken} from "../helpers/token/auth_helper";


interface IState {
  openModal:boolean
  selectRow: IUserDoc | null
  actionType: EActionType | null
}

// Props passed from mapStateToProps TS
interface PropsFromState {
  authState:  IAuthState,
  usersState: IUsersState,
  rolesState: IRolesState,
  sitesState: ISitesState,
}

interface PropsFromDispatch {
  actAuthLogin: (param: ILoginParams) => void,
  actUsersGetAll: (param: IParamsTypes) => void,
  actUserCreate: (doc: IUserDoc) => void,
  actUserUpdate: (doc: IUserDoc) => void,
  actUserDelete: (doc: IUserDoc) => void,
  actRolesGetAll: (param: IParamsTypes) => void,
  actSitesGetAll: (param: IParamsTypes) => void,
  actAuthInit: () => void,
}

type Props = PropsFromState & PropsFromDispatch & IState;


class UsersTable extends React.Component<Props, IState> {


  constructor(props){
    super(props);
    this.state={
      openModal: false,
      selectRow: null,
      actionType: null
    }
  }

  componentDidMount = async () => {
    console.log('Users: ', this.props.usersState);
    const userDocs: IUserDoc[] = this.props.usersState.docs;

    if (!userDocs.length) {
      const related: IExtraQueryParam[] = [{kind: EExtraQueryType.WITH_RELATED, value: 'userRRU'}];
      const params: IParamsTypes = {extraQuery: related};

      await initToken();
      this.props.actUsersGetAll(params);
      this.props.actRolesGetAll({});
      this.props.actSitesGetAll({});
    }
  };

  handleAction = (row: IUserDoc | null, action: EActionType ): void => {
    this.setState({ selectRow:row , openModal: true, actionType: action});
  };

  handleSubmit = (actionType: EActionType, doc: IUserDoc) => {
    if (!!actionType){
      if (actionType === EActionType.CREATE){
        this.props.actUserCreate(doc);
      }
      else if (actionType === EActionType.UPDATE){
        this.props.actUserUpdate(doc);
      }
      else if (actionType === EActionType.DELETE){
        this.props.actUserDelete(doc);
      }
    }
  };

  render () {
    const {usersState,rolesState, sitesState} = this.props;
    const {openModal, selectRow, actionType, } = this.state;

    const TableHeaderContent = ({column, children, ...rest}) => (
      <TableHeaderRow.Content
        column={column}
        {...rest}
      >
        {column.name === 'command' ? '' : children}
      </TableHeaderRow.Content>
    );

    const CellCreate = (props) => {
      if (props.column.name === 'command') {
        return <TableFilterRow.Cell {...props}>
          <Box flex direction='row' justify={'center'} pad={{"left":"20px"}}>
            <Add size={'medium'} style={{cursor: 'pointer'}} onClick={() => this.handleAction(null,EActionType.CREATE)}/>
          </Box>
        </TableFilterRow.Cell>
      }
      return (<TableFilterRow.Cell {...props} />);
    };

    const Cell = (props) => {
      return <Table.Cell
          {...props}
          style={{
            paddingTop:'8px',
            paddingBottom:'8px',
            paddingLeft: '19px',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }}
      />;
    };

    const columns=[
      { name: 'id', title: 'Id',},
      { name: 'fullName', title: 'FullName' },
      { name: 'email', title: 'Email' },
      { name: 'command',
        getCellValue: row => {
          return(
              <Box flex direction='row' justify={'center'} gap={'small'}>
                <Edit size={'medium'} style={{cursor: 'pointer'}} onClick={() => this.handleAction(row,EActionType.UPDATE)}/>
                <Trash size={'medium'} style={{cursor: 'pointer'}} onClick={() => this.handleAction(row,EActionType.DELETE)}/>
              </Box>
          )
        },
      },

    ];

    return (

        <Box>

          {openModal &&
          <UserForm
              closeModal={()=>this.setState({openModal:false})}
              selectRow={selectRow}
              actionType={actionType}
              roles={rolesState}
              sites={sitesState}
              submitModal={(actionType: EActionType,doc: IUserDoc)=>this.handleSubmit(actionType,doc)}
          />

          }

          <Grid
              rows={usersState.docs}
              columns={columns}
          >

            <FilteringState/>
            <SortingState/>

            <PagingState
                defaultCurrentPage={1}
                defaultPageSize={10}
            />

            <IntegratedFiltering />
            <IntegratedSorting />
            <IntegratedPaging />

            <Table cellComponent={Cell}/>

            <TableHeaderRow
                showSortingControls
                contentComponent={TableHeaderContent}
            />

            <TableFilterRow
                showFilterSelector={true}
                cellComponent={CellCreate}/>

            <Toolbar />

            <PagingPanel
                pageSizes={[3, 5, 10, 20, 0]}
            />

          </Grid>
        </Box>
    )
  }


}


const mapStateToProps = (state: IApplicationState): PropsFromState => ({
  authState:    state.authState,
  usersState:  state.usersState,
  rolesState:  state.rolesState,
  sitesState: state.sitesState,
});

// Mapping our action dispatcher to props is especially useful when creating container components.
const mapDispatchToProps = (dispatch: ThunkDispatch<IApplicationState, void, Action>): PropsFromDispatch => ({
  //AUTH Actions
  actAuthLogin: (param: ILoginParams) => dispatch(actAuth.login(param)),

  actUsersGetAll: (param: IParamsTypes) => dispatch(actUsers.get(param)),
  actUserCreate: (doc: IUserDoc) => dispatch(actUser.create(doc)),
  actUserUpdate: (doc: IUserDoc) => dispatch(actUser.update(doc)),
  actUserDelete: (doc: IUserDoc) => dispatch(actUser.delete(doc)),

  actRolesGetAll: (param: IParamsTypes) => dispatch(actRoles.get(param)),
  actSitesGetAll: (param: IParamsTypes) => dispatch(actSites.get(param)),

  actAuthInit: () => dispatch(actAuth.init()),


});

export default connect(mapStateToProps,mapDispatchToProps)(UsersTable);
