import * as React from 'react';
import { RouteComponentProps, withRouter } from "react-router";
import * as routes from '../../routes/RoutesList'

import { VerticalMenu } from 'grommet-controls';
import {Box, Button, Select} from "grommet";
import {Accessibility, Article, FormClose, Home, UserWorker} from "grommet-icons";
import {IAuthState} from "../../store/auth/auth";
import {ISitesState} from "../../store/auth/sites";
import {ISiteDoc} from "../../store/auth/site";
import {isAdmin, isSuperAdmin} from "../../helpers/roles/role_helper";
import {ReactElement} from "react";
import {
  EExtraQueryType,
  IExtraQueryParam,
  IPaginationParam,
  IParamsTypes
} from "../../helpers/interfaces/forQueryParams";
import {initToken} from "../../helpers/token/auth_helper";

interface item {
  id:       string,
  label:    string,
  onClick:  () => void,
  icon?:     ReactElement,
  items?:   item[],
}

interface IProps {
  authState:  IAuthState,
  sitesState: ISitesState,
  authChangeSite: (value: ISiteDoc) => void,

  actUsersGetAll: (param: IParamsTypes) => void,
  actRolesGetAll: (param: IParamsTypes) => void,
  actSitesGetAll: (param: IParamsTypes) => void,
  actSessionsGetAll: (param: IParamsTypes) => void,
  actChurchesGetAll: (param: IParamsTypes) => void,
  actNewsGetAll: (param: IParamsTypes) => void,
}

type Props = RouteComponentProps & IProps


const sidebar: React.FunctionComponent = (props: Props) => {
  const clearSelected = async () => {
    await initToken();
    await props.authChangeSite(null);

    const pages: IPaginationParam = {page: 0, limit: 0};
    const related: IExtraQueryParam[] = [{kind: EExtraQueryType.WITH_RELATED, value: 'userRRU'}];
    const params: IParamsTypes = {paginationParams: pages, extraQuery: related};

    props.actUsersGetAll(params);
    props.actRolesGetAll(params);
    props.actSitesGetAll(params);
    props.actSessionsGetAll(params);
    props.actChurchesGetAll(params);
    props.actNewsGetAll(params);
  };

  const handlerSiteSelected = async (doc: ISiteDoc) => {
    await initToken();
    await props.authChangeSite(doc);

    const pages: IPaginationParam = {page: 0, limit: 0};
    const related: IExtraQueryParam[] = [{kind: EExtraQueryType.WITH_RELATED, value: 'userRRU'}];
    const params: IParamsTypes = {paginationParams: pages, extraQuery: related};

    if (isSuperAdmin(authState.user)) {
      props.actRolesGetAll(params);
      props.actSessionsGetAll(params);
    }

    props.actUsersGetAll(params);
    props.actSitesGetAll(params);
    props.actChurchesGetAll(params);
    props.actNewsGetAll(params);
  };


  const {history, authState} = props;
  console.log('SB: ', authState);

  // For Select
  const options: {value: ISiteDoc, label: string}[] = authState.sites.map((el: ISiteDoc) => {
    return {
      value: el,
      label: el.name,
    }
  });
  const selected: {value: ISiteDoc | null, label: string} = {
    value: !!authState.selectedSite ? authState.selectedSite : null,
    label: !!authState.selectedSite ? authState.selectedSite.name : '',
  };

  // For Navigation
  const userManageItems: item[] = [];

  if (isSuperAdmin(authState.user) || isAdmin(authState.user, authState.selectedSite)) {
    userManageItems.push({
      id: 'users',
      label: 'Users',
      onClick: () => history.push(routes.USERS)
    })
  }
  if (isSuperAdmin(authState.user)) {
    userManageItems.push({
      id: 'roles',
      label: 'Roles',
      onClick: () => history.push(routes.ROLES)
    });
    userManageItems.push({
      id: 'sites',
      label: 'Sites',
      onClick: () => history.push(routes.SITES)
    });
    userManageItems.push({
      id: 'sessions',
      label: 'Sessions',
      onClick: () => history.push(routes.SESSIONS)
    });
  }

  const prometheusItems : item[] = [];
  if (isSuperAdmin(authState.user) || isAdmin(authState.user, authState.selectedSite)) {
    prometheusItems.push({
      id: 'church',
      label: 'Church',
      icon: <UserWorker />,
      onClick: () => history.push(routes.CHURCHES)
    });
    prometheusItems.push({
      id: 'news',
      label: 'News',
      icon: <Article />,
      onClick: () => history.push(routes.NEWS)
    })
  }


  console.log('Options: ', options);
  console.log('Items: ', userManageItems);
  console.log('Items: ', prometheusItems);

  return (
      <Box>
        { authState.isAuthenticated &&
        <Box>
          <Box direction='row'>
            <Select
                labelKey="label"
                valueKey="value"
                size={'large'}
                placeholder={ 'All Sites'}
                options={options}
                value={selected}
                onChange={({ option }) => {handlerSiteSelected(option.value);}}
            />
            {isSuperAdmin(authState.user) &&
            <Button
                icon={<FormClose />}
                onClick={() => clearSelected()}
            />
            }
          </Box>
          <VerticalMenu
              activeItem={{ id: 'all' }}
              items={[
                {
                  id: 'home',
                  label: 'Home',
                  icon: <Home />,
                  onClick: () => history.push(routes.HOME)
                },
                {
                  id: 'user_manage',
                  label: 'User Manage',
                  icon: <Accessibility />,
                  expanded: false,
                  items: userManageItems
                },
                  ...prometheusItems
              ]}
          />
        </Box>
        }
      </Box>

  )

};

export const Sidebar = withRouter(sidebar);
