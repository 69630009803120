import {Box} from "grommet";
import * as React from "react";
import {connect} from 'react-redux';
import AppBar from 'components/layout/appBar';
import Body from 'components/layout/body';
import {Footer} from "components/layout/footer";
import {ThunkDispatch} from "redux-thunk";
import {IApplicationState} from "../store";
import {Action} from "redux";
import {actAuth} from "../store/auth/auth";


interface IProps {
  size: string,
}

interface PropsFromDispatch {
  authLogout: () => void,
}

interface IState {
  showSidebar: boolean,
}

type Props = IProps & PropsFromDispatch;

class Layout extends React.Component<Props, IState> {
  constructor(props: Props)  {
    super(props);

    this.state = {
      showSidebar: false,
    };
  }

  public handleShowSidebar = () => {
    this.setState(prevState => ({ showSidebar: !prevState.showSidebar }));
  };

  render() {
    const {size, authLogout} = this.props;
    const { showSidebar } = this.state;

    return(
      <Box fill>
        <AppBar handleShowSidebar={this.handleShowSidebar} size={size} authLogout={authLogout}/>
        <Body showSidebar={showSidebar} size={size}>
          {this.props.children}
        </Body>
        <Footer/>

      </Box>
    )
  }
}

// Mapping our action dispatcher to props is especially useful when creating container components.
const mapDispatchToProps = (dispatch: ThunkDispatch<IApplicationState, void, Action>): PropsFromDispatch => ({
  // Routines Actions
  authLogout: () => dispatch(actAuth.logout()),
});



export default connect<null, PropsFromDispatch> (null, mapDispatchToProps)(Layout);
