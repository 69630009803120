import {EChurchesActionTypes, IChurchesAction} from "./index";
import {Action} from "redux";
import {ThunkDispatch} from "redux-thunk";
import {IApplicationState} from "../../index";
import {EDirection, IPaginationParam, IParamsTypes, ISortParam} from "../../../helpers/interfaces/forQueryParams";
import {config} from "../../../appConfig"
import axios, {AxiosResponse} from 'axios';
import ChurchModel from "../../../models/churchModel";
import {IChurchDoc} from "../church";
import {IItems, IPages} from "../../types";
import {decode} from "../../../helpers/params/param_helper";
import {IDecodeParams} from "../../../helpers/params/types";
import {ISiteDoc} from "../../auth/site";
import {IAuthState} from "../../auth/auth";


// Default Query
const defaultOrderParams: ISortParam[] = [
	{
		field: 'name',
		dir: EDirection.UP,
	},
];

const defaultPaginationParams: IPaginationParam = {
	page: 0,
	limit: 0,
};

export const actChurches = {
	get: ({
					queryParams = [],
					sortParams = defaultOrderParams,
					paginationParams = defaultPaginationParams
				}: IParamsTypes)  => {
		return async (dispatch: ThunkDispatch<IApplicationState, void, Action>, getState: () => IApplicationState) => { // ,	getState: Function

			dispatch(actionRequestOn(EChurchesActionTypes.GET_REQUEST));
			const params: IParamsTypes = {
				queryParams,
				sortParams,
				paginationParams,
			};

			const decodeParams: IDecodeParams = {
				util:				ChurchModel.util(),
				tableName: 	ChurchModel.tableName(),
				...params
			};
			try {
				const currentUser:IAuthState = getState().authState;
				const currentSite: ISiteDoc | null =  !!currentUser && !!currentUser.selectedSite ? currentUser.selectedSite : null;

				let docs: IChurchDoc[] = [];
				let items: IItems | null = null;
				let pages: IPages | null = null;

				if(!!currentSite){
					const query: string = decode(decodeParams);
					const queryAxios: string = !!query ? '?$withRelated=siteChurchPhoto&' + query : '';
					const response: AxiosResponse = await axios.get(`${config.site.apiURL}/site/${currentSite.id}/churches` + queryAxios);

					docs = response.data.docs.map((n) => {
						return new ChurchModel(n);
					});
					items = response.data.items;
					pages = response.data.pages;
				}

				return dispatch(actionRequestOff(EChurchesActionTypes.GET_SUCCESS, docs, params, items, pages));

			}
			catch (error) {
				console.log('AXIOS error: ', JSON.stringify(error));
				if (error.response && error.response.status === 401) {
					//dispatch({type: SNACKBAR_ERROR, payload: {serverError: {code: null, message:'ACCESSO NON AUTORIZZATO'}}});
				}
				else if (error.response && error.response.status === 403) {
					//dispatch({type: SNACKBAR_ERROR, payload: {serverError: {code: null, message:'ACCESSO NON CONSENTITO'}}});
				}
				else if (error.response && error.response.status === 400) {
					//dispatch({type: SNACKBAR_ERROR, payload: {serverError: {path: error.response.data.details[0].path, message:error.response.data.details[0].path + ' ' + error.response.data.details[0].message, type:error.response.data.details[0].type}}});
				}
				else{
					//dispatch({type: SNACKBAR_ERROR, payload: {serverError: {path: error.response.data.details[0].path, message:error.response.data.details[0].path + ' ' + error.response.data.details[0].message, type:error.response.data.details[0].type}}});
				}
				// Close error
				return dispatch(actionRequestOff(EChurchesActionTypes.GET_FAILURE, [], params));
			}
		}
	},

};

// Service for Fetching
const actionRequestOn = (type: EChurchesActionTypes) => {
	return (dispatch: ThunkDispatch<IApplicationState, void, Action>) => {
		const action: IChurchesAction = {
			type: type,
			payload: {
				docs: [],
				isFetching: true,
			},
		};
		dispatch(action);
	}
};

const actionRequestOff = (type: EChurchesActionTypes, docs: IChurchDoc[], params: IParamsTypes, items?: IItems | null, pages?: IPages | null) => {
	return (dispatch: ThunkDispatch<IApplicationState, void, Action>) => {
		const action: IChurchesAction = {
			type: type,
			payload: {
				docs: !!docs ? docs : [],
				isFetching: false,
				items: !!items ? items : null,
				pages: !!pages ? pages : null,
				params: !!params ? params : {},
			},
		};
		dispatch(action);
	}
};
