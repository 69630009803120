import {EUsersActionTypes, IUsersAction} from "./index";
import {Action} from "redux";
import {ThunkDispatch} from "redux-thunk";
import {IApplicationState} from "../../index";
import {EDirection, IPaginationParam, IParamsTypes, ISortParam} from "../../../helpers/interfaces/forQueryParams";
import {config} from "../../../appConfig"
import axios from 'axios';
import UserModel from "../../../models/userModel";
import {IUserDoc} from "../user";
import {IItems, IPages, IServerResponseMany} from "../../types";
import {decode} from "../../../helpers/params/param_helper";
import {IDecodeParams} from "../../../helpers/params/types";
import {push} from "connected-react-router";
import {actAuth, IAuthState} from "../auth";
import {initToken} from "../../../helpers/token/auth_helper";
import {InitTokenReturn} from "../../../helpers/token/types";
import {isSuperAdmin} from "../../../helpers/roles/role_helper";
import {ISiteDoc} from "../site";

// Default Query
const defaultOrderParams: ISortParam[] = [
  {
    field: 'id',
    dir: EDirection.UP,
  },
];

const defaultPaginationParams: IPaginationParam = {
  page: 0,
  limit: 0,
};

interface IUsersResponse extends IServerResponseMany {
  docs: IUserDoc[],
}

export const actUsers = {
  get: ({
          queryParams = [],
          extraQuery = [],
          sortParams = defaultOrderParams,
          paginationParams = defaultPaginationParams
        }: IParamsTypes)  => {
    return async (dispatch: ThunkDispatch<IApplicationState, void, Action>, getState: () => IApplicationState) => { // ,	getState: Function

      dispatch(actionRequestOn(EUsersActionTypes.GET_REQUEST));
      const params: IParamsTypes = {
        queryParams,
        extraQuery,
        sortParams,
        paginationParams,
      };

      const decodeParams: IDecodeParams = {
        util:				UserModel.util(),
        tableName: 	UserModel.tableName(),
        ...params
      };
      const token: InitTokenReturn = await initToken();
      if (token.token) {
        try {
          const currentUser:IAuthState = getState().authState;
          const currentSite: ISiteDoc | null =  !!currentUser && !!currentUser.selectedSite ? currentUser.selectedSite : null ;
          let url = `${config.site.apiURL}/auth/users`;
          if (!!currentUser && !!currentUser.user && isSuperAdmin(currentUser.user)){
            if(!!currentSite){
              url = `${config.site.apiURL}/site/${currentSite.id}/users`;
            }
          }
          else{
            if(!!currentSite){
              url = `${config.site.apiURL}/site/${currentSite.id}/users`;
            }
          }
          axios.defaults.headers.common['Authorization'] = token.token;
          const query: string = decode(decodeParams);
          const queryAxios: string = !!query ? '?' + query : '';
          const response = await axios.get<IUsersResponse>(url + queryAxios);

          const docs: IUserDoc[] = response.data.docs.map((u) => {
            return new UserModel(u);
          });
          const items: IItems = response.data.items;
          const pages: IPages = response.data.pages;

          return dispatch(actionRequestOff(EUsersActionTypes.GET_SUCCESS, docs, params, items, pages));
        }
        catch (error) {
          console.log('AXIOS error: ', JSON.stringify(error));
          console.log('AXIOS error: ', error);
          if (error.response && error.response.status === 401) {
            //dispatch({type: SNACKBAR_ERROR, payload: {serverError: {code: null, message:'ACCESSO NON AUTORIZZATO'}}});
          } else if (error.response && error.response.status === 403) {
            //dispatch({type: SNACKBAR_ERROR, payload: {serverError: {code: null, message:'ACCESSO NON CONSENTITO'}}});
          } else if (error.response && error.response.status === 400) {
            //dispatch({type: SNACKBAR_ERROR, payload: {serverError: {path: error.response.data.details[0].path, message:error.response.data.details[0].path + ' ' + error.response.data.details[0].message, type:error.response.data.details[0].type}}});
          } else {
            //dispatch({type: SNACKBAR_ERROR, payload: {serverError: {path: error.response.data.details[0].path, message:error.response.data.details[0].path + ' ' + error.response.data.details[0].message, type:error.response.data.details[0].type}}});
          }
          // Close error
          return dispatch(actionRequestOff(EUsersActionTypes.GET_FAILURE, [], params));
        }
      }
      else {
        dispatch(push('/'));
        dispatch(actionRequestOff(EUsersActionTypes.GET_FAILURE, [], params));
        dispatch(actAuth.logout());
      }
    }
  },
};

// Service for Fetching
const actionRequestOn = (type: EUsersActionTypes) => {
  return (dispatch: ThunkDispatch<IApplicationState, void, Action>) => {
    const action: IUsersAction = {
      type: type,
      payload: {
        docs: [],
        isFetching: true,
      },
    };
    dispatch(action);
  }
};

const actionRequestOff = (type: EUsersActionTypes, docs: IUserDoc[], params: IParamsTypes, items?: IItems, pages?: IPages) => {
  return (dispatch: ThunkDispatch<IApplicationState, void, Action>) => {
    const action: IUsersAction = {
      type: type,
      payload: {
        docs: !!docs ? docs : [],
        isFetching: false,
        items: !!items ? items : null,
        pages: !!pages ? pages : null,
        params: !!params ? params : {},
      },
    };
    dispatch(action);
  }
};
