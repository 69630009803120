import {IRoleSiteUserDoc} from "../store/auth/roleSiteUser";
// import {isDate} from "date-fns";
import {ERoles, ETypes, IMUtil, ITableName} from "./types";
// import { isDate } from 'date-fns';


export default class RoleSiteUserModel implements IRoleSiteUserDoc {

  public id?:       number | null;
  public roleId:    number;
  public siteId:    string | null;
  public userId:    number | null;

  public _delete?:			boolean;

  constructor(doc?: IRoleSiteUserDoc) {
    // Initial Value with input object (optional)
    this.id =             !!doc && doc.id ? Number(doc.id) : null;
    this.roleId =         !!doc && doc.roleId ? Number(doc.roleId) : 0;
    this.siteId =         !!doc && doc.siteId ? doc.siteId : null;
    this.userId =					!!doc && doc.userId ? Number(doc.userId) : null;

    this._delete =        !!doc && doc._delete ? doc._delete : false;

    // Relations
    // this.siteRolePhoto = !!doc && doc.siteRolePhoto ? new RolePhotoModel(doc.siteRolePhoto) : null;
    // 4 Attach
    // this.attach =       !!doc && doc.attach ? AttachModel.toDB2(doc.attach) : AttachModel.toDB2(new AttachModel());
    // this.attach = {
    //   file:   !!doc && doc.attach && doc.attach.file ? doc.attach.file : null,
    //   attach: !!doc && doc.attach && doc.attach.attach ? doc.attach.attach : null,
    // }
  }

  // Object to DB
  static toDB2(doc: IRoleSiteUserDoc): IRoleSiteUserDoc {
    const docForDB = Object.assign({}, doc);
    if (!doc.id) {
      delete docForDB.id
    }
    if (!doc.userId) {
      delete docForDB.userId
    }

    // if (!!doc.siteSitePhoto) {
    //   docForDB.siteSitePhoto = SitePhotoModel.toDB2(doc.siteSitePhoto);
    // }
    // else {
    //   delete docForDB.siteSitePhoto
    // }

    return docForDB;
  }

  // STATIC methods
  static util(): IMUtil {
    return {
      id: {
        name: 'id',
        forSearch: {
          forRoles: [ERoles.SUPER_ADMIN],
          relation: {},
        },
        type: ETypes.INTEGER,
        forTable: {
          visibleFor: [],
          isSortable: true,
        },
      },

      roleId: {
        name:     'roleId',
        forSearch: {
          forRoles:   [ERoles.SUPER_ADMIN],
          relation:   {},
        },
        type:     ETypes.INTEGER,
        forTable: {
          visibleFor: [],
          isSortable: true,
        },
      },

      siteId: {
        name:     'sireId',
        forSearch: {
          forRoles:   [ERoles.SUPER_ADMIN],
          relation:   {},
        },
        type:     ETypes.STRING,
        forTable: {
          visibleFor: [],
          isSortable: true,
        },
      },

      userId: {
        name:     'userId',
        forSearch: {
          forRoles:   [ERoles.SUPER_ADMIN],
          relation:   {},
        },
        type:     ETypes.INTEGER,
        forTable: {
          visibleFor: [],
          isSortable: true,
        },
      },

    }
  }

  static tableName(): ITableName {
    return {
      singular: 'authRolesSitesUsers',
      plural:   'authRolesSitesUsers',
    }
  }


}
