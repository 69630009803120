import {Box} from "grommet";
import * as React from "react";
import {actAuth, IAuthState, ILoginParams} from "../store/auth/auth";
import {connect} from 'react-redux'
import {ThunkDispatch} from "redux-thunk";
import {IApplicationState} from "../store";
import {Action} from "redux";
import RoleForm from "./roleForm"
import {Add, Edit, Trash} from "grommet-icons";
import {actRoles, IRolesState} from "../store/auth/roles";
import {EActionType, IParamsTypes} from "../helpers/interfaces/forQueryParams";
import {Grid, PagingPanel, Table, TableFilterRow, TableHeaderRow, Toolbar,} from 'dx-react-grid-grommet';
import {
  FilteringState,
  IntegratedFiltering,
  IntegratedPaging,
  IntegratedSorting,
  PagingState,
  SortingState,
} from '@devexpress/dx-react-grid';
import {actRole, IRoleDoc} from "../store/auth/role";


interface IState {
  openModal:boolean
  selectRow: IRoleDoc | null
  actionType: EActionType | null
}

// Props passed from mapStateToProps TS
interface PropsFromState {
  authState:  IAuthState,
  rolesState: IRolesState,
}

interface PropsFromDispatch {
  actAuthLogin: (param: ILoginParams) => void,
  actRolesGetAll: (param: IParamsTypes) => void,
  actRoleCreate: (doc: IRoleDoc) => void,
  actRoleUpdate: (doc: IRoleDoc) => void,
  actRoleDelete: (doc: IRoleDoc) => void,
}

type Props = PropsFromState & PropsFromDispatch & IState;


class RolesTable extends React.Component<Props, IState> {


  constructor(props){
    super(props);
    this.state={
      openModal: false,
      selectRow: null,
      actionType: null
    }
  }

  componentDidMount(): void {
    this.props.actRolesGetAll({});
  }

  handleAction = (row:IRoleDoc | null, action:EActionType ) =>{
    this.setState({ selectRow:row , openModal: true, actionType: action});
  };
  
  handleSubmit =(actionType:EActionType, doc:IRoleDoc)=>{
    if (!!actionType){
      if (actionType === EActionType.CREATE){
        this.props.actRoleCreate(doc);
      }
      else if (actionType === EActionType.UPDATE){
        this.props.actRoleUpdate(doc);
      }
      else if (actionType === EActionType.DELETE){
        this.props.actRoleDelete(doc);
      }
    }
  };

  render () {

    const {openModal, selectRow, actionType, } = this.state;
    const {rolesState} = this.props;

    const TableHeaderContent = ({column, children, ...rest}) => (
        <TableHeaderRow.Content
            column={column}
            {...rest}
        >
          {column.name === 'command' ? '' : children}
        </TableHeaderRow.Content>
    );

    const CellCreate = (props) => {
      if (props.column.name === 'command') {
        return <TableFilterRow.Cell {...props}>
          <Box flex direction='row' justify={'center'} pad={{"left":"20px"}}>
            <Add size={'medium'} style={{cursor: 'pointer'}} onClick={() => this.handleAction(null,EActionType.CREATE)}/>
          </Box>
        </TableFilterRow.Cell>
      }
      return (<TableFilterRow.Cell {...props} />);
    };

    const Cell = (props) => {
      return <Table.Cell
          {...props}
          style={{
            paddingTop:'8px',
            paddingBottom:'8px',
            paddingLeft: '19px',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }}
      />;
    };

    const columns=[
      { name: 'id', title: 'Id',},
      { name: 'name', title: 'Name' },
      { name: 'description', title: 'Description' },
      { name: 'rate', title: 'Rate' },
      { name: 'command',
        getCellValue: row => {
          return(
            <Box flex direction='row' justify={'center'} gap={'small'}>
              <Edit size={'medium'} style={{cursor: 'pointer'}} onClick={() => this.handleAction(row,EActionType.UPDATE)}/>
              <Trash size={'medium'} style={{cursor: 'pointer'}} onClick={() => this.handleAction(row,EActionType.DELETE)}/>
            </Box>
          )
        },
      },

    ];

    return (

      <Box>

        {openModal &&
        <RoleForm
          closeModal={()=>this.setState({openModal:false})}
          selectRow={selectRow}
          actionType={actionType}
          submitModal={(actionType: EActionType,doc: IRoleDoc)=>this.handleSubmit(actionType,doc)}
        />}

        <Grid
          rows={rolesState.docs}
          columns={columns}
        >

          <FilteringState/>
          <SortingState/>

          <PagingState
            defaultCurrentPage={1}
            defaultPageSize={10}
          />

          <IntegratedFiltering />
          <IntegratedSorting />
          <IntegratedPaging />

          <Table cellComponent={Cell}/>

          <TableHeaderRow
            showSortingControls
             contentComponent={TableHeaderContent}
          />

          <TableFilterRow
            showFilterSelector={true}
            cellComponent={CellCreate}/>

          <Toolbar />

          <PagingPanel
            pageSizes={[3, 5, 10, 20, 0]}
          />

        </Grid>
      </Box>
    )
  }


}


const mapStateToProps = (state: IApplicationState): PropsFromState => ({
  authState:    state.authState,
  rolesState:  state.rolesState,
});

// Mapping our action dispatcher to props is especially useful when creating container components.
const mapDispatchToProps = (dispatch: ThunkDispatch<IApplicationState, void, Action>): PropsFromDispatch => ({
  //AUTH Actions
  actAuthLogin: (param: ILoginParams) => dispatch(actAuth.login(param)),
  actRolesGetAll: (param: IParamsTypes) => dispatch(actRoles.get(param)),
  actRoleCreate: (doc: IRoleDoc) => dispatch(actRole.create(doc)),
  actRoleUpdate: (doc: IRoleDoc) => dispatch(actRole.update(doc)),
  actRoleDelete: (doc: IRoleDoc) => dispatch(actRole.delete(doc)),
});

export default connect(mapStateToProps,mapDispatchToProps)(RolesTable);
