import { ISessionState, ESessionActionTypes } from './index';
import { Reducer } from 'redux';
import SessionModel from "../../../models/sessionModel";

// Reducer initial State with ALL objects
export const initialState: ISessionState = {
	doc: new SessionModel(),
	isFetching: false,
};

const reducer: Reducer<ISessionState> = (state = initialState, action) => {
	switch (action.type) {
		case ESessionActionTypes.GET_REQUEST:
			return {
				...state,
				...action.payload,
			};
		case ESessionActionTypes.GET_SUCCESS:
			return {
				...state,
					...action.payload,
			};
		case ESessionActionTypes.GET_FAILURE:
			return {
				...state,
				...action.payload,
			};

		case ESessionActionTypes.POST_REQUEST:
			return {
				...state,
				...action.payload,
			};
		case ESessionActionTypes.POST_SUCCESS:
			return {
				...state,
				...action.payload,
			};
		case ESessionActionTypes.POST_FAILURE:
			return {
				...state,
				...action.payload,
			};

		case ESessionActionTypes.PUT_REQUEST:
			return {
				...state,
				...action.payload,
			};
		case ESessionActionTypes.PUT_SUCCESS:
			return {
				...state,
				...action.payload,
			};
		case ESessionActionTypes.PUT_FAILURE:
			return {
				...state,
				...action.payload,
			};

		case ESessionActionTypes.DELETE_REQUEST:
			return {
				...state,
				...action.payload,
			};
		case ESessionActionTypes.DELETE_SUCCESS:
			return {
				...state,
				...action.payload,
			};
		case ESessionActionTypes.DELETE_FAILURE:
			return {
				...state,
				...action.payload,
			};
			
		//DEFAULT	
		default:
			return state;
	}
};

export { reducer as SessionReducer }
