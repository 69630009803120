import * as React from "react";
import {InjectedFormikProps, withFormik} from "formik";
import {INewDoc} from "../store/new/new";
import NewModel from "../models/newModel";
import {validate} from "../validations/newValidation";
import {EActionType} from "../helpers/interfaces/forQueryParams";
import {Box, Button, Form, FormField, Heading, Layer, Paragraph} from "grommet";
import {ISiteDoc} from "../store/auth/site";
import {config} from "../appConfig";
import { DateInput } from 'grommet-controls';
import {Trash} from "grommet-icons";

interface Props {
  selectRow: INewDoc | null,
  actionType: EActionType | null,
  submitModal: (actionType: EActionType, row: INewDoc) => void,
  closeModal: () => void,
  currentSite: ISiteDoc | null
}

const NewForm: React.FunctionComponent<InjectedFormikProps<Props, INewDoc>> = props => {
  const {handleSubmit, closeModal, values, actionType, setFieldValue, handleBlur, errors, touched, isSubmitting,currentSite} = props;

  const handleImageChange = (e) => {
    e.preventDefault();

    let reader = new FileReader();
    let uploadFile: File = e.target.files[0];
    reader.readAsDataURL(uploadFile);

    if (reader && uploadFile) {
      reader.onloadend = () => {
        const imagePath = reader.result as string;
        setFieldValue('attach.file', uploadFile);
        setFieldValue('attach.attach', imagePath);
      }
    }
  };

  const handleImageDelete = (e) => {
    e.preventDefault();

    if (values.attach.file && values.attach.attach) {
      setFieldValue('attach.file', null);
      setFieldValue('attach.attach', null);
    }

  };

  return (
      <Layer
          onEsc={closeModal}
          onClickOutside={closeModal}
      >
        <Box width={'large'} pad={"large"} >
          <Heading color={'neutral-1'} margin="none" level={3}>{`${actionType} New`}</Heading>
          <br/>
          {(actionType === EActionType.CREATE || actionType === EActionType.UPDATE) &&
          <Form>
            <FormField
                name="title"
                label="Title"
                value={values.title}
                error={touched.title && !!errors.title ? errors.title : ''}
                onBlur={handleBlur}
                onChange={event => setFieldValue('title', event.target.value)}/>
            <FormField label="Date">
              <DateInput
                  defaultValue={values.date}
                  placeholder='DD/MM/YYYY'
                  onChange={({ target: { value } }) => setFieldValue('date', value)}
              />
            </FormField>
            <FormField
                name="description"
                label="Description"
                value={!!values.description ? values.description : '' }
                error={touched.description && !!errors.description ? errors.description : ''}
                onBlur={handleBlur}
                onChange={event => setFieldValue('description', event.target.value)}/>
            <FormField
              name="text"
              label="Text"
              value={!!values.text ? values.text : '' }
              error={touched.text && !!errors.text ? errors.text : ''}
              onBlur={handleBlur}
              onChange={event => setFieldValue('text', event.target.value)}/>
            <div style={{display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              padding: '18px'}}>

              <Button type={"button"} primary label={<label htmlFor={"uploadSiteNewPhoto"}>Upload Image</label>}/>

              <input
                  accept="image/*"
                  id="uploadSiteNewPhoto"
                  style={{display:'none'}}
                  type="file"
                  onChange={ (e) => {handleImageChange(e)} }
              />

              {!!currentSite && !!currentSite.id &&
              <div style={{display: 'flex', justifyContent: "center", paddingTop: '5px'}}>
                <img style={{maxWidth:'180px',maxHeight:'100px'}}
                     src={
                       values.attach.attach ||
                       (!!values.siteNewPhoto && `${config.site.apiURL}/site/${currentSite.id}/newPhotos/photo/${values.siteNewPhoto.fileName}`) ||
                       'https://via.placeholder.com/180x100'}
                     alt={'Image'}
                />
                {((values.attach && values.attach.file) || values.siteNewPhoto) &&
                <Trash size={'small'} style={{cursor: 'pointer',paddingLeft: '10px'}} onClick={(e) => {handleImageDelete(e)}}/>
                }

              </div>
              }
            </div>
            <Box justify='end' direction={"row"} margin={{top: 'large'}} >
              <Button color='light-5' primary alignSelf={"end"} label="Cancel" onClick={closeModal}/>
              <Button disabled={isSubmitting} type="submit" primary alignSelf={"end"} margin={{left: 'small'}}
                      label="Save" onClick={()=>handleSubmit()}/>
            </Box>
          </Form>
          }
          {actionType === EActionType.DELETE &&
          <Form>
            <Paragraph margin="medium">
              Are you sure to delete new {`${values.title}`} ?
            </Paragraph>
            <Box justify='end' direction={"row"} margin={{top: 'large'}} >
              <Button color='light-5' primary alignSelf={"end"} label="Cancel" onClick={closeModal}/>
              <Button disabled={isSubmitting} type="submit" color={"red"} alignSelf={"end"} margin={{left: 'small'}}
                      label="Delete" onClick={()=>handleSubmit()}/>
            </Box>
          </Form>
          }

        </Box>
      </Layer>
  );
};


const newForm = withFormik<Props, INewDoc>({
  enableReinitialize: true,
  validateOnChange: false,

  mapPropsToValues: (props): INewDoc => {
    console.log('FKProps: ', props.selectRow);
    if (!!props.selectRow){
      return {
        ...props.selectRow,
      };
    }
    else{
      return new NewModel();
    }

  },

  validate: (values: INewDoc) => {
    return validate(values, false);
  },

  handleSubmit: (values: INewDoc, { props, setSubmitting, setErrors }) => {
    console.log('Submit: ', values);
    setSubmitting(true);
    let errors = validate(values, true);

    if (Object.keys(errors).length > 0) {
      setErrors(errors);
      setSubmitting(false);
    }
    else {
      const doc = new NewModel(values);

      if (!!props.actionType)
        props.submitModal(props.actionType, doc);

      setSubmitting(false);
    }
  }
})(NewForm);

export default newForm;
