export const userName: () => string = () => {
	return 'yoo-no-lab-user';
};

export const nextAvatarName: () => string = () => {
	return 'yoo-no-lab-next-avatar';
};

export const originalAvatarName: () => string = () => {
	return 'yoo-no-lab-original-avatar';
};

export const ownerName: () => string = () => {
	return 'yoo-no-lab-owner';
};

export const tokenName: () => string = () => {
	return 'yoo-no-lab-token';
};

export const refreshTokenName: () => string = () => {
	return 'yoo-no-lab-refreshToken';
};

