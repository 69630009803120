import { ISiteState, ESiteActionTypes } from './index';
import { Reducer } from 'redux';
import SiteModel from "../../../models/siteModel";

// Reducer initial State with ALL objects
export const initialState: ISiteState = {
	doc: new SiteModel(),
	isFetching: false,
};

const reducer: Reducer<ISiteState> = (state = initialState, action) => {
	switch (action.type) {
		case ESiteActionTypes.GET_REQUEST:
			return {
				...state,
				...action.payload,
			};
		case ESiteActionTypes.GET_SUCCESS:
			return {
				...state,
					...action.payload,
			};
		case ESiteActionTypes.GET_FAILURE:
			return {
				...state,
				...action.payload,
			};

		case ESiteActionTypes.POST_REQUEST:
			return {
				...state,
				...action.payload,
			};
		case ESiteActionTypes.POST_SUCCESS:
			return {
				...state,
				...action.payload,
			};
		case ESiteActionTypes.POST_FAILURE:
			return {
				...state,
				...action.payload,
			};

		case ESiteActionTypes.PUT_REQUEST:
			return {
				...state,
				...action.payload,
			};
		case ESiteActionTypes.PUT_SUCCESS:
			return {
				...state,
				...action.payload,
			};
		case ESiteActionTypes.PUT_FAILURE:
			return {
				...state,
				...action.payload,
			};

		case ESiteActionTypes.DELETE_REQUEST:
			return {
				...state,
				...action.payload,
			};
		case ESiteActionTypes.DELETE_SUCCESS:
			return {
				...state,
				...action.payload,
			};
		case ESiteActionTypes.DELETE_FAILURE:
			return {
				...state,
				...action.payload,
			};
			
		//DEFAULT	
		default:
			return state;
	}
};

export { reducer as SiteReducer }
