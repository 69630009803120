import {Box, Button, Heading} from "grommet";
import {Menu, Notification, Login, Logout} from "grommet-icons";
import * as React from "react";
import {connect} from 'react-redux';
import {IApplicationState} from "../../store";
import {IAuthState} from "../../store/auth/auth";
import { RouteComponentProps, withRouter } from "react-router";
import * as routes from '../../routes/RoutesList';


const MainBar = (props) => (
  <Box
    tag='header'
    direction='row'
    align='center'
    justify='between'
    background={'brand'}
    pad={{ left: 'medium', right: 'small', vertical: 'small' }}
    elevation='medium'
    style={{ zIndex: 3 }}
    {...props}
  />
);


interface PropsFromState {
  authState: IAuthState,
}

interface IProps {
  handleShowSidebar: () => void,
  authLogout: () => void,
  size: string,
}

type Props = PropsFromState & IProps & RouteComponentProps


const AppBar: React.FC<Props> = (props) => {

  const { handleShowSidebar, size, history, authState, authLogout } = props;
  console.log('AB: ', authState);

  return(
    <MainBar>
      <Box>
        {size !== 'large' && authState.isAuthenticated && <Button
          icon={<Menu />}
          onClick={() => handleShowSidebar()}
        />}
      </Box>
      <Box>
        <Heading level='3' margin='none'>Admin Panel</Heading>
      </Box>
      <Box direction='row'>
        <Button
          icon={<Notification />}
          disabled={true}
        />
        {authState.isAuthenticated ? (
          <Button
            icon={<Logout />}
            onClick={() => authLogout()}
          />
        ) : (
          <Button
            icon={<Login />}
            onClick={() => history.push(routes.SIGN_IN)}
          />
        )
        }

      </Box>
    </MainBar>

  )
};

function mapStateToProps(state: IApplicationState) {
  return {
    authState: state.authState,
  };
}

export default connect<PropsFromState, IApplicationState>(mapStateToProps)(withRouter(AppBar));
