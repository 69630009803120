import { combineReducers, Reducer } from 'redux'
import { connectRouter } from 'connected-react-router'

// Import ALL state types and Reducers
import { AuthReducer, IAuthState } from "./auth/auth";
// import { IConfigState, ConfigReducer } from "./config";
import { LanguageReducer, ILanguageState } from "./language";
import { RoleReducer, IRoleState } from "./auth/role/index";
import { RolesReducer, IRolesState } from "./auth/roles/index";
import { SiteReducer, ISiteState } from "./auth/site/index";
import { SitesReducer, ISitesState } from "./auth/sites/index";
import { UserReducer, IUserState } from "./auth/user/index";
import { UsersReducer, IUsersState } from "./auth/users/index";
import {ISessionState, SessionReducer} from "./auth/session";
import {ISessionsState, SessionsReducer} from "./auth/sessions";
import {ChurchReducer, IChurchState} from "./church/church";
import {ChurchesReducer, IChurchesState} from "./church/churches";
import {INewState, NewReducer} from "./new/new";
import {INewsState, NewsReducer} from "./new/news";

// The top-level state object
export interface IApplicationState {
  authState:        IAuthState,
  // configState:     IConfigState,
  languageState:    ILanguageState,
  roleState:        IRoleState,
  rolesState:       IRolesState,
  siteState:        ISiteState,
  sitesState:       ISitesState,
  sessionState:     ISessionState,
  sessionsState:    ISessionsState,
  userState:        IUserState,
  usersState:       IUsersState,
  churchState:      IChurchState,
  churchesState:    IChurchesState,
  newState:         INewState,
  newsState:        INewsState,

  // messageState:    IMessageState,
  // churchState:     IChurchState,
  // churchesState:   IChurchesState,
  // peopleState:     IPeopleState,
  // personState:     IPersonState,
  // errorState:      IErrorState,
}

// Whenever an action is dispatched, Redux will update each top-level application state property
// using the reducer with the matching name. It's important that the names match exactly, and that
// the reducer acts on the corresponding Application import { IMunicipalitiesState } from './geo/municipalities/types';onState property type.


export default (history): Reducer<IApplicationState> => combineReducers({
  router:           connectRouter(history),
  // rest of your reducers
  authState:        AuthReducer,
  // configState: ConfigReducer,
  languageState:    LanguageReducer,
  roleState:        RoleReducer,
  rolesState:       RolesReducer,
  siteState:        SiteReducer,
  sitesState:       SitesReducer,
  sessionState:     SessionReducer,
  sessionsState:    SessionsReducer,
  userState:        UserReducer,
  usersState:       UsersReducer,
  churchState:      ChurchReducer,
  churchesState:    ChurchesReducer,
  newState:         NewReducer,
  newsState:        NewsReducer
})
