import {Box, Button, Form, FormField, Heading} from "grommet";
import * as React from "react";
import {InjectedFormikProps, withFormik} from "formik";
import {actAuth, IAuthState, ILoginParams} from "../store/auth/auth";
import {validate} from "../validations/loginValidation";
import { connect } from 'react-redux'
import {ThunkDispatch} from "redux-thunk";
import {IApplicationState} from "../store";
import {Action} from "redux";


interface IState {
  showSidebar: boolean,
}

// Props passed from mapStateToProps TS
interface PropsFromState {
  authState?: IAuthState
}

interface PropsFromDispatch {
  actAuthLogin: (param: ILoginParams) => void,
  actAuthInit: () => void,
}

interface IState {
  param: ILoginParams,
}

type IProps = PropsFromState & PropsFromDispatch & IState;


const form: React.FunctionComponent<InjectedFormikProps<IProps, ILoginParams>> = props => {
  const {handleSubmit, isSubmitting, values, setFieldValue, handleBlur, errors, touched} = props;

  return(

      <Box pad="medium" width={'medium'} background={'light-3'} round={'medium'}>
        <Heading color={'neutral-1'} margin="none" level={3}>Sign In</Heading>
        <br/>
        <Form>
          <FormField
            name="email"
            label="Email"
            value={values.email}
            error={touched.email && !!errors.email ? errors.email : ''}
            onBlur={handleBlur}
            onChange={event => setFieldValue('email', event.target.value)}/>
          <FormField
            name="password"
            type={'password'}
            label="Password"
            value={values.password}
            error={touched.password && !!errors.password ? errors.password : ''}
            onBlur={handleBlur}
            onChange={event => setFieldValue('password', event.target.value)}/>
          <Box>
            <Button disabled={isSubmitting} type="submit" primary alignSelf={"end"} label="Log In" onClick={()=>handleSubmit()}/>
          </Box>
        </Form>
      </Box>

  )

};


const signInForm = withFormik<IProps ,ILoginParams>({
  enableReinitialize: true,
  validateOnChange: false,

  mapPropsToValues: (): ILoginParams => {
    return {email:'', password:''};
  },

  validate: (values: ILoginParams) => {
    return validate(values, false);
  },

  handleSubmit: (values: ILoginParams, { props, setSubmitting, setErrors }) => {
    setSubmitting(true);
    let errors = validate(values, true);

    if (Object.keys(errors).length > 0) {
      setErrors(errors);
      setSubmitting(false);
    }
    else {
      props.actAuthLogin(values);
      setSubmitting(false);
    }
  }
})(form);

// Mapping our action dispatcher to props is especially useful when creating container components.
const mapDispatchToProps = (dispatch: ThunkDispatch<IApplicationState, void, Action>): PropsFromDispatch => ({
  //AUTH Actions
  actAuthLogin: (param: ILoginParams) => dispatch(actAuth.login(param)),
  //AUTH Actions
  actAuthInit: () => dispatch(actAuth.init()),
});

export default connect(null,mapDispatchToProps)(signInForm);


