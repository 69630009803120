import {EUsersActionTypes, IUsersAction, IUsersState} from './index';
import {Reducer} from 'redux';



// Reducer initial State with ALL objects
export const initialState: IUsersState = {
	docs: [],
	isFetching: false,
	items: null,
	pages: null,
	params: {},
};

const reducer: Reducer<IUsersState> = (state: IUsersState = initialState, action: IUsersAction) => {
	switch (action.type) {
		case EUsersActionTypes.GET_REQUEST:
			return {
				...state,
				...action.payload,
			};
		case EUsersActionTypes.GET_SUCCESS:
			return {
				...state,
					...action.payload,
			};
		case EUsersActionTypes.GET_FAILURE:
			return {
				...state,
				...action.payload,
			};

		default:
			return state;
	}
};

export { reducer as UsersReducer }
