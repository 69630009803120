import {ENewsActionTypes, INewsAction, INewsState} from '.';
import {Reducer} from 'redux';



// Reducer initial State with ALL objects
export const initialState: INewsState = {
	docs: [],
	isFetching: false,
	items: null,
	pages: null,
	params: {},
};

const reducer: Reducer<INewsState> = (state: INewsState = initialState, action: INewsAction) => {
	switch (action.type) {
		case ENewsActionTypes.GET_REQUEST:
			return {
				...state,
				...action.payload,
			};
		case ENewsActionTypes.GET_SUCCESS:
			return {
				...state,
					...action.payload,
			};
		case ENewsActionTypes.GET_FAILURE:
			return {
				...state,
				...action.payload,
			};

		default:
			return state;
	}
};

export { reducer as NewsReducer }
