interface ILocaleOptions {
	locale: string,
	name: string,
}

export interface IConfig {
	defaultLocale: string,

	locales: {
		[key: string]: string
	}

	languageOptions: ILocaleOptions[],

	modules: {
		base: {
			editProfile: boolean,
			pwdChange: boolean,
			pwdForgot: boolean,
			signIn: boolean,
			signInGoogle: boolean,
			signInFacebook: boolean,
			signUp: boolean,
			signOut: boolean,
		}
	}
	notification: {
		duration: number,
	},

	request: {
		pageLimit: number,
	},

	site: {
		apiURL: string,
		cp: string,
	}

}

export const config: IConfig = {
  defaultLocale: 'en',

  languageOptions: [
		{ locale: 'it-IT',
			name: 'Italiano',
		},
		{ locale: 'en-US',
			name: 'English'
		},
  ],

	locales: {
		'en': 'english',
		'it': 'italiano',
	},

	modules: {
		base: {
			editProfile: true,
			pwdChange: true,
			pwdForgot: true,
			signIn: true,
			signInGoogle: true,
			signInFacebook: true,
			signUp: true,
			signOut: true,
		},
	},

	notification: {
		duration: 4000,
	},

	request: {
  	pageLimit: 10,
	},

	site: {
		cp: '35482697-1234-5665-4321-951378246555',
		//apiURL: 'http://localhost:4000/api/v1',
		apiURL: 'https://yoono-data01.yoonolab.it/api/v1',
	}
};
