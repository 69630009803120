import * as jwtDecode from 'jwt-decode';
import axios from 'axios/index';
import {tokenName, refreshTokenName, config, userName} from '../../appConfig';
import {IGetTokenReturn, InitTokenReturn, IRefreshToken, IResponseMeta, IToken} from "./types";
import {ILoginUserDoc} from "../../store/auth/auth";



export const initToken = async (): Promise<InitTokenReturn> => {
	// Test for server or client
	let token : string | null;
	try{
		token =	!!localStorage.getItem(tokenName()) ? localStorage.getItem(tokenName())	: null;
	}
	catch(error) {
		return (
			{
				user: null,
				token: null,
			}
		)
	}

	// No Token
	if (!token) {
		return (
			{
				user: null,
				token: null,
			}
		)
	}
	// Token is present
	else {
		const decodedToken: IToken = await jwtDecode(token);

		// Token expired!
		if (isTokenExpired(decodedToken)) {
			const refreshToken: string | null =	!!localStorage.getItem(refreshTokenName()) ?
				localStorage.getItem(refreshTokenName()) :
				null;

			// NO RefreshToken
			if (!refreshToken) {
				return (
					{
						user: null,
						token: null,
					}
				)
			}

			const decodedRefreshToken: IRefreshToken = jwtDecode(refreshToken);

			// RefreshToken expired
			if (isTokenExpired(decodedRefreshToken)) {
				deleteToken();
				return (
					{
						user: null,
						token: null,
					}
				)
			}
			// RefreshToken not expired
			else {
				try {
					const response = await axios({
						method: 'post',
						url: `${config.site.apiURL}/auth/auth/refresh`,
						headers: {
							Accept: 'application/json',
							Authorization: refreshToken,
						},
					});

					// Response has Token
					if (response && response.data && response.data.meta) {
						deleteToken();
						setToken(response.data.meta);
						return (
							{
								user: response.data.meta.user,
								token: response.data.meta.authHeader,
							}
						)
					}
					// N Token in response
					else {
						deleteToken();
						return (
							{
								user: null,
								token: null,
							}
						)
					}
				}
				catch (error) {
					console.log('RefreshToken Error: ', error);
					return (
						{
							user: null,
							token: null,
						}
					)
				}
			}
		}
		// Token is not expired
		else {
			const userJson: string | null = !!localStorage.getItem(userName()) ? localStorage.getItem(userName()) : null;
			const user: ILoginUserDoc | null = !!userJson ? JSON.parse(userJson) : null;
			return (
				{
					user,
					token,
				}
			)
		}
	}
};


export const getToken = (): IGetTokenReturn => {
	const token: string | null =	!!localStorage.getItem(tokenName()) ? localStorage.getItem(tokenName())	: null;

	// No Token
	if (!token) {
		return (
			{
				token: null
			}
		)
	}
	// Token is present
	else {
		const decodedToken: IToken = jwtDecode(token);

		// Token expired!
		if (isTokenExpired(decodedToken)) {
			const refreshToken: string | null =	!!localStorage.getItem(refreshTokenName()) ?
				localStorage.getItem(refreshTokenName()) :
				null;

			// NO RefreshToken
			if (!refreshToken) {
				return (
					{
						token: null
					}
				)
			}

			const decodedRefreshToken: IRefreshToken = jwtDecode(refreshToken);

			// RefreshToken expired
			if (isTokenExpired(decodedRefreshToken)) {
				deleteToken();
				return (
					{
						token: null
					}
				)
			}
			// RefreshToken not expired
			else {
				return (
					{
						token: refreshToken,
					}
				)
			}
		}
		// Token is not expired
		else {
			return (
				{
					token
				}
			)
		}
	}
};


export const setToken = (responseMeta: IResponseMeta): void => {
	deleteToken();
	localStorage.setItem(tokenName(), responseMeta.authHeader);
	localStorage.setItem(refreshTokenName(), responseMeta.refreshToken);
	localStorage.setItem(userName(), JSON.stringify(responseMeta.user));

};

export const deleteToken = (): void => {
	localStorage.removeItem(refreshTokenName());
	localStorage.removeItem(tokenName());
	localStorage.removeItem(userName());

};


// Internal

const isTokenExpired = (token: IToken | IRefreshToken): boolean => {

	if (token) {
		return token.exp < Date.now() / 1000;
	}
	return true;
};

