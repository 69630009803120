import * as Yup from "yup";
import {IChurchDoc} from "../store/church/church";


const syncValidationSchema = Yup.object().shape({
	date:					Yup.string(),
	name: 				Yup.string().required().min(3),
	originalName:	Yup.string().required().min(3),
	map:					Yup.number().required().min(1),
	number:				Yup.number().required().min(1),
	siteId:				Yup.string().nullable(true),
});


const submitValidationSchema = Yup.object().shape({
	date:					Yup.string(),
	name: 				Yup.string().required().min(3),
	originalName:	Yup.string().required().min(3),
	map:					Yup.number().required().min(1),
	number:				Yup.number().required().min(1),
	siteId:				Yup.string().nullable(true),
});


const options = {
	strict: false,
	abortEarly: false,
	stripUnknown: false,
	recursive: true
};

export const validate = (values: IChurchDoc, isSubmitting: boolean) => {

	let errors = {};


	if (!isSubmitting) {

		try {
			syncValidationSchema.validateSync(values, options);
		}
		catch (err) {
			console.log('Error: ', err);
			Object.keys(values).map(key => {

				err.inner.forEach(error => {
					if (error.path === key && !errors[key]) {

						errors[key] = error.message;
					}
				});
			});
		}
	} else {

		try {
			submitValidationSchema.validateSync(values, options);
		} catch (err) {

			Object.keys(values).map(key => {
				err.inner.forEach(error => {
					if (error.path === key && !errors[key]) {
						errors[key] = error.message;
					}
				});
			});
		}
	}

	return errors;
};

