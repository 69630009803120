import {Action} from 'redux';
import { batch } from 'react-redux';
import {ThunkDispatch} from 'redux-thunk';
import {IApplicationState} from "../../index";
import {EAuthActionTypes, IAuthAction, ILoginParams, ILoginUserDoc} from './index';
import {deleteToken, initToken, setToken} from "../../../helpers/token/auth_helper";
import {InitTokenReturn, IResponseMeta} from "../../../helpers/token/types";
import axios, {AxiosResponse} from "axios";
import {config} from "../../../appConfig";
import {IServerResponseMany, IServerResponseOne} from "../../types";
import { push } from 'connected-react-router';
import { includes, remove, split, uniq } from 'lodash';
import {ISiteDoc} from "../site";
import SiteModel from "../../../models/siteModel";
import * as routes from '../../../routes/RoutesList';
// import {history} from '../../configureStore';



interface ILoginResponse extends IServerResponseOne {
	doc: ILoginUserDoc,
}

interface ISitesResponse extends IServerResponseMany {
	docs: ISiteDoc[],
}


export const actAuth = {
	// Init
	init: () => {
		return async (dispatch: ThunkDispatch<IApplicationState, void, Action>) => { // ,	getState: Function
			dispatch(actionRequestOn(EAuthActionTypes.AUTH_INIT_REQUEST));

			const response: InitTokenReturn = await initToken();
			let user: ILoginUserDoc | null = response.user;
			// IF Token!
			if (!!response.user && !!response.token) {
				const {isSuperAdmin, sites, selectedSite} = await subAction(response.user, response.token);
				if (!isSuperAdmin && !sites.length && !selectedSite)
					user = null;
				batch(() => {
					dispatch(actionRequestOff(EAuthActionTypes.AUTH_INIT_SUCCESS, user));
					dispatch(actionInitOff(EAuthActionTypes.AUTH_INIT_SUCCESS, sites, selectedSite));
				});
			}
			else {
				dispatch(actionRequestOff(EAuthActionTypes.AUTH_INIT_SUCCESS, user));
			}
		}
	},

	// Login
	login: (params: ILoginParams) => {
		return async (dispatch: ThunkDispatch<IApplicationState, void, Action>) => { // ,	getState: Function
			dispatch(actionRequestOn(EAuthActionTypes.AUTH_LOGIN_REQUEST));

			try {

				const response = await axios.post<ILoginResponse>(`${config.site.apiURL}/auth/auth/login`, params);
				const meta: IResponseMeta | null = response.data.meta ? response.data.meta : null;
				let user: ILoginUserDoc | null = meta && meta.user ? meta.user : null;

				if (!!user && !!meta) {
					const {isSuperAdmin, sites, selectedSite} = await subAction(user, meta.authHeader);
					if (!isSuperAdmin && !sites.length && !selectedSite)
						user = null;
					else
						setToken(meta);
					batch(() => {
						dispatch(push(routes.HOME));
						dispatch(actionInitOff(EAuthActionTypes.AUTH_INIT_SUCCESS, sites, selectedSite));
						dispatch(actionRequestOff(EAuthActionTypes.AUTH_LOGIN_SUCCESS, user));
					});
				}
			}
			catch (error) {
				console.log('error: ', error.response);
				if (error.response && error.response.status === 401) {
					//dispatch({type: SNACKBAR_ERROR, payload: {serverError: {code: null, message:'ACCESSO NON AUTORIZZATO'}}});
				}
				else if (error.response && error.response.status === 403) {
					//dispatch({type: SNACKBAR_ERROR, payload: {serverError: {code: null, message:'ACCESSO NON CONSENTITO'}}});
				}
				else if (error.response && error.response.status === 400) {
					//dispatch({type: SNACKBAR_ERROR, payload: {serverError: {path: error.response.data.details[0].path, message:error.response.data.details[0].path + ' ' + error.response.data.details[0].message, type:error.response.data.details[0].type}}});
				}
				else{
					//dispatch({type: SNACKBAR_ERROR, payload: {serverError: {path: error.response.data.details[0].path, message:error.response.data.details[0].path + ' ' + error.response.data.details[0].message, type:error.response.data.details[0].type}}});
				}
				// Close error
				return dispatch(actionRequestOff(EAuthActionTypes.AUTH_LOGIN_FAILURE, null));
			}

		}
	},

	logout: () => {
		return async (dispatch: ThunkDispatch<IApplicationState, void, Action>) => {
			dispatch(actionRequestOn(EAuthActionTypes.AUTH_LOGOUT_REQUEST));

			deleteToken();

			dispatch(actionRequestOff(EAuthActionTypes.AUTH_LOGOUT_SUCCESS, null));
			dispatch(actionInitOff(EAuthActionTypes.AUTH_INIT_SUCCESS, [], null));
			dispatch(push(routes.LANDING));
		}
	},

	changeSite: (value: ISiteDoc | null) => {
		return async (dispatch: ThunkDispatch<IApplicationState, void, Action>) => {
			dispatch(actionRequestOn(EAuthActionTypes.AUTH_CHANGESITE_REQUEST));

			const action: IAuthAction = {
				type: EAuthActionTypes.AUTH_CHANGESITE_SUCCESS,
				payload: {
					selectedSite: value,
					isFetching:	false,
				},
			};
			dispatch(action);

			// dispatch(push(routes.DASHBOARD));
		}
	},

};

// SubAction for 2 actions!!!
const subAction = async (user: ILoginUserDoc, token: string) => {
	const scope: string[] = Array.from(user.scope);
	const isSuperAdmin: boolean = includes(scope, 'SuperAdmin');

	if (isSuperAdmin) {
		let sites: ISiteDoc[] = [];
		try {
			// doc.siteId = config.site.id;
			axios.defaults.headers.common['Authorization'] = token;

			const response = await axios.get<ISitesResponse>(`${config.site.apiURL}/auth/sites`);
			sites = response.data.docs.map((s: ISiteDoc) => {
				return new SiteModel(s);
			});

		}
		catch (error) {
			console.log('error: ', error.response);
			if (error.response && error.response.status === 401) {
				//dispatch({type: SNACKBAR_ERROR, payload: {serverError: {code: null, message:'ACCESSO NON AUTORIZZATO'}}});
			}
			else if (error.response && error.response.status === 403) {
				//dispatch({type: SNACKBAR_ERROR, payload: {serverError: {code: null, message:'ACCESSO NON CONSENTITO'}}});
			}
			else if (error.response && error.response.status === 400) {
				error.response.data.details.map(error => {
					// const message: IMessage = {
					// 	code: error.context.label==='image' ? 'attach.file' : error.context.label,
					// 	message: error.message,
					// 	type: EMessageTypes.ERROR,
					// };
					console.log('Msg: ', error);
					// dispatch(messageActions.add(message));
				});
			}
			else{
				//dispatch({type: SNACKBAR_ERROR, payload: {serverError: {path: error.response.data.details[0].path, message:error.response.data.details[0].path + ' ' + error.response.data.details[0].message, type:error.response.data.details[0].type}}});
			}
			// dispatch(actionRequestOff(EAuthActionTypes.AUTH_INIT_FAILURE, null));
		}
		return({
			isSuperAdmin,
			sites,
			selectedSite: null,
		})
	}
	else {
		remove(scope, (s) => {
			return s === 'SuperAdmin' || s === 'Logged' || s.includes('User-');
		});
		const sitesID: string[] = remove(scope.map((s: string) => {
			const parts: string[] = split(s, '_');
			if (parts[2] !== 'Guest') {
				return parts[1];
			}
			return null;
		}), (s) => {
			return !!s;
		});

		const promises: Promise<ISiteDoc>[] = uniq(sitesID).map(async (id) => {
			try {
				// doc.siteId = config.site.id;
				axios.defaults.headers.common['Authorization'] = token;

				const response: AxiosResponse = await axios.get(`${config.site.apiURL}/auth/sites/` + id);
				return (new SiteModel(response.data.doc as ISiteDoc));

			}
			catch (error) {
				console.log('error: ', error.response);
				if (error.response && error.response.status === 401) {
					//dispatch({type: SNACKBAR_ERROR, payload: {serverError: {code: null, message:'ACCESSO NON AUTORIZZATO'}}});
				}
				else if (error.response && error.response.status === 403) {
					//dispatch({type: SNACKBAR_ERROR, payload: {serverError: {code: null, message:'ACCESSO NON CONSENTITO'}}});
				}
				else if (error.response && error.response.status === 400) {
					error.response.data.details.map(error => {
						// const message: IMessage = {
						// 	code: error.context.label==='image' ? 'attach.file' : error.context.label,
						// 	message: error.message,
						// 	type: EMessageTypes.ERROR,
						// };
						console.log('Msg: ', error);
						// dispatch(messageActions.add(message));
					});
				}
				else{
					//dispatch({type: SNACKBAR_ERROR, payload: {serverError: {path: error.response.data.details[0].path, message:error.response.data.details[0].path + ' ' + error.response.data.details[0].message, type:error.response.data.details[0].type}}});
				}
				return (new SiteModel());
				// dispatch(actionRequestOff(EAuthActionTypes.AUTH_INIT_FAILURE, null));
			}
		});

		const sites: ISiteDoc[] = remove(await Promise.all(promises), (s: ISiteDoc) => {
			return !!s.id;
		});

		const selectedSite: ISiteDoc | null = !!sites.length ? sites[0] : null;

		return({
			isSuperAdmin,
			sites,
			selectedSite,
		})
	}
};


// Service for Fetching
const actionRequestOn = (type: EAuthActionTypes) => {
	return (dispatch: ThunkDispatch<IApplicationState, void, Action>) => {
		const action: IAuthAction = {
			type: type,
			payload: {
				isFetching: true,
			},
		};
		dispatch(action);
	}
};

const actionRequestOff = (type: EAuthActionTypes, user: ILoginUserDoc | null) => {
	return (dispatch: ThunkDispatch<IApplicationState, void, Action>) => {
		const action: IAuthAction = {
			type: type,
			payload: {
				isAuthenticated: !!user,
				user: !!user ? user : null,
				isFetching: false,
				isInitializing: false,
			},
		};
		dispatch(action);
	}
};

const actionInitOff = (type: EAuthActionTypes, sites: ISiteDoc[], selectedSite: ISiteDoc | null) => {
	return (dispatch: ThunkDispatch<IApplicationState, void, Action>) => {
		const action: IAuthAction = {
			type: type,
			payload: {
				isInitializing: false,
				sites,
				selectedSite,
			},
		};
		dispatch(action);
	}
};

