import { ESessionActionTypes, ISessionAction, ISessionDoc } from "./index";
import { Action } from "redux";
import { batch } from 'react-redux';
import { ThunkDispatch } from "redux-thunk";
import { IApplicationState } from "../../index";
import SessionModel from "../../../models/sessionModel";
import {initToken} from "../../../helpers/token/auth_helper";
import {actAuth} from "../auth";
import axios from "axios";
import {config} from "../../../appConfig";
import {IServerResponseOne} from "../../types";
import {InitTokenReturn} from "../../../helpers/token/types";
import {actSessions} from "../sessions";
import {push} from "connected-react-router";

interface ISessionResponse extends IServerResponseOne {
	doc: ISessionDoc,
}

export const actSession = {
	create: (doc: ISessionDoc) => {
		return async (dispatch: ThunkDispatch<IApplicationState, void, Action>, getState: () => IApplicationState) => {
			dispatch(actionRequestOn(ESessionActionTypes.POST_REQUEST, doc));

			const sessionsState = getState().sitesState;
			const params = !!sessionsState && !!sessionsState.params ? !!sessionsState.params : {};
			const token: InitTokenReturn = await initToken();
			if (token.token) {
				try {
					// doc.sessionId = config.session.id;
					axios.defaults.headers.common['Authorization'] = token.token;

					await axios.post<ISessionResponse>(`${config.site.apiURL}/auth/sessions`, SessionModel.toDB2(doc));
					// const newDoc: ISessionDoc | null = response.data.doc ? response.data.doc : null;
				}
				catch (error) {
					console.log('error: ', error.response);
					if (error.response && error.response.status === 401) {
						//dispatch({type: SNACKBAR_ERROR, payload: {serverError: {code: null, message:'ACCESSO NON AUTORIZZATO'}}});
					}
					else if (error.response && error.response.status === 403) {
						//dispatch({type: SNACKBAR_ERROR, payload: {serverError: {code: null, message:'ACCESSO NON CONSENTITO'}}});
					}
					else if (error.response && error.response.status === 400) {
						error.response.data.details.map(error => {
							// const message: IMessage = {
							// 	code: error.context.label==='image' ? 'attach.file' : error.context.label,
							// 	message: error.message,
							// 	type: EMessageTypes.ERROR,
							// };
							console.log('Msg: ', error);
							// dispatch(messageActions.add(message));
						});
					}
					else{
						//dispatch({type: SNACKBAR_ERROR, payload: {serverError: {path: error.response.data.details[0].path, message:error.response.data.details[0].path + ' ' + error.response.data.details[0].message, type:error.response.data.details[0].type}}});
					}
					return dispatch(actionRequestOff(ESessionActionTypes.POST_FAILURE, doc));
				}
			}
			else {
				batch(() => {
					dispatch(push('/'));
					dispatch(actionRequestOff(ESessionActionTypes.POST_FAILURE));
					dispatch(actAuth.logout());
				});
			}
			batch(() => {
				dispatch(actSessions.get(params));
				dispatch(actionRequestOff(ESessionActionTypes.POST_SUCCESS, doc));
			});
		}
	},

	update: (doc: ISessionDoc) => {
		return async (dispatch: ThunkDispatch<IApplicationState, void, Action>, getState: () => IApplicationState) => {
			dispatch(actionRequestOn(ESessionActionTypes.PUT_REQUEST, doc));

			const sessionsState = getState().sitesState;
			const params = !!sessionsState && !!sessionsState.params ? !!sessionsState.params : {};
			const token: InitTokenReturn = await initToken();
			if (token.token) {
				try {
					// doc.sessionId = config.session.id;

					axios.defaults.headers.common['Authorization'] = token.token;
					await axios.put<ISessionResponse>(`${config.site.apiURL}/auth/sessions/${doc.id}`, SessionModel.toDB2(doc));
					// const newDoc: ISessionDoc | null = response.data.doc ? response.data.doc : null;
				}
				catch (error) {
					console.log('error: ', error);
					if (error.response && error.response.status === 401) {
						//dispatch({type: SNACKBAR_ERROR, payload: {serverError: {code: null, message:'ACCESSO NON AUTORIZZATO'}}});
					}
					else if (error.response && error.response.status === 403) {
						//dispatch({type: SNACKBAR_ERROR, payload: {serverError: {code: null, message:'ACCESSO NON CONSENTITO'}}});
					}
					else if (error.response && error.response.status === 400) {
						//dispatch({type: SNACKBAR_ERROR, payload: {serverError: {path: error.response.data.details[0].path, message:error.response.data.details[0].path + ' ' + error.response.data.details[0].message, type:error.response.data.details[0].type}}});
					}
					else{
						//dispatch({type: SNACKBAR_ERROR, payload: {serverError: {path: error.response.data.details[0].path, message:error.response.data.details[0].path + ' ' + error.response.data.details[0].message, type:error.response.data.details[0].type}}});
					}
					return dispatch(actionRequestOff(ESessionActionTypes.PUT_FAILURE, doc));
				}
			}
			else {
				batch(() => {
					dispatch(push('/'));
					dispatch(actionRequestOff(ESessionActionTypes.PUT_FAILURE));
					dispatch(actAuth.logout());
				});
			}
			batch(() => {
				dispatch(actSessions.get(params));
				dispatch(actionRequestOff(ESessionActionTypes.PUT_SUCCESS, doc));
			});
		}
	},

	get: (doc?: ISessionDoc, id?: number) => {
		return async (dispatch: ThunkDispatch<IApplicationState, void, Action> ) => {   //, getState: Function
			dispatch(actionRequestOn(ESessionActionTypes.GET_REQUEST));

			const getDoc: ISessionDoc = !!doc ? doc : new SessionModel();

			if (!!doc) {
				return dispatch(actionRequestOff(ESessionActionTypes.GET_SUCCESS, getDoc));
			}
			if (!!id) {
				try {
					const params = '?$withRelated=["sessionSessionPhoto"]';
					const response = await axios.get<ISessionResponse>(`${config.site.apiURL}/auth/sessions/${id}${params}` );

					return dispatch(actionRequestOff(ESessionActionTypes.GET_SUCCESS, response.data.doc));
				}
				catch (error) {
					console.log('error: ', error);
					if (error.response && error.response.status === 401) {
						//dispatch({type: SNACKBAR_ERROR, payload: {serverError: {code: null, message:'ACCESSO NON AUTORIZZATO'}}});
					}
					else if (error.response && error.response.status === 403) {
						//dispatch({type: SNACKBAR_ERROR, payload: {serverError: {code: null, message:'ACCESSO NON CONSENTITO'}}});
					}
					else if (error.response && error.response.status === 400) {
						//dispatch({type: SNACKBAR_ERROR, payload: {serverError: {path: error.response.data.details[0].path, message:error.response.data.details[0].path + ' ' + error.response.data.details[0].message, type:error.response.data.details[0].type}}});
					}
					else{
						//dispatch({type: SNACKBAR_ERROR, payload: {serverError: {path: error.response.data.details[0].path, message:error.response.data.details[0].path + ' ' + error.response.data.details[0].message, type:error.response.data.details[0].type}}});
					}
					return dispatch(actionRequestOff(ESessionActionTypes.GET_FAILURE));
				}
			}

			return dispatch(actionRequestOff(ESessionActionTypes.GET_FAILURE));
		}
	},

	delete: (doc: ISessionDoc) => {
		return async (dispatch: ThunkDispatch<IApplicationState, void, Action>, getState: () => IApplicationState) => {
			dispatch(actionRequestOn(ESessionActionTypes.DELETE_REQUEST, doc));

			const sessionsState = getState().sitesState;
			const params = !!sessionsState && !!sessionsState.params ? !!sessionsState.params : {};
			const token: InitTokenReturn = await initToken();
			if (token.token) {
				try {
					// doc.sessionId = config.session.id;

					axios.defaults.headers.common['Authorization'] = token.token;
					await axios.delete(`${config.site.apiURL}/auth/sessions/${doc.id}`,{ data: { "$hardDelete": false }});
				}
				catch (error) {
					console.log('error: ', error);
					if (error.response && error.response.status === 401) {
						//dispatch({type: SNACKBAR_ERROR, payload: {serverError: {code: null, message:'ACCESSO NON AUTORIZZATO'}}});
					}
					else if (error.response && error.response.status === 403) {
						//dispatch({type: SNACKBAR_ERROR, payload: {serverError: {code: null, message:'ACCESSO NON CONSENTITO'}}});
					}
					else if (error.response && error.response.status === 400) {
						//dispatch({type: SNACKBAR_ERROR, payload: {serverError: {path: error.response.data.details[0].path, message:error.response.data.details[0].path + ' ' + error.response.data.details[0].message, type:error.response.data.details[0].type}}});
					}
					else{
						//dispatch({type: SNACKBAR_ERROR, payload: {serverError: {path: error.response.data.details[0].path, message:error.response.data.details[0].path + ' ' + error.response.data.details[0].message, type:error.response.data.details[0].type}}});
					}
					return dispatch(actionRequestOff(ESessionActionTypes.DELETE_FAILURE, doc));
				}
			}
			else {
				batch(() => {
					dispatch(push('/'));
					dispatch(actionRequestOff(ESessionActionTypes.DELETE_FAILURE));
					dispatch(actAuth.logout());
				});

			}
			batch(() => {
				dispatch(actSessions.get(params));
				dispatch(actionRequestOff(ESessionActionTypes.DELETE_SUCCESS, doc));
			});
		}
	},

};

// Service for Fetching
const actionRequestOn = (type: ESessionActionTypes, doc?: ISessionDoc) => {
	return (dispatch: ThunkDispatch<IApplicationState, void, Action>) => {
		const action: ISessionAction = {
			type: type,
			payload: {
				doc: !!doc ? doc : new SessionModel(),
				isFetching: true,
			},
		};
		dispatch(action);
	}
};

const actionRequestOff = (type: ESessionActionTypes, doc?: ISessionDoc) => {
	return (dispatch: ThunkDispatch<IApplicationState, void, Action>) => {
		const action: ISessionAction = {
			type: type,
			payload: {
				doc: !!doc ? doc : new SessionModel(),
				isFetching: false,
			},
		};
		dispatch(action);
	}
};
