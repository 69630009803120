import { EChurchActionTypes, IChurchAction, IChurchDoc } from "./index";
import { Action } from "redux";
import { ThunkDispatch } from "redux-thunk";
import { IApplicationState } from "../../index";
import ChurchModel from "../../../models/churchModel";
import {initToken} from "../../../helpers/token/auth_helper";
import axios from "axios";
import {config} from "../../../appConfig";
import {IAttach, IServerResponseOne} from "../../types";
import {InitTokenReturn} from "../../../helpers/token/types";
import {actChurches} from "../churches";
//import {EMessageTypes, IMessage, messageActions} from "../../message";
import ChurchPhotoModel from "../../../models/churchPhotoModel";
import {actAuth, IAuthState} from "../../auth/auth";
import {push} from "connected-react-router";
import {ISiteDoc} from "../../auth/site";

interface IChurchResponse extends IServerResponseOne {
	doc: IChurchDoc,
}

export const actChurch = {
	create: (doc: IChurchDoc, attach: IAttach) => {
		return async (dispatch: ThunkDispatch<IApplicationState, void, Action>, getState: () => IApplicationState) => {
			dispatch(actionRequestOn(EChurchActionTypes.POST_REQUEST, doc));

			if (!!attach.file && !!attach.attach){
				doc.siteChurchPhoto = new ChurchPhotoModel();
				doc.siteChurchPhoto.originalName = attach.file.name;
				doc.siteChurchPhoto.mimeType = attach.file.type;
				doc.siteChurchPhoto.size = attach.file.size;
				doc.siteChurchPhoto.image = attach.attach;
			}

			const churchesState = getState().churchesState;
			const params = !!churchesState && !!churchesState.params ? !!churchesState.params : {};
			console.log('Init Token');
			const token: InitTokenReturn = await initToken();
			if (token.token) {
				try {
					const currentUser:IAuthState = getState().authState;
					const currentSite: ISiteDoc | null =  !!currentUser && !!currentUser.selectedSite ? currentUser.selectedSite : null ;

					if(!!currentSite && !!currentSite.id ){
						doc.siteId = currentSite.id;
						axios.defaults.headers.common['Authorization'] = token.token;

						await axios.post<IChurchResponse>(`${config.site.apiURL}/site/${currentSite.id}/churches`, ChurchModel.toDB2(doc));
					}
				}
				catch (error) {
					console.log('error: ', error.response);
					if (error.response && error.response.status === 401) {
						//dispatch({type: SNACKBAR_ERROR, payload: {serverError: {code: null, message:'ACCESSO NON AUTORIZZATO'}}});
					}
					else if (error.response && error.response.status === 403) {
						//dispatch({type: SNACKBAR_ERROR, payload: {serverError: {code: null, message:'ACCESSO NON CONSENTITO'}}});
					}
					else if (error.response && error.response.status === 400) {
						/*error.response.data.details.map(error => {
							const message: IMessage = {
								code: error.context.label==='image' ? 'attach.file' : error.context.label,
								message: error.message,
								type: EMessageTypes.ERROR,
							};
							console.log('Msg: ', message);
							dispatch(messageActions.add(message));
						});*/
					}
					else{
						//dispatch({type: SNACKBAR_ERROR, payload: {serverError: {path: error.response.data.details[0].path, message:error.response.data.details[0].path + ' ' + error.response.data.details[0].message, type:error.response.data.details[0].type}}});
					}
					return dispatch(actionRequestOff(EChurchActionTypes.POST_FAILURE, doc));
				}
			}
			else {
				dispatch(push('/'));
				dispatch(actionRequestOff(EChurchActionTypes.POST_FAILURE));
				return dispatch(actAuth.logout());
			}
			dispatch(actChurches.get(params));
			return dispatch(actionRequestOff(EChurchActionTypes.POST_SUCCESS, doc));
		}
	},

	update: (doc: IChurchDoc, attach: IAttach) => {
		return async (dispatch: ThunkDispatch<IApplicationState, void, Action>, getState: () => IApplicationState) => {
			dispatch(actionRequestOn(EChurchActionTypes.PUT_REQUEST, doc));

			if (!!attach.file && !!attach.attach){
				doc.siteChurchPhoto = !!doc.siteChurchPhoto ? doc.siteChurchPhoto : new ChurchPhotoModel();
				doc.siteChurchPhoto.originalName = attach.file.name;
				doc.siteChurchPhoto.mimeType = attach.file.type;
				doc.siteChurchPhoto.size = attach.file.size;
				doc.siteChurchPhoto.image = attach.attach;
				doc.siteChurchPhoto.churchId = !!doc.id ? doc.id : 0;
			}

			const churchesState = getState().churchesState;
			const params = !!churchesState && !!churchesState.params ? !!churchesState.params : {};
			const token: InitTokenReturn = await initToken();
			if (token.token) {
				try {
					const currentUser:IAuthState = getState().authState;
					const currentSite: ISiteDoc | null =  !!currentUser && !!currentUser.selectedSite ? currentUser.selectedSite : null ;

					if(!!currentSite && !!currentSite.id ){
						doc.siteId = currentSite.id;

						axios.defaults.headers.common['Authorization'] = token.token;
						await axios.put<IChurchResponse>(`${config.site.apiURL}/site/${currentSite.id}/churches/${doc.id}`, ChurchModel.toDB2(doc));
					}

				}
				catch (error) {
					console.log('error: ', error);
					if (error.response && error.response.status === 401) {
						//dispatch({type: SNACKBAR_ERROR, payload: {serverError: {code: null, message:'ACCESSO NON AUTORIZZATO'}}});
					}
					else if (error.response && error.response.status === 403) {
						//dispatch({type: SNACKBAR_ERROR, payload: {serverError: {code: null, message:'ACCESSO NON CONSENTITO'}}});
					}
					else if (error.response && error.response.status === 400) {
						//dispatch({type: SNACKBAR_ERROR, payload: {serverError: {path: error.response.data.details[0].path, message:error.response.data.details[0].path + ' ' + error.response.data.details[0].message, type:error.response.data.details[0].type}}});
					}
					else{
						//dispatch({type: SNACKBAR_ERROR, payload: {serverError: {path: error.response.data.details[0].path, message:error.response.data.details[0].path + ' ' + error.response.data.details[0].message, type:error.response.data.details[0].type}}});
					}
					return dispatch(actionRequestOff(EChurchActionTypes.PUT_FAILURE, doc));
				}
			}
			else {
				dispatch(push('/'));
				dispatch(actionRequestOff(EChurchActionTypes.PUT_FAILURE));
				return dispatch(actAuth.logout());
			}
			dispatch(actChurches.get(params));
			return dispatch(actionRequestOff(EChurchActionTypes.PUT_SUCCESS, doc));
		}
	},

	get: (doc?: IChurchDoc, id?: number) => {
		return async (dispatch: ThunkDispatch<IApplicationState, void, Action>, getState: () => IApplicationState ) => {   //, getState: Function
			dispatch(actionRequestOn(EChurchActionTypes.GET_REQUEST));
			console.log('Church GET');

			const getDoc: IChurchDoc = !!doc ? doc : new ChurchModel();

			if (!!doc) {
				return dispatch(actionRequestOff(EChurchActionTypes.GET_SUCCESS, getDoc));
			}
			if (!!id) {
				try {
					const currentUser:IAuthState = getState().authState;
					const currentSite: ISiteDoc | null =  !!currentUser && !!currentUser.selectedSite ? currentUser.selectedSite : null ;

					if(!!currentSite && !!currentSite.id ){
						const params = '?$withRelated=["siteChurchPhoto"]';
						const response = await axios.get<IChurchResponse>(`${config.site.apiURL}/site/${currentSite.id}/churches/${id}${params}` );

						return dispatch(actionRequestOff(EChurchActionTypes.GET_SUCCESS, response.data.doc));
					}
				}
				catch (error) {
					console.log('error: ', error);
					if (error.response && error.response.status === 401) {
						//dispatch({type: SNACKBAR_ERROR, payload: {serverError: {code: null, message:'ACCESSO NON AUTORIZZATO'}}});
					}
					else if (error.response && error.response.status === 403) {
						//dispatch({type: SNACKBAR_ERROR, payload: {serverError: {code: null, message:'ACCESSO NON CONSENTITO'}}});
					}
					else if (error.response && error.response.status === 400) {
						//dispatch({type: SNACKBAR_ERROR, payload: {serverError: {path: error.response.data.details[0].path, message:error.response.data.details[0].path + ' ' + error.response.data.details[0].message, type:error.response.data.details[0].type}}});
					}
					else{
						//dispatch({type: SNACKBAR_ERROR, payload: {serverError: {path: error.response.data.details[0].path, message:error.response.data.details[0].path + ' ' + error.response.data.details[0].message, type:error.response.data.details[0].type}}});
					}
					return dispatch(actionRequestOff(EChurchActionTypes.GET_FAILURE));
				}
			}

			return dispatch(actionRequestOff(EChurchActionTypes.GET_FAILURE));
		}
	},

	delete: (doc: IChurchDoc, attach: IAttach) => {
		return async (dispatch: ThunkDispatch<IApplicationState, void, Action>, getState: () => IApplicationState) => {
			dispatch(actionRequestOn(EChurchActionTypes.DELETE_REQUEST, doc));

			console.log(attach.file);

			const churchesState = getState().churchesState;
			const params = !!churchesState && !!churchesState.params ? !!churchesState.params : {};
			const token: InitTokenReturn = await initToken();
			if (token.token) {
				try {
					const currentUser:IAuthState = getState().authState;
					const currentSite: ISiteDoc | null =  !!currentUser && !!currentUser.selectedSite ? currentUser.selectedSite : null ;

					if(!!currentSite && !!currentSite.id ){
						doc.siteId = currentSite.id;

						axios.defaults.headers.common['Authorization'] = token.token;
						await axios.delete(`${config.site.apiURL}/site/${currentSite.id}/churches/${doc.id}`,{ data: { "$hardDelete": false }});
					}
				}
				catch (error) {
					console.log('error: ', error);
					if (error.response && error.response.status === 401) {
						//dispatch({type: SNACKBAR_ERROR, payload: {serverError: {code: null, message:'ACCESSO NON AUTORIZZATO'}}});
					}
					else if (error.response && error.response.status === 403) {
						//dispatch({type: SNACKBAR_ERROR, payload: {serverError: {code: null, message:'ACCESSO NON CONSENTITO'}}});
					}
					else if (error.response && error.response.status === 400) {
						//dispatch({type: SNACKBAR_ERROR, payload: {serverError: {path: error.response.data.details[0].path, message:error.response.data.details[0].path + ' ' + error.response.data.details[0].message, type:error.response.data.details[0].type}}});
					}
					else{
						//dispatch({type: SNACKBAR_ERROR, payload: {serverError: {path: error.response.data.details[0].path, message:error.response.data.details[0].path + ' ' + error.response.data.details[0].message, type:error.response.data.details[0].type}}});
					}
					return dispatch(actionRequestOff(EChurchActionTypes.DELETE_FAILURE, doc));
				}
			}
			else {
				dispatch(push('/'));
				dispatch(actionRequestOff(EChurchActionTypes.DELETE_FAILURE));
				return dispatch(actAuth.logout());
			}
			dispatch(actChurches.get(params));
			return dispatch(actionRequestOff(EChurchActionTypes.DELETE_SUCCESS, doc));
		}
	},

};

// Service for Fetching
const actionRequestOn = (type: EChurchActionTypes, doc?: IChurchDoc) => {
	return (dispatch: ThunkDispatch<IApplicationState, void, Action>) => {
		const action: IChurchAction = {
			type: type,
			payload: {
				doc: !!doc ? doc : new ChurchModel(),
				isFetching: true,
			},
		};
		dispatch(action);
	}
};

const actionRequestOff = (type: EChurchActionTypes, doc?: IChurchDoc) => {
	return (dispatch: ThunkDispatch<IApplicationState, void, Action>) => {
		const action: IChurchAction = {
			type: type,
			payload: {
				doc: !!doc ? doc : new ChurchModel(),
				isFetching: false,
			},
		};
		dispatch(action);
	}
};
