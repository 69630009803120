import {ILoginUserDoc} from "../../store/auth/auth";
import { includes, remove, split } from 'lodash';
import {ISiteDoc} from "../../store/auth/site";


export const isSuperAdmin = (user: ILoginUserDoc | null): boolean => {
 let response: boolean = false;
 if (!!user && !!user.scope.length) {
   response = includes(user.scope, 'SuperAdmin');
 }

 return response;
};

export const isAdmin = (user: ILoginUserDoc | null, selectedSite: ISiteDoc | null): boolean => {
  let response: boolean = false;

  if (!!user && !!user.scope.length && !!selectedSite) {
    const scope: string[] = Array.from(user.scope);
    remove(scope, (s) => {
      return s === 'SuperAdmin' || s === 'Logged';
    });
    scope.map((scope: string) => {
      const parts: string[] = split(scope, '_');
      if (parts[2] === 'Admin' && parts[1] === selectedSite.id) {
        response = true
      }
      return parts[1];
    });
  }

  return response;
};

export const isManager = (user: ILoginUserDoc | null, selectedSite: ISiteDoc | null): boolean => {
  let response: boolean = false;

  if (!!user && !!user.scope.length && !!selectedSite) {
    const scope: string[] = Array.from(user.scope);
    remove(scope, (s) => {
      return s === 'SuperAdmin' || s === 'Logged';
    });
    scope.map((scope: string) => {
      const parts: string[] = split(scope, '_');
      if (parts[2] === 'Manager' && parts[1] === selectedSite.id) {
        response = true
      }
      return parts[1];
    });
  }

  return response;
};

export const isEditor = (user: ILoginUserDoc | null, selectedSite: ISiteDoc | null): boolean => {
  let response: boolean = false;

  if (!!user && !!user.scope.length && !!selectedSite) {
    const scope: string[] = Array.from(user.scope);
    remove(scope, (s) => {
      return s === 'SuperAdmin' || s === 'Logged';
    });
    scope.map((scope: string) => {
      const parts: string[] = split(scope, '_');
      if (parts[2] === 'Editor' && parts[1] === selectedSite.id) {
        response = true
      }
      return parts[1];
    });
  }

  return response;
};

export const isUser = (user: ILoginUserDoc | null, selectedSite: ISiteDoc | null): boolean => {
  let response: boolean = false;

  if (!!user && !!user.scope.length && !!selectedSite) {
    const scope: string[] = Array.from(user.scope);
    remove(scope, (s) => {
      return s === 'SuperAdmin' || s === 'Logged';
    });
    scope.map((scope: string) => {
      const parts: string[] = split(scope, '_');
      if (parts[2] === 'User' && parts[1] === selectedSite.id) {
        response = true
      }
      return parts[1];
    });
  }

  return response;
};

export const isGuest = (user: ILoginUserDoc | null, selectedSite: ISiteDoc | null): boolean => {
  let response: boolean = false;

  if (!!user && !!user.scope.length && !!selectedSite) {
    const scope: string[] = Array.from(user.scope);
    remove(scope, (s) => {
      return s === 'SuperAdmin' || s === 'Logged';
    });
    scope.map((scope: string) => {
      const parts: string[] = split(scope, '_');
      if (parts[2] === 'Guest' && parts[1] === selectedSite.id) {
        response = true
      }
      return parts[1];
    });
  }

  return response;
};
