import * as React from 'react';
import {Route, Redirect, RouteProps} from 'react-router-dom';
import {connect} from 'react-redux';
import * as routes from "./RoutesList";
import {IAuthState} from "../store/auth/auth";
import {IApplicationState} from "../store";

// Props passed from mapStateToProps TS
interface PropsFromState {
	authState: IAuthState,
}

// Container specific Props
interface ComponentProps {
	history: History,
	component: React.ReactNode,
	otherProps: any,
}

// Combine both Props State + Dispatch
type RouterProps = PropsFromState & ComponentProps

const PublicRoute: React.FC<RouteProps> = ({
																						 authState,
																						 component,
																						 ...otherProps
																					 }: RouterProps) => {
	if (authState.isAuthenticated) {
		return <Redirect to={routes.HOME}/>;
	}
	else {
		return <Route {...otherProps} component={component}/>;
	}
};


function mapStateToProps(state: IApplicationState) {
	return {
		authState: state.authState
	}
}

export default connect<PropsFromState, IApplicationState>(mapStateToProps)(PublicRoute);
