import {Box, Heading} from "grommet";
import * as React from "react";
import {actAuth, IAuthState, ILoginParams, ILoginUserDoc} from "../store/auth/auth";
import {connect} from 'react-redux'
import {ThunkDispatch} from "redux-thunk";
import {IApplicationState} from "../store";
import {Action} from "redux";
import {actUsers, IUsersState} from "../store/auth/users";
import {EExtraQueryType, IExtraQueryParam, IPaginationParam, IParamsTypes} from "../helpers/interfaces/forQueryParams";
import {actRoles, IRolesState} from "../store/auth/roles";
import {actSites, ISitesState} from "../store/auth/sites";
import { Card } from 'grommet-controls';
import {actSessions, ISessionsState} from "../store/auth/sessions";
import {isSuperAdmin} from "../helpers/roles/role_helper";
import {initToken} from "../helpers/token/auth_helper";
import {actChurches, IChurchesState} from "../store/church/churches";
import {actNews, INewsState} from "../store/new/news";


interface IState {
}

// Props passed from mapStateToProps TS
interface PropsFromState {
  authState:  IAuthState,
  usersState: IUsersState,
  rolesState: IRolesState,
  sitesState: ISitesState,
  sessionsState: ISessionsState,
  churchesState: IChurchesState,
  newsState: INewsState,
}

interface PropsFromDispatch {
  actAuthLogin: (param: ILoginParams) => void,
  actUsersGetAll: (param: IParamsTypes) => void,
  actRolesGetAll: (param: IParamsTypes) => void,
  actSitesGetAll: (param: IParamsTypes) => void,
  actSessionsGetAll: (param: IParamsTypes) => void,
  actChurchesGetAll: (param: IParamsTypes) => void,
  actNewsGetAll: (param: IParamsTypes) => void,
  actAuthInit: () => void,
}

type Props = PropsFromState & PropsFromDispatch & IState;


class Dashboard extends React.Component<Props, IState> {

  componentDidMount = async () => {
    const {authState, actRolesGetAll, actSessionsGetAll, actSitesGetAll, actUsersGetAll,
           actChurchesGetAll, actNewsGetAll} = this.props;
    const user: ILoginUserDoc | null = !!authState.user ? authState.user : null;
    await initToken();

    const pages: IPaginationParam = {page: 0, limit: 0};
    const related: IExtraQueryParam[] = [{kind: EExtraQueryType.WITH_RELATED, value: 'userRRU'}];
    const params: IParamsTypes = {paginationParams: pages, extraQuery: related};

    if (isSuperAdmin(user)) {
      actRolesGetAll(params);
      actSessionsGetAll(params);
    }

    actUsersGetAll(params);
    actSitesGetAll(params);
    actChurchesGetAll(params);
    actNewsGetAll(params);

  };

  render () {
    const {usersState,rolesState, sitesState, sessionsState, authState, churchesState, newsState} = this.props;

    const isSuperAdminUser = !!authState && !!authState.user && isSuperAdmin(authState.user);

    const usersTotal = !!usersState.items ? usersState.items.filtered : 0;
    const rolesTotal = !!rolesState.items ? rolesState.items.filtered : 0;
    const sitesTotal = !!sitesState.items ? sitesState.items.filtered : 0;
    const sessionsTotal = !!sessionsState.items ? sessionsState.items.filtered : 0;
    const churchesTotal = !!churchesState.items ? churchesState.items.filtered : 0;
    const newsTotal = !!newsState.items ? newsState.items.filtered : 0;

    return (

      <Box
        direction="column"
        pad="medium"
        gap="medium"
      >
        <Box
          direction="row"
          pad="medium"
          gap="medium"
        >
          <Card
            round='small'
            background='#ffffff'
            basis='small'
            elevation='none'
            border={{color:'#1b98a8',size:'medium'}}
          >
            <Card.CardTitle>
              <Heading margin="none" color='#faa629' size='small'>USERS</Heading>
            </Card.CardTitle>
            <Card.CardContent>
              <Heading margin="none" color='#54c3f0' size='large' textAlign='end'>{usersTotal}</Heading>
            </Card.CardContent>
          </Card>
          { isSuperAdminUser &&
          <Card
            round='small'
            background='#ffffff'
            basis='small'
            elevation='none'
            border={{color:'#1b98a8',size:'medium'}}
          >
            <Card.CardTitle>
              <Heading margin="none" color='#faa629' size='small'>ROLES</Heading>
            </Card.CardTitle>
            <Card.CardContent>
              <Heading margin="none" color='#54c3f0' size='large' textAlign='end'>{rolesTotal}</Heading>
            </Card.CardContent>
          </Card>
          }
          { isSuperAdminUser &&
          <Card
            round='small'
            background='#ffffff'
            basis='small'
            elevation='none'
            border={{color:'#1b98a8',size:'medium'}}
          >
            <Card.CardTitle>
              <Heading margin="none" color='#faa629' size='small'>SITES</Heading>
            </Card.CardTitle>
            <Card.CardContent>
              <Heading margin="none" color='#54c3f0' size='large' textAlign='end'>{sitesTotal}</Heading>
            </Card.CardContent>
          </Card>
          }
          { isSuperAdminUser &&
          <Card
            round='small'
            background='#ffffff'
            basis='small'
            elevation='none'
            border={{color:'#1b98a8',size:'medium'}}
          >
            <Card.CardTitle>
              <Heading margin="none" color='#faa629' size='small'>SESSIONS</Heading>
            </Card.CardTitle>
            <Card.CardContent>
              <Heading margin="none" color='#54c3f0' size='large' textAlign='end'>{sessionsTotal}</Heading>
            </Card.CardContent>
          </Card>
          }
        </Box>
        <Box
          direction="row"
          pad="medium"
          gap="medium"
        >
          <Card
            round='small'
            background={'#ffffff'}
            basis='small'
            elevation='none'
            border={{color:'#1b98a8',size:'medium'}}
          >
            <Card.CardTitle>
              <Heading margin="none" color='#faa629' size='small'>Churches</Heading>
            </Card.CardTitle>
            <Card.CardContent>
              <Heading margin="none" color='#54c3f0' size='large' textAlign='end'>{churchesTotal}</Heading>
            </Card.CardContent>
          </Card>

          <Card
            round='small'
            background={'#ffffff'}
            basis='small'
            elevation='none'
            border={{color:'#1b98a8',size:'medium'}}
          >
            <Card.CardTitle>
              <Heading margin="none" color='#faa629' size='small'>News</Heading>
            </Card.CardTitle>
            <Card.CardContent>
              <Heading margin="none" color='#54c3f0' size='large' textAlign='end'>{newsTotal}</Heading>
            </Card.CardContent>
          </Card>
        </Box>
      </Box>

    )
  }


}


const mapStateToProps = (state: IApplicationState): PropsFromState => ({
  authState:    state.authState,
  usersState:  state.usersState,
  rolesState:  state.rolesState,
  sitesState: state.sitesState,
  sessionsState: state.sessionsState,
  churchesState: state.churchesState,
  newsState: state.newsState,
});

// Mapping our action dispatcher to props is especially useful when creating container components.
const mapDispatchToProps = (dispatch: ThunkDispatch<IApplicationState, void, Action>): PropsFromDispatch => ({
  //AUTH Actions
  actAuthLogin: (param: ILoginParams) => dispatch(actAuth.login(param)),

  actUsersGetAll: (param: IParamsTypes) => dispatch(actUsers.get(param)),
  actRolesGetAll: (param: IParamsTypes) => dispatch(actRoles.get(param)),
  actSitesGetAll: (param: IParamsTypes) => dispatch(actSites.get(param)),
  actSessionsGetAll: (param: IParamsTypes) => dispatch(actSessions.get(param)),
  actChurchesGetAll: (param: IParamsTypes) => dispatch(actChurches.get(param)),
  actNewsGetAll: (param: IParamsTypes) => dispatch(actNews.get(param)),

  actAuthInit: () => dispatch(actAuth.init()),

});

export default connect(mapStateToProps,mapDispatchToProps)(Dashboard);
