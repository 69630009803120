import reduxThunk from 'redux-thunk';

import { createBrowserHistory } from 'history'
import { applyMiddleware, compose, createStore } from 'redux'
import { routerMiddleware } from 'connected-react-router'

// import rootReducer from './reducers/index';
// import { createBrowserHistory, History } from 'history';
import { composeWithDevTools } from 'redux-devtools-extension';

// Import the state interface and our combined reducers.
import rootReducers, { IApplicationState } from './index';

export const history = createBrowserHistory();


export default function configureStore(initialState?: IApplicationState) {
  const composeEnhancers = composeWithDevTools({});

  return createStore(
    rootReducers(history), // root reducer with router state
    initialState,
    compose(
      composeEnhancers(applyMiddleware(
        reduxThunk,
        routerMiddleware(history), // for dispatching history actions
        // ... other middlewares ...
        ),
      ),
    ),
  );
}
