import {ESitesActionTypes, ISitesAction, ISitesState} from './index';
import {Reducer} from 'redux';



// Reducer initial State with ALL objects
export const initialState: ISitesState = {
	docs: [],
	isFetching: false,
	items: null,
	pages: null,
	params: {},
};

const reducer: Reducer<ISitesState> = (state: ISitesState = initialState, action: ISitesAction) => {
	switch (action.type) {
		case ESitesActionTypes.GET_REQUEST:
			return {
				...state,
				...action.payload,
			};
		case ESitesActionTypes.GET_SUCCESS:
			return {
				...state,
					...action.payload,
			};
		case ESitesActionTypes.GET_FAILURE:
			return {
				...state,
				...action.payload,
			};

		default:
			return state;
	}
};

export { reducer as SitesReducer }
