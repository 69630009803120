import * as React from "react";
import {InjectedFormikProps, withFormik} from "formik";
import {ISiteDoc} from "../store/auth/site";
import SiteModel from "../models/siteModel";
import {validate} from "../validations/siteValidation";
import {EActionType} from "../helpers/interfaces/forQueryParams";
import {Box, Button, CheckBox, Form, FormField, Heading, Layer, Paragraph} from "grommet";

interface Props {
  selectRow: ISiteDoc | null,
  actionType: EActionType | null,
  submitModal: (actionType: EActionType, row: ISiteDoc) => void,
  closeModal: () => void,
}

const SiteForm: React.FunctionComponent<InjectedFormikProps<Props, ISiteDoc>> = props => {
  const {handleSubmit, closeModal, values, actionType, setFieldValue, handleBlur, errors, touched, isSubmitting} = props;

  return (
      <Layer
          onEsc={closeModal}
          onClickOutside={closeModal}
      >
        <Box width={'large'} pad={"large"} >
          <Heading color={'neutral-1'} margin="none" level={3}>{`${actionType} Site`}</Heading>
          <br/>
          {(actionType === EActionType.CREATE || actionType === EActionType.UPDATE) &&
          <Form>
            <FormField
                name="name"
                label="Name"
                value={values.name}
                error={touched.name && !!errors.name ? errors.name : ''}
                onBlur={handleBlur}
                onChange={event => setFieldValue('name', event.target.value)}/>
            <FormField
                name="description"
                label="Description"
                value={!!values.description ? values.description : '' }
                error={touched.description && !!errors.description ? errors.description : ''}
                onBlur={handleBlur}
                onChange={event => setFieldValue('description', event.target.value)}/>
            <FormField
                name="url"
                label="Url"
                value={!!values.url ? values.url : '' }
                error={touched.url && !!errors.url ? errors.url : ''}
                onBlur={handleBlur}
                onChange={event => setFieldValue('url', event.target.value)}/>
            <FormField
                name="email"
                label="Email"
                value={!!values.email ? values.email : '' }
                error={touched.email && !!errors.email ? errors.email : ''}
                onBlur={handleBlur}
                onChange={event => setFieldValue('email', event.target.value)}/>
            <CheckBox
                checked={values.isMailing}
                label="Is Mailing"
                onChange={event => setFieldValue('isMailing', event.target.checked)}/>

            <Box justify='end' direction={"row"} margin={{top: 'large'}} >
              <Button color='light-5' primary alignSelf={"end"} label="Cancel" onClick={closeModal}/>
              <Button disabled={isSubmitting} type="submit" primary alignSelf={"end"} margin={{left: 'small'}}
                      label="Save" onClick={()=>handleSubmit()}/>
            </Box>
          </Form>
          }
          {actionType === EActionType.DELETE &&
          <Form>
            <Paragraph margin="medium">
              Are you sure to delete site {`${values.name}`} ?
            </Paragraph>
            <Box justify='end' direction={"row"} margin={{top: 'large'}} >
              <Button color='light-5' primary alignSelf={"end"} label="Cancel" onClick={closeModal}/>
              <Button disabled={isSubmitting} type="submit" color={"red"} alignSelf={"end"} margin={{left: 'small'}}
                      label="Delete" onClick={()=>handleSubmit()}/>
            </Box>
          </Form>
          }

        </Box>
      </Layer>
  );
};


const siteForm = withFormik<Props, ISiteDoc>({
  enableReinitialize: true,
  validateOnChange: false,

  mapPropsToValues: (props): ISiteDoc => {
    console.log('FKProps: ', props.selectRow);
    if (!!props.selectRow){
      return {
        ...props.selectRow,
      };
    }
    else{
      return new SiteModel();
    }

  },

  validate: (values: ISiteDoc) => {
    return validate(values, false);
  },

  handleSubmit: (values: ISiteDoc, { props, setSubmitting, setErrors }) => {
    console.log('Submit: ', values);
    setSubmitting(true);
    let errors = validate(values, true);

    if (Object.keys(errors).length > 0) {
      setErrors(errors);
      setSubmitting(false);
    }
    else {
      const doc = new SiteModel(values);

      if (!!props.actionType)
        props.submitModal(props.actionType, doc);

      setSubmitting(false);
    }
  }
})(SiteForm);

export default siteForm;
